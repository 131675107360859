export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backButton = require('../assets/imagebackicon.png');
export const rightIcone = require('../assets/right.png');
export const scalingIcon = require("../assets/scalingIcon.svg");
export const pdfIcon = require("../assets/pdfIcon.svg");
export const pdfFileIcon = require("../assets/pdfFileIcon.svg");
export const userIcon = require("../assets/userIcon.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const cancelIcon = require("../assets/cancelIcon.svg");
export const pdfFileWarningIcon = require("../assets/pdfFileWarningIcon.svg");
export const warningIcon = require("../assets/warningIcon.svg");

export const backArrowIcon = require("../assets/backArrowIcon.svg");
export const cursorIcon = require("../assets/cursorIcon.svg");
export const handIcon = require("../assets/handIcon.svg");
export const lineIcon = require("../assets/lineIcon.svg");
export const rectangleIcon = require("../assets/rectangleIcon.svg");
export const deleteMiniIcon = require("../assets/deleteMiniIcon.svg");
export const lineMiniIcon = require("../assets/lineMiniIcon.svg");
export const rectangleMiniIcon = require("../assets/rectangleMiniIcon.svg");
export const cursorMiniIcon = require("../assets/cursorMiniIcon.svg");
export const handMiniIcon = require("../assets/handMiniIcon.svg");