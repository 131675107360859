import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props2 {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchInputText:string
  projectList:any[]
  originalProjectList:any[]
  updatedidmount:any,
  projectId:number,
  projectnameDetail:string,
  projectChannalID:number,
  projectChannalname:string,
  external:boolean
  internal:boolean
  dummyArray:any[],
  anchorElTSpeci:any,
  projectOption:any,
  isAddMemberModalOpen:boolean
  LeavechatModalOpen:boolean
  reMoveMemberModalOpen:boolean
  reMovememberName:string
  isOpenRightSection:boolean
  selectedUsers: any;
  inputName: string;
  users: any;
  active:string,
  projectOptionID:number,
  channalID:number,
  projectpinActive:boolean,
  membereAdded:boolean
  reRenderCom:number,
  removeMemberid:number,
  totalmember_in_project:number,
  memberError: string;
  pinStatus:boolean,
  internalMuteStatus:boolean,
  conversationID: string;






  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController2 extends BlockComponent<Props2, S, SS> {
  // Customizable Area Start
  getProjectListApiCallId:string = '';
  getMembersListCallId: string = "";
  postCreateMemberCallId: string="";
  PinProjectApiCallId: string="";
  MuteProjectApiCallId: string="";
  leaveChatCallId: string="";
  getRightSectionMemberCallId: string="";
  reMoveMemberListCallId: string="";

  
  // Customizable Area End

  constructor(props: Props2) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchInputText:'',
      projectList:[],
      originalProjectList:[],
      updatedidmount:0,
      projectId:0,
      projectnameDetail:"Channel Name",
      projectChannalID:0,
      projectChannalname:"",
      external:false,
      internal:false,
      dummyArray:[1,2],
      anchorElTSpeci:null,
      projectOption:null,
      isAddMemberModalOpen: false,
      LeavechatModalOpen:false,
      reMoveMemberModalOpen:false,
      reMovememberName:"",
      isOpenRightSection:false,
      selectedUsers: [],
      inputName: "",
      users: [],
      active:"member",
      projectOptionID:0,
      channalID:0,
      projectpinActive:false,
      membereAdded:false,
      reRenderCom:0,
      removeMemberid:0,
      totalmember_in_project:0,
      memberError:"",
      pinStatus:false,
      internalMuteStatus:false,
      conversationID:"",





      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // super.componentDidMount();
   this.getProjectList()
   this.getMembersListApi()
    // Customizable Area End
  }
  async componentDidUpdate(prevProps: any, prevState: { updatedidmount: number }) {
    if (
      prevState.updatedidmount !== this.state.updatedidmount 
    ) {
      await this.getProjectList();
      await this.getMembersListApi()

    }
  }
  


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    }
    
    if (this.getProjectListApiCallId === apiRequestCallId) {  
         
      
        if(!responseJson.errors) {
          this.handleProjectListResponse(responseJson)
        }
    
  



    }
    if (this.getMembersListCallId === apiRequestCallId) {
     this.handleMembersListResponse(responseJson);

    }
    if (this.postCreateMemberCallId === apiRequestCallId) {
      if(responseJson.message=== "Chat memberships created successfully"){
              this.setState({membereAdded:true})
              this.showToastForAllUsers()
              this.setState({selectedUsers:[]})

    
      }else{
        this.setState({selectedUsers:[]})
        toast.success(`Account User already added to the channel.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          theme: "light",
          style: {
            width: "auto", 
            maxWidth: "unset",
            whiteSpace: "nowrap",
          },
        });
      }
      
      if(responseJson.message==="Chat memberships created successfully"){
         this.handleIncreaseCount()
      }
     }
     if (this.leaveChatCallId === apiRequestCallId) {
    
    
       if (responseJson.message==="Chat Membership has been removed") {
        this.closeLeaveChatModel()
        this.getProjectList()
        }
 
     }
     if (this.PinProjectApiCallId === apiRequestCallId) {
       if(responseJson.message==="Pin status has been changed for all related memberships."){
         this.setState({projectpinActive:true})
         this.updateui()
      }
  }
  if (this.MuteProjectApiCallId === apiRequestCallId) {
    if(responseJson.message==="Mute status has been changed."){
      this.updateui()
      
   }
}


     if (this.getRightSectionMemberCallId === apiRequestCallId) {
      this.updateui()
      this.setState({totalmember_in_project:responseJson.data.length})
     }
     if (this.reMoveMemberListCallId === apiRequestCallId) {
         if(responseJson.message==="Chat Member has been removed"){
            this.handleIncreaseCount()
            this.updateui()
            this.getRightSectionMember()

           }
          }

}
  handleMembersListResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        users: responseJson.members,
      });
    } else {
      this.setState({
        users: [],
      });
    }
  };


  getProjectList = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProjectListResponse = (res:any)=>{
    if(!res.data) {
      this.setState({projectList: [],
        originalProjectList:[]
      })
    }else
    this.setState({projectList:res.data,
      originalProjectList:res.data
       }
       )
  }

  handleProjectId = (projectId:any)=>{

    if(this.state.projectId === projectId?.attributes?.id){
      this.setState({projectId:0})      
    }else{
      this.setState({projectId:projectId?.attributes?.id})
      this.setState({projectnameDetail:projectId?.attributes?.project_name})
      this.capitalizeFirstLetter(this.state.projectnameDetail)
    }

  }
  capitalizeFirstLetter = (string: string) => {
    if (!string) return ''; 
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  handleSpeciChange = (event: any)=>{
    this.setState({anchorElTSpeci:event.currentTarget})
    
  }
  openRightSection=()=>{
this.setState({isOpenRightSection:true})
  }
  cancle=()=>{
    this.setState({isOpenRightSection:false})
  }

  handleClose3 = ()=>{
    this.setState({anchorElTSpeci:null})
  }
  OpenADDmemberModel=()=>{
    this.setState({isAddMemberModalOpen:true})
    this.getMembersListApi()
  }
  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAddMemberModalOpen: !prev.isAddMemberModalOpen,
    }));
 
  getMembersListApi = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMembersListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/teams/list_all_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  filterUsers = (users: any, selectedUsers: any) => {
    return users
      .filter(
        (user: { user_name: any }) =>
          !selectedUsers.some(
            (selectedUser: { value: any }) =>
              selectedUser.value === user.user_name
          )
      )
      .map((user: { user_name: any }) => ({
        value: user.user_name,
        label: user.user_name,
      }));
  };
  handleInputChange = (inputValue:any) => {
    this.setState({ inputName: inputValue });
  };
  handleMemberAdd = (user: any) => {    
    this.setState((prevState) => ({
      selectedUsers: [
        ...prevState.selectedUsers,
        { id: user.id, value: user.first_name, label: user.first_name },
      ],
    }));
    
  };
  filterAvailableUsers(
    users: Array<any>,
    selectedUsers: Array<any>,
    inputName: string
  ): Array<any> {
    return users?.filter(
      (user) =>
        user?.first_name?.toLowerCase().includes(inputName.toLowerCase()) &&
        !selectedUsers.some(
          (selectedUser) => selectedUser.value === user?.first_name
        )
        
    );
  }
  handleIncreaseCount = () => {
    this.setState((prevState) => ({
      reRenderCom: prevState.reRenderCom + 1,
    }));
    
  };
  handleAddMemberData = async () => {
    
    this.setState({isAddMemberModalOpen:false})
    
    const data = this.state.selectedUsers.map((obj: any) => parseInt(obj.value));    

    
    const token = await getStorageData("token");
    const header = {
        "Content-Type": 'application/json',
        token: token,
    };    
    const body = {
        "chat_membership": data.map((accountId: any) => ({
            "chat_channel_id": this.state.projectChannalID,
            "account_id": accountId
        }))
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCreateMemberCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_chat/chat_memberships`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};
   handleClick = (item: any) => {
    this.setState({active:item})
  };
  handleExternalChannelClick(channal:any){
    this.setState({projectChannalID:channal.id})
    
  }
  handleInternalChannelClick(channal:any){
    
    this.setState({projectChannalID:channal.id,
      conversationID:channal.conversation_id
    })
    this.setState({ projectChannalname:channal.name})
    this.getRightSectionMember()
    console.log("111111111111==========>con id",channal.conversation_id);
    this.handleIncreaseCount()


  

  }
  handleProjectOption = (event: any, projectobj: any) => {
    
    this.setState({ projectOption: event.currentTarget,
      projectOptionID:projectobj?.id,
      pinStatus:projectobj.attributes?.pin_status
     });
     const internalId = projectobj.attributes?.chat_channels?.find(
      (channel: { type: string; }) => channel.type === "internal"
    );
    console.log("+++++++++++++++ mute",internalId);
    
  this.setState({channalID:internalId?.id,
    internalMuteStatus:internalId?.mute_status
  })
 
  };
  handleCloseProjectOption = ()=>{
    this.setState({projectOption:null})
  }
  updateui=()=>{
    this.setState((prevState) => ({
      updatedidmount: prevState.updatedidmount + 1,
    }));
  }
  handlePinTOChannal=()=>{
    this.handlMembersEditDetailsSubmit()
    this.handleCloseProjectOption()
    


  }
  handlMembersEditDetailsSubmit = async () => {
    const token = await getStorageData("token");
    const header = {    
      "Content-Type": 'application/json',
           token: token,};
      const body = {"project_id": Number(this.state.projectOptionID),}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PinProjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_channels/change_pin_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage
    );
};
handlMuteUnmuteAPI = async () => {
  const token = await getStorageData("token");
  const header = {    
    "Content-Type": 'application/json',
         token: token,};
    
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.MuteProjectApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_chat/chat_channels/${this.state.channalID}/change_mute_status`

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PATCH"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage
  );
};
  handleSearchInputChange = (value: string) => {
    this.setState({ searchInputText: value }, () => {
      this.filterProjectList();
    });
  };

filterProjectList = () => {
  const { searchInputText, originalProjectList } = this.state;

  if (!searchInputText) {
    this.setState({ projectList: originalProjectList });
    return;
  }

  const filteredList = originalProjectList.filter((project: { attributes: { project_name: string; }; }) =>
    project.attributes.project_name
      ?.toLowerCase()
      .includes(searchInputText.toLowerCase())
  );

  this.setState({ projectList: filteredList });
};
// Function to display the toast
 showToastForAllUsers = () => {
  const allLabels = this.state.selectedUsers.map((user: { label: any; }) => user.label).join(", ");
  toast.success(`${allLabels} have been added to the group chat.`, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    closeButton: false,
    theme: "light",
    
  style: {
    width: "auto", 
    minWidth: "450px", 
    paddingLeft:"15px",
  },
  });
  this.getRightSectionMember()
};

  handleDataFromChild = (data: any, flag: boolean) => {
    this.setState({
      reMovememberName:data.account.first_name,
      removeMemberid:  data.id,
      reMoveMemberModalOpen: flag,
    });
  };
  
  OpenLeaveChatModel=()=>{
    
    this.setState({LeavechatModalOpen:true})
  }
  closeLeaveChatModel=()=>{
    this.setState({LeavechatModalOpen:false})
  }
  leavechatConfirm(){
 
    this.leaveChat()
this.handleNavigationPersonaldetails()

  }
  handleNavigationPersonaldetails = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CustomisableUserProfiles'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { savepetnum: 2})
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }



  leaveChat  = async () =>  {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.leaveChatCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_chat/chat_memberships/leave_project_chat?chat_channel_id=${this.state.projectChannalID}`

    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  getRightSectionMember = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRightSectionMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_memberships?chat_channel_id=${this.state.projectChannalID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  reMoveMemberConfirm(){
    this.removememberCallAPI()
     this.reMoveMemberModelclose()
  }
  reMoveMemberModelclose=()=>{
    this.setState({reMoveMemberModalOpen:false})
  }
  removememberCallAPI  = async () =>  {
    
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.reMoveMemberListCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_memberships/remove_membership?chat_membership_id=${this.state.removeMemberid}`

    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  sendMessage=()=>{
    console.log("msg");
    
  }
  
  handleMuteTOChannal=()=>{
    this.handlMuteUnmuteAPI()
    this.handleCloseProjectOption()
  }
  // Customizable Area End
}
