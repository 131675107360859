import React from "react";
import "gantt-task-react/dist/index.css";

import {
  Box,
  // Customizable Area Start
  Tab,
  Tabs,
  Button,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles";
import {  ExpandMore} from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Calendar from "../../../components/src/GanttChart/GanttChart.component";
import "./Style.css";
import { checkListIcon } from "./assets";

// Customizable Area End

import GanttChartController, {
  Props,
} from "./GanttChartController";
export default class GanttChart extends GanttChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  RenderRoomList(){
    return(
      <div className="roomListViewCard">
        {
          this.state.events?.map((val:any,id:any)=>{
            if(this.state.initialDate.getFullYear() === Number(val?.end.getFullYear())){
            return(
              <div style={{marginTop:"5px",display:"grid",width:"100%",gap:"10px",gridTemplateColumns:"200px auto",alignItems:"center"}}>
                <div className="RoomNameColor">{val?.room}</div>
                <div key={id} className="roomListCard">
                    <div style={{display:"flex",gap:"20px",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                        <span style={{fontWeight:600,fontSize:"16px",fontFamily:"Poppins",color:"Neutrals/Cool gray/900"}}>{val?.room}</span>
                        <div style={{width:"250px",height:"10px",borderRadius:"10px",backgroundColor:"#E2E8F0"}}>
                          <div style={{width:`${val?.completed_percent}`,backgroundColor:"#237182",height:"10px",borderRadius:"10px"}}></div>
                        </div>
                    </div>  
                    <div className="roomBottomPart">
                      <div>
                        <span style={{fontWeight:400,fontSize:"12px",fontFamily:"Poppins",color:"#334155",display:"flex",alignItems:"center",gap:"10px"}}><DateRangeIcon/>{String(val?.start.getDate())}/{String(val?.start.getMonth()+1)}/{String(val?.start.getFullYear())}{" "}-{" "}{String(val?.end.getDate())}/{String(val?.end.getMonth()+1)}/{String(val?.end.getFullYear())}</span>
                      </div>
                      <div>
                        <span style={{color:"#237182",display:"flex",alignItems:"center",fontWeight:600,gap:"5px"}}><img src={checkListIcon} alt="checklistIcon" style={{color:"#237182"}}/>{val?.completed_task}/{val?.total_task} </span>
                      </div>
                    </div> 
                </div>
              </div> 
            )
          }})
        }
      </div>
    )
  }
 
  RendertaskName(){
    return(
      <div className="eventsCard">
      { this.state.events?.map((val:any,i:any)=>{
         if(this.state.initialDate.getFullYear() === Number(val?.end.getFullYear())){
          return (
            <div style={{marginTop:"5px",display:"grid",width:"100%",gridTemplateColumns:"200px auto",alignItems:"center",gap:"10px"}}>
            <div className="RoomNameColor1">{val?.room}</div>
           <div style={{display:"flex",gap:"10px",flexDirection:"column",paddingTop:"10px",paddingBottom:"10px"}}>
              {   val?.taskList?.map((data:any)=>{
                return (
                  <div className="cardContent" key={i}> 
                    <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
                      <span className="yellowDot"></span>
                      <span style={{fontWeight:600,fontSize:"16px",fontFamily:"Poppins",color:"Neutrals/Cool gray/900"}}>{data?.task}</span>
                    </div>  
                      <span style={{fontWeight:400,fontSize:"12px",fontFamily:"Poppins",color:"#334155"}}>{String(data?.start_date.getDate())}/{String(data?.start_date.getMonth()+1)}/{String(data?.start_date.getFullYear())}{" "}-{" "}{String(data?.end_date.getDate())}/{String(data?.end_date.getMonth()+1)}/{String(data?.end_date.getFullYear())}</span>
                    <div style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <div style={{width:"150px",height:"10px",borderRadius:"10px",backgroundColor:"#E2E8F0"}}>
                        <div style={{width:`${data?.Completed}`,backgroundColor:"#237182",height:"10px",borderRadius:"10px"}}></div>
                      </div>
                      <span style={{fontWeight:400,fontSize:"16px",fontFamily:"Poppins",color:"#334155"}}>{data?.Completed}</span>
                      </div>
                  </div>
                )
              })}
            </div> 
          </div>
        )
       }
      
       })
      }
      </div>
    )
  }

  RenderQuaterCalendar(){

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const monthStarting = months[this.state.initialDate.getMonth()]
    const monthEnding = months[this.state.initialDate.getMonth()+2]

    return (
      <div className="container">
         <div className="header">
          <div className="monthTitle">
              <div className="monthName">
                <span onClick={this.handlePreviousWeek}>{"<"}</span>
                <span>{monthStarting}{" "}-{" "}{monthEnding}</span>
                <span onClick={this.handleNextWeek}>{">"}</span>
              </div>
              <div className="monthDuration">
                <span style={{fontFamily:"Poppins"}}>Period :{" "}{" "} 1/{this.state.initialDate.getMonth()+1}/{this.state.initialDate.getFullYear()}{" "}{" "}-{" "}{" "}1/{this.state.initialDate.getMonth()+3}/{this.state.initialDate.getFullYear()}</span>
              </div>
          </div>
          <div className="month">
          {
            this.handleQuarterWeeks() === "January" &&
            this.state.Quarter1.map((val:any,id:any)=>{
              return(
                <div  key={id} style={{display:"flex",flexDirection:"column"}}>
                  <span style={{color:"#334155",fontWeight:400}}>{val.name}</span>
                  <span style={{fontWeight:600,color:"#1E293B"}}>{val.start}</span>
                </div>
              )
            })
          }
          {
            this.handleQuarterWeeks() === "April" &&
            this.state.Quarter2.map((val:any,id:any)=>{
              return(
                <div  key={id} style={{display:"flex",flexDirection:"column"}}>
                  <span style={{color:"#334155",fontWeight:400}}>{val.name}</span>
                  <span style={{fontWeight:600,color:"#1E293B"}}>{val.start}</span>
                </div>
              )
            })
          }
          {
            this.handleQuarterWeeks() === "July" &&
            this.state.Quarter3.map((val:any,id:any)=>{
              return(
                <div  key={id} style={{flexDirection:"column",display:"flex"}}>
                  <span style={{fontWeight:400,color:"#334155"}}>{val.name}</span>
                  <span style={{fontWeight:600,color:"#1E293B"}}>{val.start}</span>
                </div>
              )
            })
          }
          {
            this.handleQuarterWeeks() === "October" &&
            this.state.Quarter4.map((val:any,id:any)=>{
              return(
                <div  key={id} style={{display:"flex",flexDirection:"column"}}>
                  <span style={{color:"#334155",fontWeight:600}}>{val.name}</span>
                  <span style={{fontWeight:600,color:"#1E293B"}}>{val.start}</span>
                </div>
              )
            })
          }
          </div> 
         </div>
      </div>
    )
  }

  RenderYearCalendar(){
    return (
      <div className="container">
         <div className="header">
          <div className="monthTitle">
              <div className="monthName">
                <span onClick={this.handlePreviousWeek}>{"<"}</span>
                <span>{this.state.initialDate.getFullYear()}</span>
                <span onClick={this.handleNextWeek}>{">"}</span>
              </div>
              <div className="monthDuration" style={{fontFamily:"Poppins"}}> <span>Time Period :{" "} 1/1/{this.state.initialDate.getFullYear()}{" "}-{" "}1/1/{this.state.initialDate.getFullYear()+1}</span></div>
          </div>
          <div className="month">
           {this.state.monthName?.map((val:any)=>{
              return(
                <div className="monthDiv" key={val?.id}>
                  <span>{val?.name}</span>
                  <span style={{fontWeight:600}}>{val?.id}</span>
                </div>
              )})}
          </div> 
         </div>
      </div>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div style={{
          background:"cover !important",
          backgroundColor:"#E0EDF0",
          display:"flex",
          flexDirection:"column",
          paddingLeft:"30px",
          paddingRight:"30px",
          minHeight: "100vh", 
          height: "auto",     
          overflow: "auto",    
          }}>
            <div>
              <div style={webStyle.ganttBoxWrapper}>
                <span style={{color:"#0F172A",fontSize:"18px",fontWeight:700}}>Rhino Stan Smith HQ roof renovation </span>
              </div>
              <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row",marginTop:"20px"}}>
                <div>
                 <Tabs 
                 value={this.state.activeTab} 
                 onChange={this.handleChange} 
                 style={{display:"flex",gap:"5px",color:"#325962",backgroundColor:"#FFFFFF",width:"300px",flexDirection: "row",borderRadius:"10px",zIndex: 0,position: "relative"}}>
                    <Tab label="Week"  style={{minWidth:"auto",...(this.state.activeTab === 0 && {
                      background: "#E0EDF0", 
                      borderRadius: "12px"
                    })
                    }} value={0} />

                    <Tab label="Month" style={{minWidth:"auto",...(this.state.activeTab === 1 && {
                      background: "#E0EDF0", 
                      borderRadius: "12px"
                    })}} value={1}/>
                    <Tab label="Quater" style={{minWidth:"auto",
                      ...(this.state.activeTab === 2 && {
                        background: "#E0EDF0", 
                        borderRadius: "12px"
                      })
                    }} value={2}/>
                    <Tab label="Year" style={{minWidth:"auto",
                      ...(this.state.activeTab === 3 && {
                        background: "#E0EDF0", 
                        borderRadius: "12px"
                      })
                    }} value={3}/>
                 </Tabs>
                </div>
                <div style={{display:"flex",gap:"20px"}}>
                  <Button 
                   style={{backgroundColor:"#E0EDF0",color:"#325962"}}
                  >
                    Today
                  </Button>
                <CustomDropDown
                            id="outlined-select-currency-native"
                            select
                            value={this.state.listView}
                            data-test-id={'sortValue'}
                            onChange={this.handleChangeSelect}
                            SelectProps={{
                                native: true,
                                IconComponent: ExpandMore,
                            }}
                            style={{padding:"6px"}}
                  >
                            <option value={"ROOM_VIEW"}>Room View</option>
                            <option value={"TASKLIST_VIEW"}>Task List View</option>
                        </CustomDropDown>
                </div>
              </div>
            </div>
           <div style={{marginTop:"10px",display:"grid",width:"100%",gap:"10px",gridTemplateColumns:"200px auto",height:"100px"}}>
            <div className="roomBlockName">
               <span>Room Name</span>
            </div>
           {
                this.state.activeTab === 0 && 
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                   <Calendar activeTabs={this.state.activeTab} eventValues={this.state.events} nextWeek={this.handleNextWeek}  dateValue={this.state.initialDate} prevWeek={this.handlePreviousWeek}/>
                </div> 
            }
           {
                this.state.activeTab === 1 && 
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}> 
                  <Calendar activeTabs={this.state.activeTab} eventValues={this.state.events} nextWeek={this.handleNextWeek}  dateValue={this.state.initialDate} prevWeek={this.handlePreviousWeek}/>
               </div> 
            }
               {
                this.state.activeTab === 2 &&
                this.RenderQuaterCalendar()
               }
               {
                this.state.activeTab === 3 &&
                this.RenderYearCalendar()
               }
            </div> 
            {
              this.state.listView === "ROOM_VIEW" &&
              <div style={{display:"grid",width:"100%"}}>
                {this.RenderRoomList()}
              </div>
            }
            {
              this.state.listView === "TASKLIST_VIEW" &&
              <div style={{display:"grid",width:"100%"}}>
                {this.RendertaskName()}
              </div>
            }
           
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomDropDown = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#fff",
    color: "#237182",
  },
  "& .MuiSelect-select": {
    paddingBlock: "10px",
    color: "#237182",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    color: "#237182",
  },
});

const webStyle = {
  mainBox: {
    backgroundColor:"#E0EDF0",
    display:"flex",
    flexDirection:"column",
    paddingLeft:"30px",
    paddingRight:"30px"
  },
  calendarRoom:{
   backgroundColor:"#FFFFFF",
   width:"150px",
   height:"auto",
   display:"flex",
   alignItem:"space-between",
   flexDirection:"column",
   padding:"20px",
   marginBottom:"20px"
  },
  searchType:{
   width:"90vw",
   backgroundColor:"#FFFFFF",
   height:"fit-content"
  },
  bg:{
    backgroundColor:"#E2E8F0",
  },
  roomName:{
    height:"200px",
    display:"flex",
    alignItems:"center",
    color:"#475569"
  },
  viewSwitcherWrapper: {
    marginTop: "10px"
  },
  ganttBoxWrapper: {
    marginTop: "20px"
  },
  buttonStyle: {
    marginLeft: "10px",
    border: "none",
    backgroundColor: "#F0F0F0",
  },
  chkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};
// Customizable Area End