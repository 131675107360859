import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  TextField,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { backArrowIcon, cursorIcon, handIcon, lineIcon, rectangleIcon, lineMiniIcon } from "./assets";



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ScalingController, {
  Props,
} from "./ScalingController.web";


export default class Scaling extends ScalingController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {tool,pixels,realWorldDistance,unit,endpoint1,endpoint2 } = this.state;
    const boxStyle = (currentTool: string) => ({
        backgroundColor: tool === currentTool ? '#E0EDF0' : '#ffffff',
        padding: '10px',
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'inline-block',
      });
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
          <MainWrapper>
            <MyProfileHeader navigation={this.props.navigation}  tabName={'Scaling&Drawings'}/>
            <Box sx={{display:'flex',bgcolor:'#FFFFFF',border:'1px solid #E2E8F0',justifyContent:'space-between',padding:'8px 24px 8px 16px'}}>
                <Box style={{display:'flex',gap:'26px', alignItems:'center'}}>
                    <Box data-test-id="handleGoBack" onClick={this.handleGoBack}>
                        <img src={backArrowIcon} alt='arrow'/>
                    </Box>
                    <Box>
                        <Typography style={{
                             color:'#0F172A',
                             fontFamily: 'Poppins',
                             fontSize: '16px',
                             fontWeight: 600,
                             lineHeight: '24px',
                             textAlign: 'left',
                             textUnderlinePosition: 'from-font',
                             textDecorationSkipInk: 'none',
                        }}>{this.state.fileName}</Typography>
                    </Box>

                </Box>
                <Box style={{display:'flex',gap:'16px',alignItems: 'center', marginLeft: '-135px'}}>
                    <Box data-test-id='selectTool'  onClick={()=>this.selectTool('arrow')} style={boxStyle('arrow')}>
                    <img src={cursorIcon} alt='arrow'/>
                    </Box>
                    <Box data-test-id='selectTool' onClick={()=>this.selectTool('hand')} style={boxStyle('hand')}>
                    <img src={handIcon} alt='arrow'/>
                    </Box>
                    <Box data-test-id='selectTool' onClick={()=>this.selectTool('line')} style={boxStyle('line')}>
                    <img src={lineIcon} alt='arrow'/>
                    </Box>
                    <Box data-test-id='selectTool' onClick={()=>this.selectTool('rectangle')} style={boxStyle('rectangle')}>
                    <img src={rectangleIcon} alt='arrow'/>
                    </Box>
                    {!this.state.tool && <Box style={{marginLeft:'-20px'}}>
                    <Typography style={{ 
                        color:'#0F172A',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        textUnderlinePosition: 'from-font',
                        textDecorationSkipInk: 'none',
                        }}>Select a tool to get started</Typography>
                    </Box>
                    }
                    {this.state.tool && <Box style={{marginLeft:'-20px'}}>
                    <Typography style={{ 
                        color:'#0F172A',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        textUnderlinePosition: 'from-font',
                        textDecorationSkipInk: 'none',
                        }}></Typography>
                    </Box>
                    }

                </Box>
                <Box style={{display:'flex', alignItems:'center'}}>
                    <CloseButton data-test-id='handelClose' onClick={this.handelClose}>
                    Close File
                    </CloseButton>

                </Box>
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                <Box sx={{display:'flex',width:'100%',padding:'24px 32px 24px 32px',bgcolor:'#F0F5F7'}}>
               
              

        <Box sx={{display:'flex',width:'100%',bgcolor:'#FFFFFF',justifyContent:'center'}}>
        <canvas
          className="canvasBox"
          ref={this.canvasRef}
          onMouseMove={this.handleMouseMove}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
        />
        </Box>
        
                </Box>
                <Box>
                <Box className='container'>
        <Box sx={{padding:'8px 4px 8px 4px'}}>
        <Typography className='sectionTitle'>
          Scaling Panel
        </Typography>
            
        </Box>            
        
        <Divider style={{ marginBottom: '16px',marginTop: '-8px' }} />
        <Box style={{display:'flex',gap:'4px',marginBottom: '16px'}}>
            <Box>
            <Typography className="toolTypo">
            Tool: <strong></strong>
           </Typography>
            </Box>
            <Box sx={{display:'flex'}}>
           <img src={lineMiniIcon} alt="img"/>
            </Box>
            <Box>
            <Typography className="lineTypo">
            Line
           </Typography>
            </Box>
        



        </Box>
        

        <Typography className='label'>Set Scale</Typography>
        <CustomInputLabel1>Pixels:</CustomInputLabel1>
        <CustomTextField
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="Pixels"
        data-test-id="pixels"
        value={pixels}
        onChange={(e) => this.handleInputChange('pixels', pixels)}
        placeholder="Pixels"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        />
         <CustomInputLabel1>Real-World Distance:</CustomInputLabel1>
         <CustomTextField
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="realWorldDistance"
        data-test-id="realWorldDistance"
        value={realWorldDistance}
        onChange={(e) => this.handleInputChange('realWorldDistance', realWorldDistance)}
        placeholder="Real-World Distance"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        />
     
        <CustomInputLabel1>Unit:</CustomInputLabel1>
         <CustomTextField
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="unit"
        data-test-id="unit"
        value={unit}
        onChange={(e) => this.handleInputChange('unit', Number(unit))}
        placeholder="Unit"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        
        />

          <Box sx={{marginTop:'8px'}}>
           <CustomInputLabel2>Endpoint 1</CustomInputLabel2>
           <Box className='inputGroup'>
         <CustomTextField2
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="endpoint1"
        data-test-id="endpoint1"
        value={endpoint1.x}
        onChange={(e) => this.handleEndpointChange('endpoint1', 'x', endpoint1.x)}
        placeholder="X"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className="startAdornmentTypo">X</Typography>
              </InputAdornment>
            ),
          }}
        />
            <CustomTextField2
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="endpoint1"
        data-test-id="endpoint1"
        value={endpoint1.y}
        onChange={(e) => this.handleEndpointChange('endpoint1', 'y', endpoint1.y)}
        placeholder="Y"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className="startAdornmentTypo">Y</Typography>
              </InputAdornment>
            ),
          }}
        />
           </Box>
           <CustomInputLabel2>Endpoint 2</CustomInputLabel2>
           <Box className='inputGroup'>
         <CustomTextField2
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="endpoint2"
        data-test-id="endpoint2"
        value={endpoint2.x}
        onChange={(e) => this.handleEndpointChange('endpoint2', 'x', endpoint2.x)}
        placeholder="X"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className="startAdornmentTypo">X</Typography>
              </InputAdornment>
            ),
          }}
        />
            <CustomTextField2
        autoComplete='off'
        fullWidth
        variant="outlined"
        name="endpoint2"
        data-test-id="endpoint2"
        value={endpoint2.y}
        onChange={(e) => this.handleEndpointChange('endpoint2', 'y', endpoint2.y)}
        placeholder="Y"
        inputProps={{
            style: {
                fontFamily: "Poppins",
            },
        }}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className="startAdornmentTypo">Y</Typography>
              </InputAdornment>
            ),
          }}
        />
           </Box>
          </Box>
     

        <Divider style={{ marginBottom: '8px',marginTop: '8px' }} />
        <Typography className='lengthAndAreaTypo'>
          Length: <span className='lengthAndAreaDataTypo'>{this.state.length} meters</span>
        </Typography>
      </Box>

                </Box>

            </Box>
          
          </MainWrapper>  
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .root": {
    display: 'flex',
    height: '100vh',
  },
  "& .sidebar": {
    width: 300,
    borderLeft: '1px solid #ccc',
    padding: theme.spacing(2),
    backgroundColor: '#f4f4f4',
  },
  "& .toolbar": {
    display: 'flex',
  },
  "& .canvas": {
    flex: 1,
    position: 'relative',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .pdfCanvas": {
    width: '90%',
    height: '90%',
    border: '1px solid #ccc',
  },
  '& .container': {
    backgroundColor: '#FFFFFF',
    width: 216,
    top: 124,
    left: 1224,
    padding: '8px 16px',
    gap: 16,
    border: '0px solid transparent',
    borderLeft: '1px solid #E2E8F0',
  },
  '& .sectionTitle': {
    color:'#1E293B',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    marginBottom: '8px',
  },
  '& .toolTypo':{
    color:'#475569',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .lineTypo':{
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  '& .label': {
    marginBottom: '8px',
    color:'#151818',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    
  },
  '& .inputGroup': {
    display: 'flex',
    justifyContent: 'space-between',
    gap:'4px',
  },
  '& .input': {
    width: '45%',
  },
  '& .area': {
    fontWeight: 600,
    color: '#007BFF',
    textDecoration: 'underline',
  },
  '& .lengthAndAreaTypo':{
    color:'#1E293B',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .lengthAndAreaDataTypo':{
    color:'#237182',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
  '& .startAdornmentTypo':{
    color:'#0F172A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',

  },
}));
const CloseButton = styled(Button)({
    backgroundColor: "#237182",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight:'24px',
    height: "32px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "6px 10px 6px 10px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth:0,
    '&:hover': {
      backgroundColor: '#237182', 
    },
  });
  const CustomTextField = styled(TextField)({
    marginBottom:'8px',
    "& .MuiOutlinedInput-root": {
        height: '36px',
        borderRadius: '8px',
        borderColor: '#CBD5E1',
        backgroundColor: '#FFFFFF',
        "& fieldset": {
          borderColor: "#CBD5E1",
        },
        "&:hover fieldset": {
          border: "0.5px solid #CBD5E1",
        },
        "&.Mui-focused fieldset": {
          border: "0.5px solid #CBD5E1",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: '10px 8px',
        color:'#0F172A',
        fontSize: '14px',
        fontFamily: 'Poppins',
        lineHeight:'22px',
        "&::placeholder": {
          color: '#94A3B8',
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: '8px',
    fontWeight: '400',
    lineHeight:'22',
    fontSize: '14px',
    color: '#475569',
  },
  });
  const CustomTextField2 = styled(TextField)({
    marginBottom:'8px',
    "& .MuiOutlinedInput-root": {
        height: '36px',
        borderRadius: '8px',
        borderColor: '#CBD5E1',
        backgroundColor: '#FFFFFF',
        "& fieldset": {
          borderColor: "#CBD5E1",
        },
        "&:hover fieldset": {
          border: "0.5px solid #CBD5E1",
        },
        "&.Mui-focused fieldset": {
          border: "0.5px solid #CBD5E1",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: '10px 0px',
        color:'#475569',
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight:'400PX',
        lineHeight:'22px',
        "&::placeholder": {
          color: '#94A3B8',
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: '8px',
    fontWeight: '400',
    lineHeight:'22',
    fontSize: '14px',
    color: '#0F172A',
  },
  });

  const CustomInputLabel1 = styled(InputLabel)({
    marginBottom:'8px',
    color:'#475569',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  });
  const CustomInputLabel2 = styled(InputLabel)({
    marginBottom:'8px',
    color:'#151818',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  });
// Customizable Area End
