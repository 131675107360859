import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import axios from 'axios';
import { logoutUser, removeStorageData, setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface ErrorResponse {
  errors: ErrorDetail[];
}

interface ErrorDetail {
  failed_login?: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  validations: {
    emailValid: boolean,
    passwordsMatch: boolean,
  
  },
  errors: {
    email: string,
    password: string,
 
  },
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showPopup: boolean;
  emailErrorFail:string;
  passErrorFail:string;
  originalPassword:string;
  showPassword:boolean;
  asterisk:string;
  messageToggle:boolean,
  tostifymessage:string,
  status:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallIdData: string = "";
  apiEmailLoginCallId: string = "";
  apiEmailLoginCallIds: string = "";
  getPersonalInformation:string="";
  getTokenCheckExpiryId:string="";
  getSubscriptionTakenId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      validations: {
        emailValid: false,
        passwordsMatch: false
      },
      errors: {
        email: '',
        password: '',
      },
      email: "",
      password: "",
      originalPassword:"",
      asterisk:"",
      showPassword:true,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPopup: false,
      emailErrorFail:"",
      passErrorFail:"",
      messageToggle:false,
      tostifymessage:"",
      status:""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const redirectedFrom = await getStorageData("resetOTP");
    if(redirectedFrom){
      this.setState({showPopup: true})
    }
    this.getTokenCheckExpiryApiCall('mount');
    this.showMessageAfterAccountDeletion();
    // Customizable Area End
  }

  // Customizable Area Start
  getTokenCheckExpiryApiCall = async (arg: string) => {
    const token1 = await getStorageData("token");
    const header = {
      "Content-Type": "application/json"
    };

    const payload = {
      "token": token1
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (arg === 'mount') this.getTokenCheckExpiryId = requestMessage.messageId;
    else this.getSubscriptionTakenId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/logins/check_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showMessageAfterAccountDeletion = async () => {
    const storedMessage = await getStorageData("message", true);
    if (storedMessage) {
      this.setState({ ...storedMessage }, () => {
        this.closeMessageBox();
      });
    }
    await removeStorageData("message");
  }

  closeMessageBox = () => {
    setTimeout(()=>{
     this.setState({ messageToggle:false, tostifymessage:"", status:"" });
    },2000);
  }

  sendLoginFailErrorMessages=(responseJson:ErrorResponse)=>{
    if( responseJson.errors[0].failed_login === "Incorrect password."){
      this.setState({
        passErrorFail: responseJson.errors[0].failed_login,
        emailErrorFail:""
      })
    }else{
      this.setState({
        emailErrorFail: responseJson.errors[0].failed_login || "",
        passErrorFail:""
      })
    }
  }
  handleClose = () => {
    this.setState({ showPopup: false}, async()=>{
      logoutUser();
      await removeStorageData("resetOTP");
    });
  }
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };
  validateEmail = (email: string) => {
    let error = '';
  
    if (!email.trim()) {
      error = 'Please enter the email';
    } else {
      const emailValid = configJSON.emailValidText.test(email);
      error = emailValid ? '' : 'Please enter a valid email address.';
    }
  
    this.setState(
      {
        validations: { ...this.state.validations, emailValid: !error },
        errors: { ...this.state.errors, email: error },
      },
    );
  };

  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword}));
  }

  handlePasswordChanged = (e:any) => {
    const { originalPassword } = this.state;
    const { value } = e.target;
    const inputEvent = e.nativeEvent as InputEvent;
    const input = e.target as HTMLInputElement;
    const cursorPositionPointer = input.selectionStart || 0;
  
    if (!inputEvent || !inputEvent.inputType || value === " ") {
      return; }

    let originalText ;
  
    if (inputEvent.inputType === 'insertText' && inputEvent.data) {
      const data = inputEvent?.data;
      const originalArray = originalPassword.split('');
      originalArray.splice(cursorPositionPointer-1,0,data); 
      originalText = originalArray.join('');
      this.setState({
          originalPassword: originalText,
          asterisk: "*".repeat(originalText.length),
          password: value,
        },() => {
          const newCursorPosition = cursorPositionPointer-1 + data.length;
          input.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      );
        }
        else if(!value){
          originalText=value;
         }
         else{
           const originalArray = originalPassword.split('');
           originalArray.splice(cursorPositionPointer, 1); 
           originalText = originalArray.join('');
         }
         this.setState({
          asterisk: "*".repeat(originalText.length),
          originalPassword: originalText,
        });
  };
  
  
  validatePassword = () => {
    const passwordsMatch = this.state.originalPassword.length > 0;
    const error = passwordsMatch ? '' : 'Please enter the password';
    this.setState({
      validations: { ...this.state.validations, passwordsMatch },
      errors: { ...this.state.errors, password: error },});
  };

  handleBlurField =(e:any)=>{
    const {  value,name } = e.target;
    const newValueData = value.replace(/\s+/g, '');
    switch (name) {
        
      case 'email':
        this.validateEmail(newValueData as string);
        break;
     
      case 'password':
        this.validatePassword();
        break ;
          
      default:
        break;
    }
  }
  
  
  handleInputChange = (e:any) => {
    const { name, value, checked, type } = e.target;
    const newValue = value.replace(/\s+/g, '');

    const fieldValue1 = type === 'checkbox' ? checked : newValue;

    this.setState({ [name]: fieldValue1 } as unknown as Pick<S, keyof S>,);
  };
  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling

  setRememberMe = (va: boolean) => {
    this.setState({ checkedRememberMe: !this.state.checkedRememberMe });
  };
  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };
  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  sendLoginFailMessages(err:any) {
    if(err[0].failed_login=="No account found with this email address."){
      const emailValid = false;
      const error = 'No account found with this email address.';
      this.setState(
        {
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error,password:"" },
        },
      );
    }
  }
  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;
    
  navigateToForgotPassword =async()=>{
    const emailValid = configJSON.emailValidText.test(this.state.email);
    let error = '';
    if (!this.state.email) {
      error = 'Please enter email';
    } else if (!emailValid) {
      error = 'Invalid email address';
    }
    if(!this.state.email || !emailValid){
      this.setState(
        {
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error },
        },
      );
      return;
    }
    await setStorageData('emailId', this.state.email)
    this.goToForgotPassword();
  }
  handleSignupNavigate=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountRegistration'
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg);
  }

  getPersonalInformationAPI = () => {
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPersonalInformation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/profile`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLoginRedirect = (responseJson: any) => {
    if(responseJson.data && responseJson.data.attributes.role_id!=="owner"){
      this.handleClientRedirect(responseJson)
    }
    else if(responseJson.data && responseJson.data.attributes.first_name && responseJson.data.attributes.last_name){
      this.getTokenCheckExpiryApiCall('subscriptions')
    }else{
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'CustomisablePersonalProfiles'
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }
  handleClientRedirect=(responseJson:any)=>{
    let route="CustomisableUserProfiles";
    if(responseJson.data && responseJson.data.attributes.role_id!=="owner"&&(!responseJson.data.attributes.phone_number||!responseJson.data.attributes.about_me)){
     route="CustomisablePersonalProfiles";
    }
      const employeeMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
      employeeMsg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        route
      );
      employeeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {role:responseJson.data.attributes.role_id});
      employeeMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(employeeMsg);
  }
  handleEmailLoginCallResponse = (responseJson: any, errorResponse: any) => {
    console.log(responseJson,"hello data is here")
    if (!(responseJson && responseJson.meta && responseJson.data)) {
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessages(responseJson.errors);
      this.sendLoginFailErrorMessages(responseJson);
      this.parseApiCatchErrorResponse(errorResponse);
    }
      const { id } = responseJson?.data;
      const { token, remember_me_token } = responseJson.meta;
      const storedId = localStorage.getItem('id')
      if (storedId && storedId !== id) {
          localStorage.clear()
      }
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
      }
      localStorage.setItem("token", this.state.checkedRememberMe ? remember_me_token : token);
      if (localStorage.getItem("role")) {
        localStorage.removeItem("role");
      }
      localStorage.setItem("role",responseJson.data.attributes.role_id);
  
      localStorage.setItem("id", id);
      this.getPersonalInformationAPI();
  };
  
  doEmailLogInn(): void {
    if (this.state.email === null || this.state.email.length === 0 || !this.emailReg.test(this.state.email)){
      this.showAlert("Error", configJSON.errorEmailNotValid);
    }
    if (this.state.password === null || this.state.password.length === 0){
      this.showAlert("Error", configJSON.errorPasswordNotValid);
    }
    const header = {"Content-Type": configJSON.loginApiContentType};
    const attrs = {email: this.state.email, password: this.state.originalPassword, remember_me: this.state.checkedRememberMe};
    const data = {type: "email_account", attributes: attrs };
    const httpBody = {data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiEmailLoginCallIdData = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.loginAPiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.loginAPiMethod);
    runEngine.sendMessage(requestMessage.id, 
    requestMessage);
  }

  handleSubscriptionNavigation (responseJson: {isValidToken: boolean, isSubscribed: boolean}){
    if (responseJson) {
      if(responseJson.isValidToken && !responseJson.isSubscribed){
        this.props.navigation.navigate("AddNewTeamMember");
      }else if(responseJson.isValidToken && responseJson.isSubscribed){
        this.props.navigation.navigate("CustomisableUserProfiles");
      }
  }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const messageId = message.id;
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: this.state.checkedRememberMe,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
      }  else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallIdData) {
      this.handleEmailLoginCallResponse(responseJson, errorResponse);
      await Promise.all([
        setStorageData("username", responseJson.data.attributes.first_name),
        setStorageData("lastname", responseJson.data.attributes.last_name)
      ]);
    }
    if (apiRequestCallId === this.getPersonalInformation) {
      this.handleLoginRedirect(responseJson)
  }
  if (apiRequestCallId === this.getTokenCheckExpiryId) {
    if (responseJson) {
       if(responseJson.isValidToken && responseJson.isSubscribed){
        this.props.navigation.navigate("CustomisableUserProfiles");
       }
    }
  }
  if (apiRequestCallId === this.getSubscriptionTakenId) {
      this.handleSubscriptionNavigation(responseJson)
  }
 
      }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
