// Customizable Area Start
import React from "react";
import {
  AppBar,
  Typography,
  Container,
  TextField,
  OutlinedTextFieldProps,
  Grid,
  Button,
  FormControl,
  Select,
  SelectProps,
  MenuItem,
  Box,
  Divider,
  Stepper,
  Step,
  StepIcon,
  StepConnector,
  StepLabel,
  DialogActions,
  Dialog,
  Snackbar,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  Check,
  ArrowBackIos,
  Clear
} from "@material-ui/icons";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WorldFlag from "react-world-flags";
import { Formik } from "formik";
import * as yup from "yup";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {  withStyles } from '@material-ui/core/styles';
import CreateEditProjectPortfolioController, {
  Props,
  configJSON,
} from "./CreateEditProjectPortfolioController";
import Loader from "../../../components/src/Loader.web";
import CustomArrowIcon from '@material-ui/icons/ExpandMore';
import AddAndViewMember from "./AddAndViewMember.web";

const FormContainer = styled(Container)({
  marginBlock: "20px",
  borderRadius: "16px",
  maxWidth: "-webkit-fill-available",
  backgroundColor: "#fff",
  marginInline: "20px",
  fontFamily: "'Poppins', sans-serif",
  padding: '10px',
  minWidth: '100%'
});

const CustomDivider = styled(Divider)({
  height: "0.5px",
  marginBlock: 0,
  marginInline: "20px",
  backgroundColor: '#E2E8F0'
})

const Section = styled(Box)({
  marginBottom: "20px",
  padding: "20px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  fontFamily: "'Poppins', sans-serif",
});

const SaveButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#325962",
  marginRight: "13px",
  fontWeight: 600,
  fontSize: "16px",
  height: "44px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "Poppins",
  borderWidth:0,
});
const SaveButtonEnable = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#E0EDF0",
  color: "#325962",
  textTransform: "none",
  display:"flex",
  fontSize: "16px",
  fontWeight: 600,
  height: "44px",
  padding: "10px 16px 10px 16px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#E0EDF0",
  },
  border: "1px solid #C5E0E7",
  marginRight: "13px",
  letterSpacing: 0,
  boxShadow: "none",
  borderWidth:0,
});
const SectionLabel = styled(Typography)({
  fontFamily: "'Poppins', sans-serif",
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "15px",
});
const InputLabel = styled(Typography)({
  color: "#64748B",
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "'Poppins', sans-serif",
  marginBottom: "2px",
});

const DisabledNextButton = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#F1F5F9",
  color: "#64748B",
  textTransform: "none",
  fontSize: "16px",
  marginRight: '30px',
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#F1F5F9",
  },
});

 const styleSheet = {
  stepperText: { fontFamily: 'Poppins', whiteSpace: 'nowrap', fontSize: '14px', lineHeight: '22px'},
   logoutButton: {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "15px",
    fontWeight:600,
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "148px",
    padding: "10px 16px 10px 16px",
  } as const,
  logoutButton2: {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "121px",
    padding: "10px 16px 10px 16px",
    fontWeight:600,
  } as const,
  cancelButton: {
    backgroundColor: "#E2E8F0",
    color: "#325962",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "96px",
    fontWeight:600,
  } as const,
  title: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0F172A",
    fontFamily: "Poppins",
    lineHeight: "32px",
    letterSpacing: "-0.005em"
  }
 }

const EnabledNextButton = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#237182",
  color: "#fff",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  width: "69px",
  height: "44px",
  marginRight: '30px',

  padding: "10px 16px 10px 16px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#237182",
  },
});

const Root = styled("div")({
  fontFamily: "'Poppins', sans-serif",
  width: "33.53%",
  backgroundColor: "#F0F5F7",
});

const ActiveStepLabel = styled(StepLabel)({
  "& .MuiStepConnector-lineHorizontal":{
    borderTopWidth:'2px'
  },
  "& .MuiTypography-body1":{
         fontWeight:600
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 0,
  },
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#0F172A",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#34D399",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepIcon-root": {
    color: "#94A3B8",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepLabel-label.MuiStepLabel-active": {
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    color: "#0F172A",
    width:'140px',
  }
});

interface CustomTextFieldProps extends OutlinedTextFieldProps {
  hasValue: boolean;
}

export const CustomTextField = styled(({ hasValue, ...otherProps }: CustomTextFieldProps) => (
  <TextField {...otherProps} />
))(({ hasValue }: { hasValue: boolean }) => ({
  "& .MuiOutlinedInput-root": {
    height: '56px',
    borderRadius: '8px',
    borderColor: '#CBD5E1',
    backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
}));

export const ProjectTextField = styled(({ hasValue, ...otherProps }: CustomTextFieldProps) => (
  <TextField {...otherProps} />
))(({ hasValue }: { hasValue: boolean }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius:'8px',
    borderColor:'#CBD5E1',
    backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
}));
interface CustomSelectProps extends SelectProps {
  hasValue: boolean;
}

export const CustomSelect = styled(({ hasValue, ...otherProps }: CustomSelectProps) => (
  <Select {...otherProps} />
))(({ hasValue }: { hasValue: boolean }) => ({
  backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
  borderRadius: '8px',
  fontFamily: "'Poppins', sans-serif",
  "& .MuiSelect-select:focus": {
    backgroundColor: "white",
    borderRadius: 0,
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: '8px',
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1 !important",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1 !important",
  },
  "& .MuiSelect-icon": {
    color: "#94A3B8",
  },
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      borderColor: '#0F172A',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0F172A',
    },
  },
  line: {
    borderColor: '#94a3b8',
    borderTopWidth: 2,
    width:"40px"
  },
}))(StepConnector);

const Wrapper = styled(Box)({
  fontFamily: "'Poppins', sans-serif",
});
const formSchema = (conditionalValidate:any) =>
  yup.object().shape({
    projectName: yup
      .string()
      .required("Please enter project name")
      .test(
        "no-whitespace-only",
        "Project name can't be blank",
        (value) => value && value.trim() !== ""
      )
      .matches(/^[a-zA-Z\d\s]+$/, "Project name must be alphanumeric"),

    firstName: yup
      .string()
      .when([], {
        is: () => conditionalValidate,
        then: (schema:any) => 
          schema
            .required("Please enter first name")
            .test(
              "no-whitespace-only",
              "First name can't be blank",
              (value:any) => value && value.trim() !== ""
            )
            .matches(/^[a-zA-Z\s]+$/, "First name must contain alphabets only"),
        otherwise: (schema:any) => schema.optional(),
      }),

    lastName: yup
      .string()
      .when([], {
        is: () => conditionalValidate,
        then: (schema:any) => 
          schema
            .required("Please enter last name")
            .test(
              "no-whitespace-only",
              "Last name can't be blank",
              (value:any) => value && value.trim() !== ""
            )
            .matches(/^[a-zA-Z\s]+$/, "Last name must contain alphabets only"),
        otherwise: (schema:any) => schema.optional(),
      }),

    email: yup
      .string()
      .when([], {
        is: () => conditionalValidate,
        then: (schema:any) => 
          schema
            .required("Please enter an email")
            .email("Invalid email format")
            .test(
              "no-whitespace-only",
              "Email can't be blank",
              (value:any) => value && value.trim() !== ""
            ),
        otherwise: (schema:any) => schema.optional().email("Invalid email format"),
      }),

    phoneNumber: yup
      .string()
      .when([], {
        is: () => conditionalValidate,
        then: (schema:any) => 
          schema
            .required("Please enter phone number")
            .matches(/^\d+$/, "Phone number must contain only digits")
            .min(10, "Phone number is too short")
            .max(15, "Phone number is too long"),
        otherwise: (schema:any) => schema.optional(),
      }),

    postCode: yup
      .string()
      .required("Please enter post code")
      .test(
        "no-whitespace-only",
        "Post code can't be blank",
        (value) => value && value.trim() !== ""
      )
      .matches(/^[a-zA-Z0-9\s]+$/, "Post code must be alphanumeric"),

    country: yup
      .string()
      .required("Please select a country"),

    state: yup
      .string()
      .required("Please select a state"),

    streetName: yup
      .string()
      .required("Please enter street name")
      .test(
        "no-whitespace-only",
        "Street name can't be blank",
        (value) => value && value.trim() !== ""
      )
      .matches(/^[a-zA-Z0-9\s]+$/, "Street name must be alphanumeric"),

    city: yup
      .string()
      .required("Please enter city")
      .test(
        "no-whitespace-only",
        "City can't be blank",
        (value) => value && value.trim() !== ""
      )
      .matches(/^[a-zA-Z\s]+$/, "City must contain alphabets only"),

    projectDescription: yup
      .string()
      
      .matches(/^[\w\s\W]*$/, "Project description must be alphanumeric")
  });
  
export default class CreateEditProjectPortfolio extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  CustomStepIcon = (props: any) => {
    const { active, completed } = props;
    const getBackgroundColor = () => {
      if (completed) {
        return "#34D399";
      } else if (active) {
        return "#0F172A";
      } else {
        return "#94A3B8";
      }
    };

    const getColor = () => {
      return active || completed ? "#fff" : "#94A3B8";
    };

    const getSize = () => {
      return active || completed ? 32 : 24;
    };

    const styles = {
      color: getColor(),
      backgroundColor: getBackgroundColor(),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: getSize(),
      height: getSize(),
      fontFamily: "'Poppins', sans-serif",
      marginRight: active || completed ? "5px" : 0
    };

    return (
      <div style={styles}>
        {completed ? (
          <Check style={{ color: "#fff", fontSize: 20 }} />
        ) : (
          <StepIcon {...props} />
        )}
      </div>
    );
  };

  renderClientDetailsSection = ({
    planId,
    errors,
    touched,
    firstName,
    lastName,
    email,
    countryCode,
    phoneNumber,
    setFieldValue,
    setFieldTouched,
    configJSON,
    formik
  }:any) => {
    return (
      planId !== "3" && (
        <>
          <Section>
            <SectionLabel>Client Details</SectionLabel>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <InputLabel>Name *</InputLabel>
                <CustomTextField
                  autoComplete="off"
                  hasValue={!!firstName}
                  variant="outlined"
                  name="firstName"
                  data-test-id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(this.getError('firstName', formik))}
                  helperText={this.getError('firstName', formik)}
                  FormHelperTextProps={{
                    style: { marginLeft: 0,
                      color: '#FF0000',
                      fontSize: 'small',
                      marginTop: '2px',
                      fontFamily: 'Poppins'
                    },
                  }}
                  fullWidth
                  placeholder="Enter name"
                  inputProps={{
                    style: {
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                />
              
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <InputLabel>Last name *</InputLabel>
                <CustomTextField
                  autoComplete="off"
                  hasValue={!!lastName}
                  name="lastName"
                  data-test-id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(this.getError('lastName', formik))}
                  helperText={this.getError('lastName', formik)}
                  FormHelperTextProps={{
                    style: { marginLeft: 0,
                      color: '#FF0000',
                      fontSize: 'small',
                      marginTop: '2px',
                      fontFamily: 'Poppins'
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter last name"
                  inputProps={{
                    style: {
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                />
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <InputLabel>Email *</InputLabel>
                <CustomTextField
                  autoComplete="off"
                  hasValue={!!email}
                  name="email"
                  variant="outlined"
                  data-test-id="emailTest"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(this.getError('email', formik))}
                  helperText={this.getError('email', formik)}
                  FormHelperTextProps={{
                    style: { marginLeft: 0,
                      color: '#FF0000',
                      fontSize: 'small',
                      marginTop: '2px',
                      fontFamily: 'Poppins'
                    },
                  }}
                  fullWidth
                  placeholder="Enter email"
                  type="email"
                  inputProps={{
                    style: {
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                />
               
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <InputLabel>Phone number *</InputLabel>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontFamily: "'Poppins', sans-serif",
                    gap:"8px"
                  }}
                >
                  <CustomSelect
                    style={{
                      width: "150px",
                      height: "56.3px",
                    }}
                    data-test-id="inputCountryCode"
                    variant="outlined"
                    name="countryCode"
                    value={formik.values.countryCode}
                    hasValue={!!countryCode}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                    displayEmpty
                    inputProps={{
                      style: {
                        fontFamily: "'Poppins', sans-serif",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          height: "323px",
                          width: "150px",
                          fontFamily: "'Poppins', sans-serif",
                        },
                      },
                    }}
                    open={this.state.piToggleDropDown}
                    onOpen={() => this.handleTogglePIDropDown()}
                    onClose={() => this.handleTogglePIDropDown()}
                    IconComponent={() => (
                      <div
                        onClick={(e) => {
                          this.handleTogglePIDropDown();
                        }}
                        style={{ marginRight: "8px" }}
                        data-test-id="txtInputCountryCodeIcon"
                      >
                        {this.handleCondition(
                          this.state.piToggleDropDown,
                          <KeyboardArrowUpIcon
                            style={{ cursor: "pointer", color: "#64748B" }}
                          />,
                          <KeyboardArrowDownIcon
                            style={{ cursor: "pointer", color: "#64748B" }}
                          />
                        )}
                      </div>
                    )}
                  >
                    {configJSON.countryCodesArray.map((country:any) => (
                      <MenuItem value={country.dial_code} key={country.code}>
                        <Box display="flex" alignItems="center">
                          <WorldFlag
                            code={country.code}
                            height="18"
                            width="24"
                            style={{
                              marginLeft: "5px",
                              marginRight: "10px",
                              borderRadius: "1px",
                              marginBottom: "3px",
                            }}
                          />
                          <Typography style={{ fontFamily: "'Poppins', sans-serif" }}>
                            &nbsp;{country.dial_code}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </CustomSelect>
  
                  <CustomTextField
                    autoComplete="off"
                    data-test-id="phoneNumber"
                    hasValue={!!phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.phoneNumber}
                    onBlur={formik.handleBlur}
                    error={Boolean(this.getError('phoneNumber', formik))}
                    helperText={this.getError('phoneNumber', formik)}
                    FormHelperTextProps={{
                      style: { marginLeft: 0,
                        color: '#FF0000',
                        fontSize: 'small',
                        marginTop: '2px',
                        fontFamily: 'Poppins'
                      },
                    }}
                    name="phoneNumber"
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="00000 00000"
                    inputProps={{
                      minLength: 10,
                      maxLength: 15,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: {
                        fontFamily: "'Poppins', sans-serif",
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>
          <CustomDivider />
        </>
      )
    );
  };

  render() {
    const steps = this.getSteps();
    const {
      activeStep,
      completed,
      formValues: {
        firstName,
        projectName,
        projectDescription,
        lastName,
        postCode,
        city,
        email,
        streetName,
        phoneNumber,
        country,
        state,
        countryCode,
      },
      planId
    } = this.state;
    return (
      <Wrapper>
        <Loader loading={this.state.loading} />
        <MyProfileHeader navigation={this.props.navigation} handleDialogOpen={this.handleOpenDialogLeave} tabName="Projects">
      
         <Snackbar
    open={this.state.snackbarOpen}
    autoHideDuration={6000}
    message={this.state.snackbarMessage}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
/>     
          <Formik
            initialValues={this.state.formValues}
            validationSchema={formSchema(planId != '3')}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validateOnMount={true}
            data-test-id="formik"
          >
            {(formik) => (
              
              
              <>
              <form  onSubmit={formik.handleSubmit}>
                
                <Box 
                  style={{
                    cursor: "pointer",
                    fontFamily: "Poppins, sans-serif",
                    display: "flex",
                    backgroundColor: "#F0F5F7",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginInline: "40px",
                    marginTop: '20px'
                  }}>
                  <Typography
                    data-test-id="backButon2"
                    onClick={() => this.goBack2(formik)}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      display: "flex",
                      alignItems: "baseline",
                      width: "15%",
                      fontSize:'14px',
                      fontWeight: 600,
                      color:'#334155'
                    }}
                  >
                    <ArrowBackIos style={{ height: "12px", width: "20px",color:'#334155' }} />
                    Back
                  </Typography>
                  {planId !=='3' && <Root>
                    <Stepper
                      connector={<QontoConnector/>}
                      activeStep={activeStep}
                      style={{
                        backgroundColor: "#F0F5F7",
                        fontFamily: "'Poppins', sans-serif",
                        padding: 0,
                        width:'40px',
                      }}
                    >
                      {steps.map((placeholder, index) => (
                        <Step key={placeholder}
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}>
                          <ActiveStepLabel
                            StepIconComponent={(props) => (
                              <this.CustomStepIcon
                                {...props}
                                active={activeStep === index}
                                completed={!!completed[index]}
                              />
                            )}
                          >
                            <Typography style={styleSheet.stepperText}>
                            {activeStep === index && placeholder}
                            </Typography>
                          </ActiveStepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Root>}
                  <Box style={{ display: "flex",alignItems:'center',gap:'10px' }}>
                  <Box style={{
                              display: "flex",
                              gap: "0.5rem",
                              cursor: "pointer",
                                   }}>
                  <Typography onClick={this.handleOpenViewAndAddMemberDiload} style={{
                                    fontWeight: 600,
                                    color: "#237182",
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Poppins'
                                             }}>
                        
                                    {this.state.projectId && 
  (this.state.getAllAddedMemeber.members.length > 0 
    ? 'View & Add Member' 
    : '')}
                                 </Typography>
                </Box>
                   
                    {!this.handleToggleButton(formik.errors) && !this.handleCheckValues(formik) ? (
  <SaveButtonEnable
    data-test-id="btnSubmitProjectData"
    type="button"
    onClick={() => {
      this.handleOpenDialog(); 
    }}
  >
    Save Draft
  </SaveButtonEnable>
) : (
  <SaveButton >Save Draft</SaveButton>
)}

{this.state.openDialogLeave && <Dialog PaperProps={{
                      style: {
                        height: "232px",
                        width: '584px',
                        borderRadius: "16px",
                        padding: "40px 32px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} open={this.state.openDialogLeave} onClose={this.handleCloseDialog}>
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Are you sure you want to leave without saving?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px",
                        }}><Clear /></Typography></Box>
                        <DialogActions style={{
                            marginTop: "32px",
                            gap: "16px",
                            padding: "0px"
                        }}>
                          <Button style={{
                              backgroundColor: "#E2E8F0",
                              color: "#325962",
                              fontSize: "16px",
                              fontFamily: "Poppins",
                              textTransform: "none",
                              height: "56px",
                              borderRadius: "8px",
                              fontWeight:600,
                          }} onClick={()=>this.handleCloseDialogAndNavigatetoBack()}>
                          Go back without saving 
                          </Button>
                          <Button data-test-id="savedraftbtn"  style={styleSheet.logoutButton}  onClick={() => this.handleSaveDraft(formik.values)}>
                            Save Changes
                          </Button></DialogActions>

                      </Box>
         </Dialog>}
                    <Dialog PaperProps={{
                      style: {
                        height: "232px",
                        width: '584px',
                        borderRadius: "16px",
                        padding: "40px 32px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} open={this.state.openDialog} onClose={this.handleCloseDialog}>
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Do you want to save your draft before exiting?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px"
                        }}><Clear /></Typography></Box>
                        <DialogActions style={{
                          marginTop: "32px",
                          gap: "16px",
                          padding: "0px"
                        }}>
                          <Button style={styleSheet.cancelButton} onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button  data-test-id="savedraftbtn"  style={styleSheet.logoutButton2} onClick={() => this.handleSaveDraft(formik.values)}>
                            Save Draft
                          </Button></DialogActions>

                      </Box>
                      </Dialog>
                      {!formik.isValid || formik.isSubmitting ? (
  <DisabledNextButton data-test-id="btnSubmitProjectData">
    Next
  </DisabledNextButton>
) : (
  <EnabledNextButton
    data-test-id="btnSubmitProjectData"
    type="submit" 
  >
    Next
  </EnabledNextButton>
)}
               
                  </Box>
                </Box>
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: "#F0F5F7",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                ></AppBar>
                <Box sx={{marginLeft: '24px', marginRight: '70px'}}>
                <FormContainer>
                  <Section>
                    <SectionLabel>Project Information</SectionLabel>
                    <InputLabel>Project Name *</InputLabel>
                    <CustomTextField
                      autoComplete='off'
                      hasValue={!!projectName}
                      variant="outlined"
                      name="projectName"
                      data-test-id="projectName"
                      value={formik.values.projectName}
                      onChange={(event) => {
                        if (this.state.backendErrors.projectName) {
                          this.setState((prevState) => ({
                            backendErrors: {
                              ...prevState.backendErrors,
                              projectName: '',
                            },
                          }));
                        }
                    
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      error={Boolean(this.getError('projectName', formik, this.state.backendErrors))}
                      helperText={this.getError('projectName', formik, this.state.backendErrors)}
                      FormHelperTextProps={{
                        style: { marginLeft: 0,
                          color: '#FF0000',
                          fontSize: 'small',
                          marginTop: '2px',
                          fontFamily: 'Poppins'
                        },
                      }}
                      fullWidth
                      placeholder="Enter project name"
                      inputProps={{
                        style: {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                  </Section>
                  <CustomDivider />
                
                 {this.renderClientDetailsSection({
        planId,
        firstName,
        lastName,
        email,
        countryCode,
        phoneNumber,
        configJSON,
        formik
      })}
                 
                  <Section>
                    <SectionLabel>Address</SectionLabel>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <InputLabel>Street Name *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          hasValue={!!streetName}
                          variant="outlined"
                          name="streetName"
                          data-test-id="streetName"
                          value={formik.values.streetName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(this.getError('streetName', formik))}
                  helperText={this.getError('streetName', formik)}
                  FormHelperTextProps={{
                    style: { marginLeft: 0,
                      color: '#FF0000',
                      fontSize: 'small',
                      marginTop: '2px',
                      fontFamily: 'Poppins'
                    },
                  }}
                  fullWidth
                  placeholder="Enter name street"
                  inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}

                        />
                       
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>City *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          hasValue={!!city}
                          name="city"
                          variant="outlined"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(this.getError('city', formik))}
                          helperText={this.getError('city', formik)}
                          FormHelperTextProps={{
                            style: { marginLeft: 0,
                              color: '#FF0000',
                              fontSize: 'small',
                              marginTop: '2px',
                              fontFamily: 'Poppins'
                            },
                          }}
                          fullWidth
                          placeholder="Enter city"
                          data-test-id="city-input"
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Post code *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          data-test-id="postCode"
                          variant="outlined"
                          name="postCode"
                          hasValue={!!postCode}
                          value={formik.values.postCode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(this.getError('postCode', formik))}
                          helperText={this.getError('postCode', formik)}
                          FormHelperTextProps={{
                            style: { marginLeft: 0,
                              color: '#FF0000',
                              fontSize: 'small',
                              marginTop: '2px',
                              fontFamily: 'Poppins'
                            },
                          }}
                          fullWidth
                          placeholder="Enter post code"
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth 
                        variant="outlined">
                          <InputLabel>State *</InputLabel>
                          <CustomSelect
                            IconComponent={CustomArrowIcon}
                            data-test-id="stateinput"
                            name="state"
                            hasValue={Boolean(state)}
                            onChange={(e) =>
                              formik.handleChange(e)
                            }
                            value={formik.values.state}
                            onBlur={formik.handleBlur}
                            error={Boolean(this.getError('state', formik))}
                            displayEmpty
                            inputProps={{
                              style: { fontFamily: "'Poppins', sans-serif" },
                            }}
                          >
                            <MenuItem value="">
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                Select state
                              </Typography>
                            </MenuItem>
                            {this.state.stateList.map((state, index) => (
                              <MenuItem
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                                key={index}
                                value={state.isoCode}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                          {Boolean(this.getError('state', formik)) && (
    <Typography
      variant="caption"
      color="error"
      style={{  color: '#FF0000',
        fontSize: 'small',
        marginTop: '2px',
        fontFamily: 'Poppins' }}
    >
      {this.getError('state', formik)}
    </Typography>
  )}
                        </FormControl>
                       
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth 
                        variant="outlined">
                          <InputLabel>Country *</InputLabel>
                          <CustomSelect
                            IconComponent={CustomArrowIcon}
                            data-test-id="countryName"
                            name="country"
                            onChange={(event) => this.handleCountryChangeAndGetState(event, formik.setFieldValue)}
                            value={formik.values.country}
                            hasValue={Boolean(country)}
                            onBlur={formik.handleBlur}
                            error={Boolean(this.getError('country', formik))}
                            
                            displayEmpty
                            inputProps={{
                              style: { fontFamily: "'Poppins', sans-serif" },
                            }}
                          >
                            <MenuItem value="">
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                Select country
                              </Typography>
                            </MenuItem>
                            {this.state.countryList.map((country, index) => (
                              <MenuItem
                                key={index}
                                value={country.isoCode}
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                {country.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                          {Boolean(this.getError('country', formik)) && (
    <Typography
      variant="caption"
      color="error"
      style={{  color: '#FF0000',
        fontSize: 'small',
        marginTop: '2px',
        fontFamily: 'Poppins' }}
    >
      {this.getError('country', formik)}
    </Typography>
  )}
                        </FormControl>
                       
                      </Grid>
                    </Grid>
                  </Section>
                  <CustomDivider/>
                  <Section>
                    <SectionLabel>Additional Information</SectionLabel>
                    <InputLabel>Description</InputLabel>
                    <ProjectTextField
                      autoComplete='off'
                      data-test-id="projectDescription"
                      hasValue={!!projectDescription}
                      value={formik.values.projectDescription}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(this.getError('projectDescription', formik))}
                      helperText={this.getError('projectDescription', formik)}
                      FormHelperTextProps={{
                        style: { marginLeft: 0,
                          color: '#FF0000',
                          fontSize: 'small',
                          marginTop: '2px',
                          fontFamily: 'Poppins'
                        },
                      }}
                      name="projectDescription"
                      variant="outlined"
                      fullWidth
                      placeholder="Type something here..."
                      multiline
                      rows={2}
                      inputProps={{
                        style: {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                  
                  </Section>
                  
                </FormContainer>
                </Box>
                </form>
              </>
            )}
          </Formik>
        </MyProfileHeader>
        <AddAndViewMember open={this.state.openAddMemberDialog} close={this.handleCloseViewAndAddMemberDiload}  projectId={this.state.projectId} planId={this.state.planId} navigation={undefined} id={""}/>
      </Wrapper>
    );
  }
}

// Customizable Area End
