// Customisable Area Start
import React from "react";
import CreateEditProjectPortfolioController, {
  FileData,
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import AddAndViewMember from "./AddAndViewMember.web";
import {
  Box,
  Button,
  createTheme,
  Grid,
  Paper,
  styled,
  SvgIcon,
  ThemeProvider,
  Typography,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import {
  ArrowBackIos,
  Close,
  ExpandLess,
  ExpandMore,
  FolderOpen,
  Clear
} from "@material-ui/icons";
import { file_upload } from "./assets";
import Dropzone, { DropzoneState } from "react-dropzone";
// Customizable Area Start


const theme = createTheme({ palette: {primary: {main: "#fff",
      contrastText: "#fff",},
  },
  typography: {body1: {
      fontFamily: "Poppins",},
  },});

 const styleSheet = {logoutButton: {
   background: "#237182",color: "#FFFFFF",
   fontSize: "15px",fontWeight:600,
   fontFamily: "Poppins",textTransform: "none",
   height: "56px",borderRadius: "8px",
   width: "148px",padding: "10px 16px 10px 16px",} as const,
 logoutButton2: {background: "#237182",color: "#FFFFFF",
   fontSize: "16px",fontFamily: "Poppins",
   textTransform: "none",height: "56px",
   borderRadius: "8px",width: "121px",
   padding: "10px 16px 10px 16px",fontWeight:600,} as const,
 cancelButton: {
   backgroundColor: "#E2E8F0",color: "#325962",
   fontSize: "16px",fontFamily: "Poppins",
   textTransform: "none",height: "56px",
   borderRadius: "8px",width: "96px",fontWeight:600,} as const,
 title: {
   fontWeight: 700,fontSize: "24px",
   color: "#0F172A",fontFamily: "Poppins",
   lineHeight: "32px",letterSpacing: "-0.005em"},}

const SaveButton = styled(Button)({
 backgroundColor: "#E0EDF0",borderRadius: "8px",border: "1px solid #C5E0E7",
  color: "#325962",marginRight: "13px",fontWeight: 600,
  fontSize: "16px",height: "44px",letterSpacing: 0,
  textTransform: "none",padding: "10px 16px 10px 16px",boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",borderWidth:0,
  width: '117px',
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#E0EDF0"
  },

});
const SaveButtonEnable = styled(Button)({
  fontFamily: "'Poppins', sans-serif",backgroundColor: "red",color: "#fff",
  textTransform: "none",display:"flex",fontSize: "16px",
  fontWeight: 600,height: "44px",padding: "10px 16px 10px 16px",
  borderRadius: "8px",
  "&:hover": {backgroundColor: "#237182"},
  border: "1px solid #C5E0E7",marginRight: "13px",letterSpacing: 0,
  boxShadow: "none",borderWidth:0});

const StyledBox = styled(Box)({
  padding: "2rem 2.5rem",
  textAlign: "center",
  height: "fit-content",
  background: "#F0F5F7",
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  "& .backButton": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "600",
    width: "30vw",
    "& p": {
      fontSize: "0.875rem",
      color: "#334155",
      fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#334155",
    },
  },
  "& .progressPercentage":{
fontFamily: 'Poppins',
fontSize: '12px',
fontWeight: 400,
lineHeight: '16px',
color:'#64748B'


  },
  "& .titleText": {
    color: "#0F172A",
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
  },
  "& .buttonsBlock": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "30vw",
    justifyContent: "end",
  },
  "& .addMemberBtn": {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
    zIndex: 1,
  },
  "& .addMemberIcon": {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  "& .addMemberText": {
    fontWeight: 600,
    color: "#237182",
  },
  "& .saveDraftBtn": {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    textTransform: "none",
    fontFamily: "Poppins",
  },
  "& .subTitle": {
    fontSize: "1.125rem",
    color: "#475569",
    lineHeight: "1.5rem",
    marginBottom: "2rem",
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: 0,
  },
  "& .typeItem": {
    padding: "1rem",
    background: "#FFF",
    borderRadius: "14px",
    "& .typeItemHeader": {
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
    },
    "& .typeTitle": {
      fontFamily: 'Poppins',
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: "#0F172A",
    },
    "& .folderIcon": {
      padding: "0.5rem",
      background: "#E0EDF0",
      color: "#237182",
      borderRadius: "8px",
    },
  },
  "& .selectTypeBtn": {
    width: "100%",
    background: "#237182",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    "&:disabled": {
      background: "transparent",
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#64748B",
    },
  },
  "& .savedraftbutton": {
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
  },
  "& .uploadHeader": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    "& p": {
      color: "#237182",
      fontWeight: 600,
    },
  },
  "& .dragText": {
    fontSize: "0.875rem",
    color: "#292524",
    fontWeight: 600,
    lineHeight: "1.375rem",
  },
  "& .fileFormatText": {
    color: "#475569",
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  "& .fileBlock": {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008;",
    marginTop: "1rem",
    padding: "0.5rem",
    gap: "0.75rem",
    borderRadius: "8px",
    textAlign: "start",
    "& .fileLogo": {
      padding: "0.5rem",
      borderRadius: "4px",
    },
    "& .fileName": {
      fontSize: "0.875rem",
      lineHeight: "1.375rem",
      color: "#0F172A",
    },
    "& .fileSize": {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#64748B",
    },
    "& .fileError": {
      fontWeight:400,
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#DC2626",
    },
    "& .moreIcon": {
      padding: "0.5rem",
      color: "#475569",
      cursor: "pointer",
    },
  },
  "& .fileCollapse": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem",
    "& .uploadedFilesText": {
      fontSize: "0.875rem",
      lineHeihgt: "1.375rem",
      color: "#334155",
    },
    "& .uploadedFilesCount": {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#237182",
    },
    "& .collapseBtn": {
      display: "flex",
      gap: "0.75rem",
      cursor: "pointer",
    },
    " & .collapseText": {
      color: "#237182",
      fontWeight: 600,
    },
  },
});
// Customizable Area End

export default class ProjectCostPlanUpload extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.renderDropZone = this.renderDropZone.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

renderDropZone = (sectionId: number) => {
  const { fileCounts } = this.state;
  const fileCount = fileCounts[sectionId] || 0;

  return (
    <Dropzone
      onDrop={(acceptedFiles) => this.handleFileChange(sectionId, acceptedFiles)}
      accept={{ 'image/*': [], 'application/pdf': [] }}
      maxFiles={5} 
      disabled={fileCount >= 5} 
    >
      {({ getRootProps, getInputProps, isDragActive }: DropzoneState) => (
        <Paper
          {...getRootProps()}
          elevation={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            borderRadius: '8px',
            padding: '1rem',
            backgroundColor: '#F1F5F9',
            border: '2px dashed rgba(203, 213, 225, 1)',
            boxSizing: 'border-box',
            marginTop: '1rem',
            cursor: fileCount >= 5 ? 'not-allowed' : 'pointer',
          }}
        >
          <input {...getInputProps()} />

          {isDragActive ? (
            <Typography>Drop the files here ...</Typography>
          ) : (
            <>
            
              <Box className="uploadHeader">
                <img src={file_upload} alt="Upload Icon" />
                <Typography>Upload Files</Typography>
              </Box>
              <Typography className="dragText">Drag & Drop Files Here</Typography>
              <Typography className="fileFormatText">Acceptable formats: PDF & Images JPG, JPEG, PNG</Typography>
              <Typography className="fileFormatText">(Max size: 10MB)</Typography>
            </>
          )}
        </Paper>
      )}
    </Dropzone>
  );
};


getProgressWidth = (file: FileData) => {
  if (file.error) {
    return '100%';
  }
  return `${file.progress ?? 100}%`;
};

getBorderTopStyle = (file: FileData) => {
  if (file.error) {
    return '4px solid #DD4126';
  } else if (file.progress !== undefined && file.progress < 100) {
    return '4px solid #237182';
  } else {
    return '4px solid #67D49A'; 
  }
};

getFileStatusText = (file: FileData, shouldShowProgress: boolean) => {
  if (file.error) {
    return file.error;
  } else if (shouldShowProgress) {
    return this.getProgressText(file);
  }
  return null;
};

renderProgressBar = (file: FileData) => {
  const progressWidth = this.getProgressWidth(file);
  const borderTopStyle = this.getBorderTopStyle(file); 

  return (
    <>
    
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '4px',
          width: '100%',
          zIndex: 1,
        }}
      />
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '4px',
          width: progressWidth,
          borderTop: borderTopStyle,
        }}
      />
    </>
  );
};

getProgressText = (file: FileData) => {  
  return file.progress === 100 || !file.progress ? `${file.size}` : `${file.progress}% complete`;
};

renderFileCollapse = (filesLength: number, sectionId: number, fileListExpanded: Record<number, boolean>) => {
  return(
  <Box
    className="fileCollapse"
    data-test-id="handleFilesCollapse"
    onClick={() => this.handleFilesCollapse(sectionId)}
    style={{ cursor: 'pointer' }}
  >
    <Typography className="uploadedFilesText">
      Uploaded Files: <span className="uploadedFilesCount">{filesLength}</span>
    </Typography>
    
    <Box className="collapseBtn">
      <Typography className="collapseText">
        {fileListExpanded[sectionId] ? 'Show less' : 'Show more'}
      </Typography>
      
      {fileListExpanded[sectionId] ? <ExpandLess /> : <ExpandMore />}
    </Box>
  </Box>
  )
};


renderFileBlock = (file: FileData, handleFileDelete: (sectionId: number, fileName: string, error:string) => void) => {
  const iconBackgroundColor = file.error ? '#FEE2E2' : '#E0EDF0';
  const shouldShowProgress = !file.error && parseFloat(file.size) <= 10 * 1024 * 1024;
  const fileStatusText = this.getFileStatusText(file, shouldShowProgress);
  

  return (
      <Box
          className="fileBlock"
          style={{
              borderRadius: '10px',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: '#FFFFFF',
          }}
          key={file.file_name} 
      >
          {this.renderProgressBar(file)}

          <SvgIcon
              style={{
                  width: 38,
                  height: 38,
                  borderRadius: '4px',
                  background: iconBackgroundColor,
              }}
          >
              {this.planLogo(file.content_type, !!file.error)}
          </SvgIcon>

          <Box style={{ width: '100%' }}>
              <Typography className="fileName">{file.file_name}</Typography>
              <Typography className={file.error ? 'fileError' : 'progressPercentage'} color={file.error ? 'error' : undefined}>
                  {fileStatusText}
              </Typography>
              
          </Box>

          <Close
              style={{ cursor: 'pointer' }}
              onClick={() => {
                  const sectionId = file.section_id;
                  if (sectionId !== undefined) {
                      handleFileDelete(sectionId, file.file_name, file.error || '');
                  } 
              }}
          />
      </Box>
  );
};

renderFileList = (sectionId: number, files: FileData[]) => {
  const { fileListExpanded } = this.state;
  const sortedFiles = files.slice().sort((a, b) => {
    return a.file_name.localeCompare(b.file_name); 
 
  });

  const visibleFiles = fileListExpanded[sectionId] ? sortedFiles : sortedFiles.slice(0, 2);



  return (
      <>
          {visibleFiles.map((file) => this.renderFileBlock(file, this.handleFileDelete))}

          {sortedFiles.length > 2 && this.renderFileCollapse(sortedFiles.length, sectionId, fileListExpanded)}
      </>
  );
};

handleFilesCollapse = (sectionId: number) => {
  this.setState((prevState) => ({
      fileListExpanded: {
          ...prevState.fileListExpanded,
          [sectionId]: !prevState.fileListExpanded[sectionId],
      },
  }));
};

  // Customizable Area End
  render() {
    const { specialistCostPlanList } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          {specialistCostPlanList.length > 0 &&
          <StyledBox>
          <Box>
            <Box className="pageTitle">
              <Box className="backButton" data-test-id="backButon" onClick={() => this.goBack(this.state.projectId)}>
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
              <Typography className="titleText">
                Upload Files for Specialist Cost Plan
              </Typography>
              <Box className="buttonsBlock">
              <Box style={{
                              display: "flex",
                              gap: "0.5rem",
                              cursor: "pointer",
                                   }}>
                  <Typography onClick={this.handleOpenViewAndAddMemberDiload} style={{
                                    fontWeight: 600,
                                    color: "#237182",
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontFamily: 'Poppins'
                                             }}>
                                     View & Add Member
                                 </Typography>
                </Box>
                    <SaveButtonEnable className="savedraftbutton" style={{ height:'44px', width: "auto" }}
                      data-test-id="btnSubmitProjectData"
                      onClick={this.handleOpenDialog}>
                      Save Draft</SaveButtonEnable>
                    <Dialog PaperProps={{style: {
                        height: "232px",width: '584px',borderRadius: "16px",
                        padding: "40px 32px",color: "#FFFFFF", boxSizing:"border-box"
                      }
                    }} 
                    open={this.state.openDialog} 
                    onClose={this.handleCloseDialog}>
                      <Box sx={{ 
                        padding: 0 
                        }}>
                         <Box style={{display: "flex",justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >
                          Do you want to save your draft before exiting?
                          </Typography> 
                          <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",width: "20px"
                        }}><Clear />
                        </Typography>
                        </Box>
                        <DialogActions style={{marginTop: "32px",
                          gap: "16px",padding: "0px"}}>
                          <Button 
                          style={styleSheet.cancelButton} 
                          onClick={this.handleCloseDialog}>
                            Cancel</Button>
                          <Button  data-test-id="savedraftbtn" style={styleSheet.logoutButton2} onClick={this.handleSaveDraftUploadFile}>
                            Save Draft
                          </Button></DialogActions>

                      </Box>
                      </Dialog>
                <Button
                  className="selectTypeBtn"
                  style={{ width: "auto", height:'44px' }}
                  disabled={this.isSubmitDisabled()}
                  data-test-id="next-btn"
                  onClick={this.handleStripe}
                >
                  Next
                </Button>
              </Box>
            </Box>
            <Typography className="subTitle">
              Please upload the documents required for the cost estimation.
            </Typography>
        
   

<Grid container spacing={2} style={{ margin: '-9px' }}>
  {specialistCostPlanList
    .slice()
    .sort((a, b) => a.attributes.id - b.attributes.id)
    .map((section) => {
      const sectionAttributes = section.attributes;
      const sectionId = sectionAttributes.id;

      const uploadedSectionFiles = this.state.uploadedFiles[sectionId] || [];

      const newUploadedFiles = Object.values(this.state.formValues.projectImages)
        .flat()
        .filter((fileData: FileData) => fileData.section_id === sectionId);

      const allFilesForSection = [
        ...uploadedSectionFiles,
        ...newUploadedFiles.filter(
          (newFile) => !uploadedSectionFiles.some(oldFile => oldFile.file_name === newFile.file_name)
        ),
      ];

      return (
        <Grid item xs={12} md={6} lg={4} key={sectionId}>
          <Box className="typeItem">
            <Box className="typeItemHeader">
              <FolderOpen className="folderIcon" />
              <Typography className="typeTitle">
                {sectionAttributes.name} {!sectionAttributes.mandatory ? '(Optional)' : '*'}
              </Typography>
            </Box>

            {this.renderDropZone(sectionId)}

            <Box mt={2}>
              {this.renderFileList(sectionId, allFilesForSection)}
            
            </Box>
          </Box>
        </Grid>
      );
    })}
</Grid>

          
            </Box>
            </StyledBox>
          }
          {!this.state.loading && specialistCostPlanList.length===0 && <Grid container style={{display:'flex',flexDirection: 'column',height:'100vh'}}>
            <Box>
                <StyledBox>
                <Box className="pageTitle">
              <Box className="backButton" data-test-id="backBtnTwo" onClick={() => this.goBack(this.state.projectId)}>
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
              <Typography className="titleText">
                Upload Files for Specialist Cost Plan
              </Typography>
              <Box className="buttonsBlock"></Box>
               </Box>
               </StyledBox>
            </Box>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Box sx={{display:'flex', flexDirection:'column',alignItems:'center',marginTop:'-34px'}}>
            <Box sx={{display:'flex'}}>
            <Typography style={{    fontFamily: 'Poppins',
                  color:'#0F172A',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'center',}}>No cost plan yet</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
            <Typography style={{   
                  marginTop:'16px',
                  marginBottom:"24px",
                  color:'#0F172A',
                   fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',}}>Please add cost plans from admin.</Typography>
            </Box>
            </Box>  
            </Box>

            

            </Grid>}
            <AddAndViewMember open={this.state.openAddMemberDialog} close={this.handleCloseViewAndAddMemberDiload}  projectId={this.state.projectId} planId={this.state.planId} navigation={undefined} id={""}/>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}
// Customisable Area End
