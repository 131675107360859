// Customizable Area Start
export const filterIcon = require("../assets/filter.svg");
export const backIcon = require("../assets/backIcon.svg");
export const mockIcon = require("../assets/mock_icon.png");
export const file_upload = require("../assets/file_upload.png");
export const buildNewIcon = require("../assets/build_new_icon.png");
export const extendIcon = require("../assets/extend_icon.png");
export const remodelIcon = require("../assets/remodel_icon.png");
export const refurbishIcon = require("../assets/refurbish_icon.png");
export const addMember = require("../assets/add-member.png");
export const addProjectFrame = require("../assets/Frame.svg");
export const DraftIcon = require("../assets/DraftIcon.svg");
export const ProposedIcon = require("../assets/ProposedIcon.svg");
export const OngoingIcon = require("../assets/OngoingIcon.svg");
export const PausedIcon = require("../assets/PausedIcon.svg");
export const CompletedIcon = require("../assets/CompletedIcon.svg");
export const bedImage = require("../assets/image_bed.png");
export const roundPlusIcon = require("../assets/roundplusicon.png");
export const threeDotIcon = require("../assets/button_icon.png");
export const downArrowIcon = require("../assets/downArrow.png");
export const dollarIcon = require("../assets/dollar.png");
export const  upArrowIcon = require("../assets/upArrow.png");
export const  rightArrowIcon = require("../assets/rightArrow.png");
export const  calendarIcon = require("../assets/calendar.png");
export const  blackDownArrowIcon = require("../assets/blackDownArrow.png");
export const  frameIcon = require("../assets/Frame2.png");
export const notFound = require("../assets/NotFound.png");
export const imageEyeOff = require("../assets/imageEyeOff.svg");
export const imageEye = require("../assets/imageEye.svg");
export const arrowDown = require("../assets/arrowDown.svg");
export const crossSmallIcon = require("../assets/crossSmallIcon.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const crossSearchIcon = require("../assets/crossSearchIcon.svg");
export const addIcon = require("../assets/addIcon.svg");
export const avatarIcon = require("../assets/avatarIcon.svg");
export const successIcon = require("../assets/success.png");
export const dateIcon = require("../assets/dateIcon.svg");
export const pdfIcon = require('../assets/group_icon.png')
// Customizable Area End
