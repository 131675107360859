// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  navigation: any;
  id: string;
  membersData: Member[];
  selectedOptions: [];
  onSelectionChange?: (selectedOptions: any[]) => void;
  memberError: string;
}

interface OptionType {
  value: number | string; // Allow string for added text
  label: string;
  email: string;
}

interface Member {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

// Define the props for CustomSelect
interface CustomSelectProps {}

interface S {
  options: OptionType[];
  count: any;
  isMemberSelected: boolean;
  selectedMembers: any;
  inputValue: string;
  addedText:boolean;
}

interface SS {
  id: any;
}

export default class AddMembersModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      options: this.getOptions(props.membersData),
      count: null,
      isMemberSelected: false,
      selectedMembers: {},
      inputValue: "",
      addedText:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getOptions = (membersData: Member[]) => {
    return membersData.map((member) => {
      return {
        value: member.id,
        label: `${member?.first_name} ${member?.last_name}`,
        email: `${member?.email}`,
      };
    });
  };

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.membersData !== this.props.membersData) {
      this.setState({
        options: this.getOptions(this.props.membersData),
      });
    }
  }

  filterOption = (option: any, inputValue: string) => {
    const { label, email } = option.data;
    const input = inputValue.toLowerCase();
    this.setState({
      inputValue:input
    })

    return (
      label.toLowerCase().includes(input) || email.toLowerCase().includes(input)
    );
  };

  handleChange = (selectedOptions: any) => {
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(selectedOptions || []);
    }
  };

  onInputChange = (inputValue: string) => {
    const { options } = this.state;
    if (!inputValue) {
      this.setState({
        isMemberSelected: false,
      });
        
      
      
    }

    const filteredOptions = options.filter((option) => {
      const { label, email } = option;
      const input = inputValue.toLowerCase();
      return (
        label.toLowerCase().includes(input) ||
        email.toLowerCase().includes(input)
      );
    });

    this.setState({
      count: filteredOptions.length,
    });
  };

  handleSelectMember = (memberId: number) => {
    this.setState((prevState) => ({
      selectedMembers: {
        ...prevState.selectedMembers,
        [memberId]: !prevState.selectedMembers[memberId],
      },
    }));
  };
  handleAddAsText = () => {
    const { inputValue, options } = this.state;
  
    if (!inputValue.trim()) {
      return;
    }
  
    // Create a new option
    const newOption: OptionType = {
      value: inputValue, // Use inputValue as the value
      label: inputValue, // Use inputValue as the label
      email: "", // Optional, since it's a custom text
    };
  
    // Update the options state with the new option
    this.setState({
      options: [...options, newOption], // Add the new option to existing options
      inputValue: "", // Clear the input field
      addedText: true, // Mark the text as added
    });
  
    // Notify the parent component of the change if onSelectionChange is defined
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange([...options, newOption]);
    }
  };
  
  

  

}

// Customizable Area End
