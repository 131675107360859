import React, { createRef } from "react";

// Customizable Area Start
import {
    Box,
    Input,
    Typography,
    Button,
  Modal,

} from "@mui/material";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    styled,
} from "@mui/material/styles";
import { Close} from "@material-ui/icons";

import ChatConversationController, { Props } from "./ChatConversationController";
import {profile,} from "./assets";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'; 
import ReplyIcon from '@material-ui/icons/Reply';
import { borderRadius } from "react-select/src/theme";



const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class ChatConversation extends ChatConversationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.chatEndRef = createRef();
        // Customizable Area End
    }

    // Customizable Area Start
    InputSendMSG = ()=>{
      const { sendMessage } = this.state;
        return(
      <Box style={{ padding: "11px 20px", display: "flex", position: "relative", background: "white" }}>
      <input
        type="file"
        accept="*/*" 
        ref={this.fileInputRef}
        style={{ display: 'none' }} 
        onChange={this.handleFileChange}
      />
      
      <CustomInput
        endAdornment={
          <div onClick={this.handleIconClick} style={webStyles.attachment_icon} >
            <AttachFileIcon style={{ color: '#94A3B8' }} /> 
          </div>
        }
        style={webStyles.sendmsgInput}
                placeholder="Type a message here..."
                disableUnderline
                data-test-id="Type a message here..."
                value={this.state.sendMessage}
        onChange={this.handleInputChange} 
      />

         <Button
                  data-test-id="sendBtn-id"
                  onClick={this.sendMessage}
                  style={{
                    background: sendMessage ? "#237182" : "#F1F5F9",
                    color: sendMessage ? "#FFFFFF" : "#64748B",

                    fontFamily:'Poppins',
                    fontWeight:600,
                    fontSize:'16px',
                    lineHeight:'24px',
                    marginLeft:"15px",
                    padding:"10px 16px",
                    textTransform: "capitalize",
                  }}
               
                >
                  Send
                </Button>
    </Box>
        )
    
      }
       formatTimestampTo12Hour(timeString: string | number | Date) {
        const date = new Date(timeString);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12;
        hours = hours ? hours : 12; // Convert 0 to 12
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        
        return `${hours}:${formattedMinutes} ${ampm}`;
      }
    // Customizable Area End

    render() {

        // Customizable Area Start
        return (
            <StyledEngineProvider injectFirst>

                <ThemeProvider theme={theme}>
                <MainchatMsg >
                <Box>
  <Box className="today_date">
    <Box className="date_line"></Box>
    <Typography className="date">16 May, 2024</Typography>
  </Box>

  {this.state.allChatMessageList.map((item) => {
    
    
  const timestamp = item.timestamp;
  const formattedTime = this.formatTimestampTo12Hour(timestamp);
  
  return (
    <OuttorWrappr   key={item.sid}>
      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            src={profile}
            alt=""
            width={"32px"}
            height={"32px"}
            style={{ borderRadius: "6px", marginRight: "10px" }}
          />
          <Typography className="rece-user">{item?.sender}</Typography>
          <Typography className="rece-msg_time">{formattedTime}</Typography>
        </Box>
      </Box>
      
      <Typography className="receive_msg">{item?.body}</Typography>

      <Box className="optionBox">
        <Box style={{display:"flex",gap:"4px"}}>
        
          <ReplyIcon  style={{width:'22px',height:'22px',color:'#475569'}}/>
          <PushPinOutlinedIcon  style={{width:'22px',height:'22px',color:'#475569'}}/>
          {this.state.username === item?.sender? 
          <DeleteOutlineIcon style={{ width: "22px", height: "22px", color: "#DC2626" }} onClick={this.handleDeleteMessage.bind(this, item.sid)} />
          :""
          }

        </Box>
        </Box>
    </OuttorWrappr >
  );
})}
  <div ref={this.chatEndRef} />
</Box>
</MainchatMsg>
{ this.InputSendMSG() }
<Modal open={this.state.reMoveMemberModalOpen} style={{ display: "flex" }}>
  <Box style={webStyles.renameModal}>
    <Box style={webStyles.modalHeader}>
      <Typography style={webStyles.renameText}>Delete Message</Typography>
      <Close
        data-test-id="close-data-id"
        onClick={this.reMoveMemberModelclose.bind(this)}
        style={{ cursor: "pointer" }}
      />
    </Box>
    <Typography style={webStyles.leave_chat_sub_heading}>
      Are you sure you want to delete this message
    </Typography>
    <Box style={{ textAlign: "end", marginTop: "24px" }}>
      <RenameButton
        data-test-id="create-id"
        style={{
          padding: "1rem",
          borderRadius: "8px",
          fontFamily: "Poppins",
          textTransform: "none",
          background: "#E0EDF0",
          color: "#325962",
          fontWeight: 600,
          fontSize: "16px",
        }}
        onClick={this.reMoveMemberModelclose}
      >
        Cancel
      </RenameButton>
      <RenameButton
        style={{
          marginLeft: "8px",
          padding: "1rem",
          borderRadius: "8px",
          fontFamily: "Poppins",
          textTransform: "none",
          background: "#237182",
          color: "#FFFFFF",
          fontWeight: 600,
          fontSize: "16px",
        }}
        onClick={this.deleteMessageConfirm.bind(this)}
        data-test-id="leavechatConfirm"
      >
        Confirm
      </RenameButton>
    </Box>
  </Box>
</Modal>

                </ThemeProvider>
            </StyledEngineProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const OuttorWrappr = styled(Box)({
  padding: "20px 10px",
  position: "relative",
  "& .optionBox": {
    display:"none",
  },
 "&:hover": {
  background: "#F8FAFC",
  borderRadius:"2px",


    "& .optionBox": {
      display:"block",
      padding: "2px 4px",
      background: "#FFFFFF",
      border: "1px solid #E2E8F0",
      borderRadius:"6px",
      position: "absolute",
      right: 9,
      top: 6,
    },
  }
});
const MainchatMsg = styled(Box)({
    height: "calc(100vh - 238px)",
    overflowY: "scroll", 
    background:"#F1F5F9",
    padding:"0 20px",
        overflowX: "hidden", 
        "& .today_date":{
          zIndex: 1,
          position: 'relative',
        },
        "& .date_line":{
          background: '#CBD5E1',
          textAlign: 'center',
          height: '2px',
        width: '100%',
        marginTop:"15px",
        },
        "& .date":{
          background: '#F1F5F9',
          position: 'absolute',
          left: 0,
          right: 0,
          top: '-19px',
          width: 'fit-content',
          margin: 'auto',
          padding: '10px',
          zIndex: 9999,
          fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
        },
    "& .receive_msg":{
      paddingLeft:"44px",
      maxWidth: "600px",
      fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color:"#0F172A"
      
    },
    "& .rece-user":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color:"#0F172A",
    },
    "& .rece-msg_time":{
      fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color:"#94A3B8",
    paddingLeft:"6px"
    },
      scrollbarWidth: "auto", 
      scrollbarColor:" #CBD5E1 #ffffff",  
    
       "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#CBD5E1",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F1F5F9",
      },
    
    
    
    });
    const CustomInput = styled(Input)({
        padding:" 0px 0px 0px 5px",
      "& .MuiInputBase-input::placeholder": {
        fontSize:'16px',
    color: "#94A3B8",
    fontFamily:'Poppins',
    lineHeight:"24px",
    fontWeight:400,
  },
      });
      const RenameButton = styled(Button)({
        padding: "1rem",
        textTransform: "none",
        background: "#237182",
        color: "#FFF",
        fontWeight: 600,
        borderRadius: "8px",
        fontFamily: "Poppins",
      
        "&:disabled": {
          background: "#F1F5F9",
          color: "#64748B",
        },
        "&:hover": {
          background: "#237182",
        },
      
      });

const webStyles = {
  leave_chat_sub_heading:{
    fontSize: "16px",
    lineHeight: "24px",
    color:"#1E293B",
    fontFamily: "poppins",
    fontWeight: 400,
  },
  modalHeader: {
    display: "flex",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
    justifyContent: "space-between",
  },
  renameModal: {
    height: "fit-content",
    padding: "2.5rem 2rem",
    width: "40vw",
    margin: "auto",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  renameText: {
    fontFamily: "poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color:"#0F172A"
  },
    attachment_icon: {
      width: "24px",
      height: "24px",
      marginRight: "0.5rem",
      color: "#94A3B8",
      cursor:'pointer',
      },
      sendmsgInput: {
        border: "1px solid #C4CBD4",
        background: "#FCFDFE",
        borderRadius:'8px',
        color:'#0F172A',
        padding:"10px 0px 10px 10px",
        fontFamily:'Poppins',
        width:'100%',
        fontWeight:400,
        fontSize:'16px',
        lineHeight:"24px",
        
      },
}

// Customizable Area End
