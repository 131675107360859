import React, { Component } from 'react';
const SuccessfullyLogo = require("./success.png");
const UnSuccessfullyLogo = require("./unsuccess.png");

interface MessageProps {
    message:string,
    status:string,
    stylePosition?:any
}

interface MessageState {

}

class Message extends Component<MessageProps, MessageState> {
    constructor(props: MessageProps) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div style={{...stylesheet.messageBox, ...this.props.stylePosition } as React.CSSProperties}>
                {this.props.status== "success"?<div style={{display:"flex",justifyContent:"center", alignItems:"center", gap: "8px", padding: "8px 16px 8px 8px"}}>
                    <img src={SuccessfullyLogo} width={"26px"} height={"26px"}/> <p style={{ margin:"0px 0px" }}> { this.props.message } </p>
                </div>:<div style={{display:"flex",justifyContent:"center", alignItems:"center", gap: "8px", padding: "8px 16px 8px 8px"}}>
                    <img src={UnSuccessfullyLogo} width={"26px"} height={"26px"}/> <p style={{ margin:"0px 0px" }}> { this.props.message } </p>
                </div>}
            </div>
        );
    }
}

const stylesheet = {
    messageBox: {
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0F172A",
        backgroundColor: "white",
        display: "inline-block",
        position: "fixed", 
        top: "100px", 
        left: "50%", 
        transform: "translate(-50%, 0)", 
        borderRadius: "8px",
        zIndex: "100", 
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", 
    }
};

export default Message;
