// Customizable Area Start
import React from "react";

import { Box,Checkbox,Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  RadioGroup,
  TextField,
  Typography, } from "@mui/material";

import "./ProjectCard.css";
import "react-multi-carousel/lib/styles.css";
import { projectCarouselData, status } from "./types";
import { ProjectCarousel } from "./ProjectCarousel.web";

import KanbanBoardController, { Props } from "./KanbanBoardController";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {unCheckIcon,checkedIcon, success,} from "./assets"
import { Close } from "@material-ui/icons";
import CustomSelect from "./AddMembersModal.web";
import { Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';


export default class KanbanBoard extends KanbanBoardController {
  constructor(props: Props) {
    super(props);
  }

  renderChangeListView=()=>{
    return(
      <Box component="div" style={webStyle.OptionBox}>
                  <Box component="div" style={webStyle.singleOptionBox}>
                    <Box
                      data-test-id="button1"
                      onClick={() => this.handleToggle("Kanban View")}
                      component="div"
                      style={{
                        ...webStyle.PersonalInformationOption,
                        backgroundColor: this.state.toggle ? "#E0EDF0" : "",
                        color: this.state.toggle ? "#325962" : "#64748B",
                      }}
                    >
                      Kanban View
                    </Box>
                    <Box
                      data-test-id="button2"
                      onClick={() => this.handleToggle("List View")}
                      component="div"
                      style={{
                        ...webStyle.BusinessInformationOption,
                        backgroundColor: this.state.toggle ? "" : "#E0EDF0",
                        color: this.state.toggle ? "#64748B" : "#325962",
                      }}
                    >
                      List View
                    </Box>
                  </Box>
                </Box>
    )
  }

  render() {
    const statuses = [
      { name: "To Do", colorCode: "#60A5FA" },
      { name: "In Progress", colorCode: "#F59E0B" },
      { name: "Completed", colorCode: "#10B981" },
      { name: "Blocked", colorCode: "#F87171" },
    ];
    return (
      <>
        <ThemeProvider theme={theme}>
        <MyProfileHeader showSidebar={true} navigation={this.props.navigation}>
        {this.state.messageToggle && (
              <MessageBox
                message={this.state.tostifymessage}
                status={this.state.status}
              />
            )}
          <Grid container sx={webStyle.gridCont} gap={2}>
            <Box style={{ margin: "2rem 2rem 2rem 2.9rem", width: "100%" }}>
              <Box style={{fontSize:"24px" ,fontFamily:"Poppins", fontWeight:700 , color:"#0F172A", lineHeight:"32px" }}>{this.state.projectName}</Box>
              <Box style={webStyle.headerSection2}>
              <Box style={{display:"flex",alignItems:"center",gap:"24px"}}> 
                 {this.renderChangeListView()}
                 <Box
                  style={{
                    color: '#94A3B8',
                    border: '1px solid #CBD5E1',
                    borderRadius: '8px',
                    padding: '10px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={this.handleMenuOpen}
                >
                  <Typography>Bulk Action</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
                
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  <MenuItem
                    onClick={(e) => this.handleActionClick('Change Status', e)}
                    sx={{
                      borderRadius: '10px',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                  >
                    Change Status <KeyboardArrowRightIcon />
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleActionClick('Assign User', '')}
                    sx={{
                      borderRadius: '10px',
                    }}
                  >
                    Assign User
                  </MenuItem>
                </Menu>
                
                <Menu
                  anchorEl={this.state.subMenuAnchorEl}
                  open={Boolean(this.state.subMenuOpen)}
                  onClose={this.handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  {['To Do', 'In Progress', 'Completed', 'Blocked', 'Archived'].map((status) => (
                  <MenuItem
                  key={status}
                  data-test-id="sub-menu-id"
                  onClick={() => this.handleSubMenuClick(status)}
                  sx={{
                    borderRadius: '8px',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: '#ffffff',
                    },
                  }}
                >
                  <Typography
                    style={{
                      ...webStyle.statusStyle,
                      backgroundColor: this.getStatusBackgroundColor(status), 
                    }}
                  >
                    {status}
                  </Typography>
                </MenuItem>
                  ))}
                </Menu>
            </Box>
                
              <Box style={{display:"flex",alignItems:"center",gap:"24px"}}> 
              <Box
                  style={{ display: "flex", alignItems: "center", gap: "0" }}
                >
                  <Box style={webStyle.sort}>
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Sort By:
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <select
                        style={{
                          border: "none",
                          color: "#237182",
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          backgroundColor: "#f0f5f7",
                          marginBottom: "2px",
                          marginRight: "5px",
                          paddingRight: "30px",
                          appearance: "none",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                        }}
                        name="sort"
                        data-test-id="sort-id"
                      >
                        <option value="a-z">Due Date</option>
                        <option value="z-a">Closest First</option>
                        <option value="z-a">Furthest First</option>

                      </select>

                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                          fontSize: "20px",
                          color: "#237182",
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2292 7.50155L9.99583 10.7349L6.7625 7.50155C6.60681 7.34551 6.39543 7.25781 6.175 7.25781C5.95457 7.25781 5.74319 7.34551 5.5875 7.50155C5.2625 7.82655 5.2625 8.35155 5.5875 8.67655L9.4125 12.5016C9.7375 12.8266 10.2625 12.8266 10.5875 12.5016L14.4125 8.67655C14.7375 8.35155 14.7375 7.82655 14.4125 7.50155C14.0875 7.18488 13.5542 7.17655 13.2292 7.50155Z"
                            fill="#237182"
                          />
                        </svg>
                      </span>
                    </Box>
                  </Box>
                </Box>

                <Box onClick={this.handleFilter} data-test-id="filter-id" style={webStyle.filterStyle}>
                 <FilterListIcon width="15px" height="10px" /> <Typography style={{color:'#94A3B8' ,textAlign:"center" , paddingLeft:"0.2rem", cursor:"pointer"}}>Filter</Typography> 
                </Box>

                <Box>
                  <TextField
                    variant="outlined"
                    placeholder="Search here..."
                    data-test-id="search-input"
                    value={this.state.searchText}
                    onChange={(e:any) => this.handleSearch(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              height: "20px",
                              color: "#94A3B8",
                              width: "20px",
                            }}
                          />
                        </InputAdornment>
                      ),

                      style: {
                        width: "460px",
                        height: "6rem",
                        borderRadius: "8px",
                        fontFamily:"poppins"
                      },
                    }}
                    inputProps={{
                    style: {
                    padding: "10px 16px", 
                    fontFamily:"Poppins"
                     },
                    maxLength: 50,
                    }}
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "white",
                      height: "46px",
                      marginRight: "0.9rem",
                      fontFamily:"poppins"
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                      },
                    }}
                  />
                </Box>
              </Box>
              </Box>
              <Grid container style={{ width: "100%" }}>
                {statuses.map((statusObj) => (
                  <Grid
                    key={statusObj.name}
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <Box>
                      {this.renderStatusData(
                        statusObj.name,
                        this.filterTasksByStatus(statusObj.name.toLowerCase()).length,
                        statusObj.colorCode
                      )}
                     {this.filterTasksByStatus(statusObj.name.toLowerCase()).map((task, index) => {
                      const textColor = this.getPriorityColor(task?.priority, "text");
                      const backgroundColor = this.getPriorityColor(task?.priority, "background");
                      const assigneeName = task?.assignee_name?.[0] || "No Assignee";

                      return this.renderCard(
                        task?.taskId,
                        task?.roomName,
                        task?.taskName,
                        task?.priority,
                        textColor,
                        task?.checklist,
                        assigneeName,
                        "07/12/20 - 07/09/24",
                        backgroundColor,
                        task?.roomId
                        );
                      })}

                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {this.renderModal()}
            {this.renderFilterModal()}
            {this.renderTaskListDetailModel()}
            <CustomToast icon={<img src={success} />} />
          </Grid>
        </MyProfileHeader>
      </ThemeProvider>
      </>
    );
  }
  renderFilterModal = () => {
    return (
      <Modal open={this.state.isFilterModal} style={{ display: "flex" }}>
        <Box
          component="div"
          style={{
            overflowY:"scroll",
            width: "40vw",
            height: "100%",
            padding: "16px 16px",
            boxShadow:
              "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
            background: "white",
            position: "fixed",
            top: 0,
            right: 0,
            transform: "translateX(0)",
            display:"flex",
            justifyContent:"space-between",
            flexDirection:"column",
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",   
          }}
        >
         <Box> <Box style={{ ...webStyle.modalHeader, marginBottom: "20px" }}>
            <Typography style={webStyle.renameText}>Filter</Typography>
            <Close
              data-test-id="close-filter-modal-id"
              onClick={this.handleFilterModal}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Box>
            <Box style={webStyle.filterSection} onClick={this.handleOpenRoomList} data-test-id="filter_Room_open_id" >
              <Box style={{cursor:"pointer"}}>By Room/Area</Box>
              <Box> {this.state.isRoomListOpen? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</Box> 
            </Box>
              { this.state.isRoomListOpen ? this.renderRoomData(): ""}
            <Box style={webStyle.filterSection} onClick={this.handleOpenTaskList} data-test-id="filter_Task_open_id">
              <Box style={{cursor:"pointer"}}>By Tasklist</Box>
              <Box> {this.state.isTaskListOpen? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</Box> 
            </Box>
            { this.state.isTaskListOpen ? this.renderTaskListData(): ""}
            
            <Box style={webStyle.filterSection} onClick ={this.handleOpenPriorityList} data-test-id="filter_priority_open_id">
              <Box style={{cursor:"pointer"}}>By Priority</Box>
              <Box> {this.state.isPriorityOpen? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</Box> 
            </Box>
            { this.state.isPriorityOpen ? this.renderPriorityListData(): ""}

          </Box>
          </Box>
  
          <Box style={webStyle.filterButton}>
           
            <RenameButton
              onClick={this.ApplyFilterHandle}
              data-test-id="apply-filter-data-id"
              style={{
                padding:"0.8rem 1rem"
              }}
              disabled={
                !(
                  this.state.isTaskListSelectedFilter.length > 0 || 
                  this.state.isPrioritySelected.length > 0 || 
                  this.state.isRoomSelected.length > 0
                )
              }
               >
              Apply
            </RenameButton>
            <Typography
            style={{...webStyle.cancelFilterButton,cursor:"pointer"}}
              onClick={() => this.handleClearAllFilter()}
              data-test-id="clear-all-id"
            >
              Clear All
            </Typography>
          </Box>
        </Box>
      </Modal>
    );
  };
  

  renderRoomData = () =>{
    return(
      <Box>  {this.state.roomList.map((room)=>(
        <Box style={webStyle.roomDataStyle}>
        <Checkbox
          data-test-id="room-change-id"
          checked={this.state.isRoomSelected.includes(room.id)} 
        onChange={() => this.handleChangeRoomSelected(room.id)}
         
         icon={
           <img
             src={unCheckIcon} style={{ width: 16, height: 16 }} />
         }
         checkedIcon={  <img
             src={checkedIcon}
             style={{ width: 16, height: 16 }}
           />}
         style={
           { color: "#64748B" , padding:"9px 9px 9px 0px" }
         }
         />
         <Box><Typography style={webStyle.roomsStatusCircleStyle}></Typography></Box>
         <Box>{room.attributes.room_name}</Box>

        </Box>
      ))}</Box>
    )
  }

  renderTaskListData = () =>{
    return(
      <Box>  {this.state.taskList.map((task)=>(
        <Box style={webStyle.roomDataStyle}>
        <Checkbox
          data-test-id="room-change-id"
          checked={this.state.isTaskListSelectedFilter.includes(task.id)} 
        onChange={() => this.handleChangeTaskListSelected(task.id)}
         
         icon={
           <img
             src={unCheckIcon} style={{ width: 16, height: 16 }} />
         }
         checkedIcon={  <img
             src={checkedIcon}
             style={{ width: 16, height: 16 }}
           />}
         style={
           { color: "#64748B" ,padding:"9px 9px 9px 0px" }
         }
         />
         <Box style={{color:"#0F172A" , fontSize:"14px" , fontWeight:400 , fontFamily:"poppins"}}>{task.attributes.name}</Box>

        </Box>
      ))}</Box>
    )
  }
  
  renderPriorityListData = () =>{
    return(
      <Box>
        
       {this.state.isPriorityList.map((priority)=>(
        <Box style={{display:"flex",justifyContent:"left",alignItems:"center"}}>
           <Checkbox
          data-test-id="room-change-id"
          checked={this.state.isPrioritySelected.includes(priority.id)} 
        onChange={() => this.handleChangePriority(priority.id)}
         
         icon={
           <img
             src={unCheckIcon} style={{ width: 16, height: 16 }} />
         }
         checkedIcon={  <img
             src={checkedIcon}
             style={{ width: 16, height: 16 }}
           />}
         style={
           { color: "#64748B",padding:"9px 9px 9px 0px"  }
         }
         />
          <Box style={{...webStyle.priorityStyleList,backgroundColor:priority.PriorityBackgroundColor}}>
            <Typography
              style={{
                ...webStyle.roomStatusCircleStyle,
                backgroundColor: priority.PriorityColor,
              }}
            ></Typography>
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Poppins",
                color: priority.PriorityColor,
              }}
            >
              {priority.Priority_name}
            </Box>
          </Box>
        </Box>
       ))}
      </Box>
    )
  }
  

  renderStatusData = (status: string, count: number, colorCode: string) => {
    return (
      <Box style={webStyle.outerBoxStatus}>
        <Typography
          style={{
            ...webStyle.statusCircleStyle,
            backgroundColor: colorCode,
          }}
        ></Typography>
        <Box style={{ fontFamily: "poppins" }}>{status}</Box>
        <Box style={webStyle.statusCountStyle}>{count}</Box>
      </Box>
    );
  }
  
    renderCard = (
      id:string,
   roomType: string,
   taskName: string,
   priority: string,
   priorityColor: string,
   checklist: string,
   assignedTo: string,
   dateRange: string,
   priorityBgColor:string,
   roomId:string
   ) => {
     const { priorityDropdownOpen } = this.state;
 
     return (
       <Grid style={webStyle.cardOuterContainer}  >
         <Box style={webStyle.roomStyleMainCard}>
           <Box style={webStyle.roomStyleCard}>
             <Typography style={webStyle.roomStatusCircleStyle}></Typography>
             <Box style={webStyle.roomTypeStyle} onClick={this.handleTaskListDetailCard}>{roomType}</Box>
           </Box>
           <Box>
             <Checkbox
               data-test-id="tasklist-change-id"
               checked={this.state.isProjectTaskList?.includes(id)}
               onChange={() => this.handleChangeProjectTaskListSelect(id, roomId)}
               icon={
                 <img
                   src={unCheckIcon}
                   style={{ width: 16, height: 16 }}
                   alt="unchecked"
                 />
               }
               checkedIcon={
                 <img
                   src={checkedIcon}
                   style={{ width: 16, height: 16 }}
                   alt="checked"
                 />
               }
               style={{ color: "#64748B" }}
             />
           </Box>
         </Box>
         <Box style={webStyle.roomNameStyle}>{taskName}</Box>
         <Box
           style={{
             display: "flex",
             alignItems: "center",
             justifyContent: "space-between",
             marginTop: "10px",
           }}
         >
           <Box
             style={{ ...webStyle.priorityStyle, backgroundColor: priorityBgColor }}
             onClick={() => this.togglePriorityDropdown(id)}
           >
             <Typography
               style={{
                 ...webStyle.roomStatusCircleStyle,
                 backgroundColor: priorityColor,
               }}
             ></Typography>
             <Box
               style={{
                 fontSize: "12px",
                 fontWeight: 400,
                 fontFamily: "Poppins",
                 color: priorityColor,
               }}
             >
               {priority === "medium" ? "Mid" : priority}
             </Box>
             <KeyboardArrowDownIcon
               style={{ fontSize: "12px", color: priorityColor }}
             />
           </Box>
 
           {priorityDropdownOpen[id] && (
             <Box
               style={{
                 position: "absolute",
                 backgroundColor: "white",
                 boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                 zIndex: 1000,
                 padding: "10px",
                 borderRadius: "4px",
                 marginTop:"9rem"
               }}
             >
               {["Low", "Mid", "High"].map((level) => (
                 <Box
                   key={level}
                   style={{
                     padding: "5px 10px",
                     cursor: "pointer",
                     fontFamily: "Poppins",
                   }}
                   data-test-id="priority-change-id"
                   onClick={() => this.handlePriorityChange(id, roomId, level)}
                 >
                   {level}
                 </Box>
               ))}
             </Box>
           )}
 
           <Typography
             style={{
               color: "#237182",
               display: "flex",
               alignItems: "center",
             }}
           >
             <ChecklistIcon style={{ width: "16px", height: "16px" }} />
             <Typography style={{ fontSize: "12px" }}>{checklist}</Typography>
           </Typography>
         </Box>
         <Divider style={{ marginTop: "10px" }} />
         <Box
           style={{
             display: "flex",
             alignItems: "center",
             justifyContent: "space-between",
             marginTop: "10px",
           }}
         >
           <Box style={webStyle.containerDataStyle}>
             <PersonOutlineOutlinedIcon width="16px" height="16px" />{" "}
             <Typography
               style={{
                 fontFamily: "Poppins",
                 fontSize: "12px",
                 fontWeight: 400,
               }}
             >
               {assignedTo}
             </Typography>
           </Box>
           <Box style={webStyle.containerDataStyle}>
             <DateRangeIcon />
             <Box>
               <Typography
                 style={{
                   fontSize: "12px",
                   fontFamily: "Poppins",
                   fontWeight: 400,
                 }}
               >
                 {dateRange}
               </Typography>
             </Box>
           </Box>
         </Box>
       </Grid>
     );
   };

  renderTaskListDetailModel= () =>{
    return (
      <Modal open={this.state.isTaskListDetailOpen} style={{ display: "flex" }}>
        <Box
          component="div"
          style={{
            overflowY:"scroll",
            width: "40vw",
            height: "100%",
            padding: "24px",
            boxShadow:
              "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
            background: "white",
            position: "fixed",
            top: 0,
            right: 0,
            transform: "translateX(0)",
            display:"flex",
            justifyContent:"space-between",
            flexDirection:"column",
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
          }}
        >
         <Box> 
          <Box style={{ ...webStyle.modalHeader, marginBottom: "20px" }}>
            <Typography style={webStyle.renameText}>Tasklist Name</Typography>
            <Close
              data-test-id="close-filter-modal-id"
              onClick={this.handleTaskListDetailCard}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , gap:"32px"}}>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between",width:"45%"}}>
               <Box style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                  <Typography style={webStyle.innerDetail} > <AdjustOutlinedIcon style={webStyle.iconDetailColorStyle} /> <Typography style={webStyle.multipleDetailDataStyle}>Status:</Typography> </Typography>
                  <Typography style={webStyle.innerDetail} ><AssistantPhotoOutlinedIcon style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Priority:</Typography> </Typography>
                  <Typography style={webStyle.innerDetail} ><PeopleOutlinedIcon style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Team:</Typography> </Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
               <Typography
        onClick={this.handleMenuClickDetail}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: this.getStatusBackgroundColorDetail(this.state.selectedStatus),
          padding: "4px 6px 4px 8px",
          borderRadius: "40px",
          color: "white",
        }}
      >
        {this.state.selectedStatus}
        <span style={{ marginLeft: "8px" }}><KeyboardArrowDownIcon/></span>
      </Typography>
      <Menu
        anchorEl={this.state.anchorElStatus}
        open={Boolean(this.state.anchorElStatus)}
        onClose={this.handleClose}
        PaperProps={{
          style: {
            borderRadius: "8px",
          },
        }}
      >
        {this.state.statuses.map((status) => (
          <MenuItem
            key={status}
            onClick={() => this.handleMenuItemClickDetail(status)}
            sx={{
              borderRadius: "8px",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
          >
            <Typography
              style={{
                backgroundColor: this.getStatusBackgroundColorDetail(status),
                padding: "4px 6px 4px 8px",
                borderRadius: "40px",
                color: "white",
              }}
            >
              {status}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      
      <Typography
        onClick={this.handlePriorityClick}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: this.getPriorityBackgroundColor(this.state.selectedPriority),
          padding: "1px 8px",
          borderRadius: "16px",
          color:this.getPriorityColorStatus(this.state.selectedPriority)
        }}
      > <span style={{...webStyle.roomStatusCircleStyle,
        backgroundColor:this.getPriorityColorStatus(this.state.selectedPriority),marginRight:"3px"}}></span>
       <span> {this.state.selectedPriority}</span>
        <span ><KeyboardArrowDownIcon/></span>
      </Typography>
      <Menu
        anchorEl={this.state.anchorElPriority}
        open={Boolean(this.state.anchorElPriority)}
        onClose={this.state.anchorElPriority}
        PaperProps={{
          style: {
            borderRadius: "8px",
            width: "109px",
          },
        }}
        style={{padding:"8px 16px"}}
      >
        {this.state.priorities.map((priority:string) => (
          <MenuItem
            key={priority}
            onClick={() => this.handlePriorityItemClick(priority)}
            sx={{
              padding: "8px 16px",
              borderRadius: "16px",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
          >
         
            <Typography
              style={{
                backgroundColor: this.getPriorityBackgroundColor(priority),
                color:this.getPriorityColorStatus(priority),
                padding: "1px 8px",
                borderRadius: "16px",
                display:"flex",
                alignItems:"center",
                gap:"4px"
              }}
            >
                 <Typography style={{...webStyle.roomStatusCircleStyle,
                backgroundColor:this.getPriorityColorStatus(priority)}}></Typography>
             <Typography> {priority}</Typography>
            </Typography>
          </MenuItem>
        ))}
      </Menu>

         <Typography><GroupAddOutlinedIcon style={{width:"22px",height:"22px",color:"#237182"}}/></Typography>
               </Box>
            </Box>
            <Box style={{display:"flex", alignItems:"center",justifyContent:"space-between" , width:"45%"}}>
            <Box  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                  <Typography style={webStyle.innerDetail} > <DateRangeOutlinedIcon style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Start Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><DateRangeOutlinedIcon style={webStyle.iconDetailColorStyle}/>  <Typography style={webStyle.multipleDetailDataStyle}>Due Date:</Typography></Typography>
                  <Typography style={webStyle.innerDetail} ><LocalOfferOutlinedIcon style={webStyle.iconDetailColorStyle}/> <Typography style={webStyle.multipleDetailDataStyle}>Room/Area:</Typography></Typography>
               </Box>
               <Box  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
               <Typography style={{color:"#237182",fontSize:"16px",fontWeight:600,fontFamily:"Poppins"}}>01/07/24</Typography>
                  <Typography style={{color:"#237182",fontSize:"16px",fontWeight:600,fontFamily:"Poppins"}}> 25/07/24</Typography>
                  <Typography style={{color:"#64748B",fontSize:"12px",fontWeight:600,fontFamily:"Poppins" , display:"flex",alignItems:"center",gap:"4px"}}> <Typography style={webStyle.roomsStatusCircleStyle}></Typography> Room Name test</Typography>
               </Box>
            </Box>
          </Box>     
          </Box>
        </Box>
      </Modal>
    );
  }

  renderModal() {
    return (
      <Modal open={this.state.isAssignMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={{...webStyle.modalHeader, marginBottom: "8px"}}>
            <Typography style={webStyle.renameText}>
            Assign Member
             </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
          Select a user from the list below or search by name or email.
                    </Typography>
       
           <CustomSelect   memberError={this.state.memberError} onSelectionChange={(selectedUsers:any) => this.setState({ selectedUsers })}  membersData={this.state.users}
           ref={(ref) => console.log("Props passed to CustomSelect:", this.state.users)}    selectedOptions={[]} navigation={undefined} id={""}/>
 
           <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={() => this.handleAddMemberModal()}      
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
             onClick={this.handleAssignMember}
              data-test-id="assign-member-data-id"
              disabled={this.state.selectedUsers.length === 0} 
            >
              Assign
            </RenameButton>
          </Box>

        </Box>
      </Modal>
    );
  };

}

const CustomToast = styled(ToastContainer)({
  width: "unset",
  marginTop: "80px",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  fontSize: "16px",
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
  },
});

const webStyle = {
  iconDetailColorStyle:{
    color:"#475569",
    width:"18px",
    height:"18px"
  },
  multipleDetailDataStyle:{
    color:"#475569",
    fontSize:"14px",
    fontWeight:400,
    fontFamily:"Poppins"
  },
  roomDataStyle:{
    display:"flex",
    justifyContent:"start",
    alignItems:"center",
    gap:"8px",
    fontFamily:"poppins",
    fontWeight:600,
    fontSize:'12px',
    color:"#64748B"
  },
  filterSection:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"poppins",
    gap:"10px",
    padding:"6px 8px 6px 0px",
    color:"#0F172A"
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  filterModal: {
    overflowY:"scroll",
    width: "40vw",
    height: "100%",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    position: "fixed",
  top: 0,
  right: 0, 
  transform: "translateX(0)",

  },
  inviteMemberStyle:{
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
    margin: "0.5rem auto",
    paddingLeft:"12px"
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  filterButton: {
    marginTop:"16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom:"2rem"
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  cancelFilterButton: {
    color: "#237182",
    fontWeight:700,
    fontFamily:"poppins",
    fontSize:"18px"

  },
  statusStyle:{
    backgroundColor:"#3B82F6" ,
     fontWeight:400 ,
     fontSize:"12px",
     fontFamily:'Poppins',
     padding:"4px 6px 4px 8px",
     borderRadius:"40px",
     color:"#FFFFFF"

  },
  filterStyle:{
     borderRadius:"8px",
     backgroundColor:"white",
     BorderColor:"#CBD5E1",
     display:"flex",
     alignItems:"center",
     padding:"10px 12px",
     paddingRight:"25px",
     width:"7%"
  },
  containerDataStyle: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "#64748B",
  },
  priorityStyle: {
    borderRadius: "16px",
    width: "69px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "1px 8px",
  },
  priorityStyleList: {
    borderRadius: "16px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "1px 8px",
  },
  roomNameStyle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#334155",
  },
  roomStyleCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  roomStyleMainCard:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  roomStatusCircleStyle: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#60A5FA",
  },
  roomTypeStyle: {
    fontFamily: "Poppins",
    color: "#64748B",
    fontSize: "12px",
    fontWeight: 600,
  },
  cardOuterContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    padding: "12px",
    margin:"1rem 0rem",
    width:"95%"
  },
  mainStatusStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  statusCountStyle: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#237182",
    color: "white",
    textAlign: "center" as "center",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "poppins",
  },

  statusCircleStyle: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#60A5FA",
    marginLeft:"1rem"
  },
  roomsStatusCircleStyle: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#237182",
  },
  outerBoxStatus: {
    marginTop:"2rem",
    backgroundColor: "#FFFFFF",
    width: "95%",
    borderRadius: "8px",
    padding: "6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    gap: "8px",
  },
  gridCont: {
     paddingLeft:"6.5rem"
  },

  headerSection2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "poppins",
    fontSize: "16px",
    marginTop:"1.5rem"
  },
  outerBox: {
    borderRadius: "5px",
    padding: "0px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.625rem 40px 0px 40px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "#F8FAFC",
    borderRadius: "50px",
    padding: "4px",
  },

  PersonalInformationOption: {
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "9px 16px",
    cursor: "pointer",
    fontSize: "16px",
  },

  BusinessInformationOption: {
    padding: "9px 16px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 &&
      ({
        textAlign: "center",
      } as React.CSSProperties)),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px 0px 40px",
    marginTop: "32px",
    backgroundColor: "#f0f5f7",
  },

  sort: {
    marginRight: "3px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  innerDetail:{
    display:"flex",
    alignItems:"center",
    gap:"8px"
  }
  
};

// Customizable Area End
// Customizable Area End
