// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.sectionEndPoint  = "bx_block_kanbanboard/sections"
exports.settingsEndPoint  = "bx_block_kanbanboard/settings"
exports.GetTaskListEndPoint = "bx_block_tasks/task_lists"
exports.validationApiMethodType = "GET"
exports.httpPostMethod="POST"
exports.httpPatchMethod="PATCH"
exports.GetRoomListEndPoint = "bx_block_profile/project_rooms/project_room_list"
exports.GetProjectMembersEndPoint ="bx_block_profile/project_teams_and_members/project_teams_and_members"
exports.PostAddAssignEndPoint = "bx_block_tasks/task_lists/add_assignee"
exports.GetProjectTaskListEndPoint="bx_block_tasks/projects"
exports.validationApiContentType = "application/json";
exports.patchChangeStatusEndPoint = "bx_block_tasks/projects"
exports.patchChangePriorityEndPoint = "bx_block_tasks/projects"

// Customizable Area End
