import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Task, ViewMode } from "gantt-task-react";
import { Alert } from "react-native";
import { NavigationStackProp } from 'react-navigation-stack';
import moment from "moment";
import { TaskType } from "gantt-task-react/dist/types/public-types";

interface APIData {
  attributes: {
      project_start_date: string,
      project_end_date: string,
      task_name: TaskType,
      percentage: number
  },
  id: string,
  type: TaskType
}
// Customizable Area End

export const configJSON = require("./config");
export const ganttData = require("./constants.json");


export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ganttChartView: ViewMode;
  ganttChartTasks: Task[];
  ganttChartTasksMobile: Task[];
  isChecked: boolean;
  ganttChartColumnWidth: number;
  minGrid: string;
  maxGrid: string;
  activeTab:any;
  listView:string;
  events:any;
  initialDate:any;
  monthName:any;
  Quarter1:any;
  Quarter2:any;
  Quarter3:any;
  Quarter4:any;
  cuurentQuarter:any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  name: string;
  progress: number;
  start: Date;
  end: Date;
  // Customizable Area End
}

export default class GanttChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ganttChartView: ViewMode.Day,
      ganttChartTasks: ganttData,
      ganttChartTasksMobile: ganttData,
      isChecked: true,
      ganttChartColumnWidth: 80,
      minGrid: "2023-01-01",
      maxGrid: "2023-03-31",
      activeTab:3,
      listView:"ROOM_VIEW",
      monthName:[{name:"Jan",id:"01"},{name:"Feb",id:"02"},{name:"March",id:"03"},{name:"April",id:"04"},{name:"May",id:"05"},{name:"Jun",id:"06"},{name:"July",id:"07"},{name:"Aug",id:"08"},{name:"Sept",id:"09"},{name:"Oct",id:10},{name:"Nov",id:11},{name:"Dec",id:12}],
      initialDate:new Date(),
      cuurentQuarter:"January",
      Quarter1:[
          {start:"1",name:"Jan"
          },
          {name:"Jan",
            start:"8"
          },
          { start:"15",
            name:"Jan"
          },
          {
            name:"Jan",
            start:"22"
          },
          {
            name:"Feb",
            start:"1"
          },
          {start:"8",
            name:"Feb"
          },
          {
            name:"Feb",start:"15"
          },
          {
            name:"Feb",
            start:"22"
          },
          {
            start:"1",name:"Mar"
          },
          {
            name:"Mar",
            start:"8"
          },
          {
            start:"15",  name:"Mar"          
          },
          {
            name:"Mar",start:"22"
          }
        ],
      Quarter2:[{name:"April",start:"1"},
          {
            name:"April",start:"8"
          },
          {
            name:"April", start:"15"
          },
          { name:"April", start:"22"
          },
          {
            name:"May",
            start:"1"
          },
          {name:"May", start:"8"
          },
          {
            name:"May",start:"15"
          },
          {
            name:"May",
            start:"22"
          },
          {name:"June", start:"1"
          },
          {
            name:"June",
            start:"8"
          },
          {
            name:"June",
            start:"15"
          },
          {
            name:"June",
            start:"22"
          }
        ],
      Quarter3:[
          {
            name:"July",
            start:"1"
          },{
            name:"July",
            start:"8"},
          {name:"July",start:"15"},
          {
            name:"July",
            start:"22"
          },
          {
            name:"Aug",
            start:"1"
          },
          {
            name:"Aug",
            start:"8"
          },
          {
            name:"Aug",
            start:"15"
          },
          {  name:"Aug",  start:"22"},
          { name:"Sept", start:"1"},
          {
            name:"Sept",
            start:"8"
          },
          {name:"Sept",start:"15"},
          {
            name:"Sept",
            start:"22"
          }
        ],
      Quarter4:[
          { name:"Oct",start:"1"},
          { name:"Oct", start:"8"},
          {name:"Oct", start:"15"},
          {
            name:"Oct",
            start:"22"
          },
          {
            name:"Nov",
            start:"1"
          },
          {name:"Nov", start:"8"},
          {name:"Nov", start:"15"},
          {name:"Nov", start:"22"},
          {
            name:"Dec",
            start:"1"
          },
          {name:"Dec", start:"8"},
          {name:"Dec", start:"15"
          },
          {
            name:"Dec",
            start:"22"
          }    
      ],
      events:[
        {
          id: 0,
          completed_task:"8",
          title: "Meeting with John",
          completed_percent:"80%",
          start: new Date(2024, 11, 31, 9, 0), 
          end: new Date(2025, 0,2, 10, 0), 
          room: "Room A",
          total_task:"10",
          taskList:[
            {
              task:"Complete the ganttChart",
              start_date:new Date(2024, 11, 31, 9, 0),
              end_date:new Date(2025, 0,2, 10, 0),
              Completed:"60%"
            },
            {
              start_date:new Date(2024, 11, 31, 9, 0),
              task:"Completed the ganttChart22",
              end_date:new Date(2025, 0,2, 10, 0),
              Completed:"90%"
            },
            {
              task:"Complete the ganttChart33",
              Completed:"10%",
              start_date:new Date(2024, 11, 31, 9, 0),
              end_date:new Date(2025, 0,2, 10, 0)
            }
          ]
        },
        {
          id: 1,
          title: "Team Sync",
          start: new Date(2024, 11, 29, 10, 30), 
          room: "Room B",
          completed_percent:"60%",
          end: new Date(2024, 11, 29, 11, 30), 
          completed_task:"6",
          total_task:"10",
          taskList:[
            {
              end_date:new Date(2025, 0,2, 10, 0),
              task:"Complete the GanttChart",
              start_date:new Date(2024, 11, 31, 9, 0),
              Completed:"60%"
            } 
          ]
        },
        {
          id: 2,
          title: "Lunch Break",
          start: new Date(2024, 9, 27, 12, 0), 
          room: "Room C",
          completed_percent:"40%",
          total_task:"10",
          completed_task:"4",
          taskList:[
            {
              task:"Complete the ganttChart",
              start_date:new Date(2024, 11, 31, 9, 0),
              end_date:new Date(2025, 0,2, 10, 0),
              Completed:"60%"
            } 
          ],
          end: new Date(2025, 3, 27, 13, 0) 
        },
        {
          id: 3,
          end: new Date(2024, 11, 27, 13, 0),
          room: "Room D",
          total_task:"10",
          completed_task:"9",
          title: "Interview Time",
          start: new Date(2024, 11, 27, 12, 0), 
          taskList:[
            {
              task:"Complete tasks",
              start_date:new Date(2024, 11, 31, 9, 0),
              end_date:new Date(2025, 0,2, 10, 0),
              Completed:"10%"
            } 
          ],
          completed_percent:"90%"
        },
        {
          id: 4,
          title: "Meeting with John",
          room: "Room E",
          completed_percent:"80%",
          start: new Date(2024, 11, 31, 9, 0), 
          end: new Date(2025, 0,2, 10, 0), 
          total_task:"10",
          completed_task:"8",
          taskList:[
            {
              task:"Complete the ganttChart33",
              end_date:new Date(2025, 0,2, 10, 0),
              Completed:"10%",
              start_date:new Date(2024, 11, 31, 9, 0),
            },
            {
              start_date:new Date(2024, 11, 31, 9, 0),
              end_date:new Date(2025, 0,2, 10, 0),
              Completed:"60%",
              task:"Complete the ganttChart",
            },
            {
              end_date:new Date(2025, 0,2, 10, 0),
              task:"Completed the ganttChart22",
              Completed:"90%",
              start_date:new Date(2024, 11, 31, 9, 0)
            }
          ]
        }
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.getWaterFallData();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id == getName(MessageEnum.RestAPIResponceMessage)) {     
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    
      if (apiRequestCallId != null) {
        this.setState({ganttChartTasks: this.responseFormatter(responseJson?.data, 'web')})
        this.setState({ganttChartTasksMobile: this.responseFormatter(responseJson?.data, 'mob')})
      }
    }
    // Customizable Area End
  }
  
  responseFormatter = (data: [], platform: string) => {
    let formatedData = [];
    if (data.length > 0) {
      formatedData = data?.map((item: APIData) => ({
          start: new Date(item?.attributes?.project_start_date),
          end: new Date(item?.attributes?.project_end_date),
          name: item?.attributes?.task_name,
          id: item?.id,
          progress: platform === "web" ? item?.attributes?.percentage : item?.attributes?.percentage / 100,
          type: item?.type,
          project: "ProjectSample"
      }))
      const startDates = formatedData.map(response => response.start);
      const endDates = formatedData.map(response => response.end);
      const dates = [...startDates, ...endDates];
  
      // Convert date strings to moment objects
      const momentDates = dates.map(dateString => moment(dateString));
  
      // Find the earliest and latest dates
      const startDate = moment.min(momentDates).subtract(3, 'days');
      const endDate = moment.max(momentDates).add(5, 'days');
  
      this.setState({minGrid: startDate.format('YYYY-MM-DD')});
      this.setState({maxGrid: endDate.format('YYYY-MM-DD')});
    } else {
      formatedData = ganttData;
    }
    
    return formatedData;
  }

  getWaterFallData(): boolean {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGanttChartWaterfallData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  
  onGanttChartPress = (text: SS) => {
      let message = `${moment(text?.start).format("YYYY-MM-DD")} - ${moment(text.end).format("YYYY-MM-DD")}\nProgress: ${text.progress * 100}%`;
    Alert.alert(text.name, message);
  }

  navigateToDetails = {
    onPress: () => {this.props.navigation.navigate("GanttChartDetails")}
  }

  handleChange = (event:any,newValue:any) => {
    this.setState({
      activeTab:newValue
    });
  };

  handleChangeSelect = (event:any) => {
    this.setState({
      listView:event.target.value
    });
  };

  handleNextWeek =()=>{
    if(this.state.activeTab === 0){
      const NextWeek = moment(this.state.initialDate).add(1,'w').toDate()
      this.setState({
        initialDate:NextWeek
      })
    }
    if (this.state.activeTab === 1){
      const NextWeek = moment(this.state.initialDate).add(1,'months').toDate()
      this.setState({
        initialDate:NextWeek
      })
    }
    if (this.state.activeTab === 2){
      if(this.state.initialDate.getMonth() <  10){
        const NextWeek = moment(this.state.initialDate).add(3,'months').toDate()
        this.setState({
          initialDate:NextWeek
        })
      }
    }
    if(this.state.activeTab === 3){
      const YearCalendar = moment(this.state.initialDate).add(1,'years').toDate()
      this.setState({
        initialDate:YearCalendar
      })
    }
  }

  handlePreviousWeek = () => {
    if(this.state.activeTab === 0){
      const previousWeek = moment(this.state.initialDate).subtract(1,'w').toDate()
    this.setState({
      initialDate:previousWeek
    })
    }
    if(this.state.activeTab === 1){
      const previousWeek = moment(this.state.initialDate).subtract(1,'months').toDate()
      this.setState({
        initialDate:previousWeek
      })
    }
    if (this.state.activeTab === 2){
      if(this.state.initialDate.getMonth() <  10){
        const NextWeek = moment(this.state.initialDate).subtract(3,'months').toDate()
        this.setState({
          initialDate:NextWeek
        })
      }
    }
    if(this.state.activeTab === 3){
      const YearCalendar = moment(this.state.initialDate).subtract(1,'years').toDate()
      this.setState({
        initialDate:YearCalendar
      })
    }
   
  };

  handleQuarterWeeks =()=>{
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const monthStarting = months[this.state.initialDate.getMonth()]

    return monthStarting
  }


 
  // Customizable Area End
}
