// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require('../assets/threedots.png');
export const folder = require('../assets/folder.png');
export const file = require('../assets/file.png');
export const backButton = require('../assets/Backbtn.png');
export const folderThreeDot = require('../assets/folderThreeDots.png');
export const uploadSvg = require('../assets/upload.svg');
export const noDocsSvg = require('../assets/noDocs.svg');
export const uploadDate = require('../assets/uploadDate.svg');
export const unchecked = require('../assets/unchecked.svg');
export const checked = require('../assets/checked.svg');
export const searchSvg = require('../assets/searchSvg.svg');
export const profileImg = require('../assets/photo.png');
export const crossSvg = require('../assets/cross.svg');
// Customizable Area End
