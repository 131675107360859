import moment from "moment";
import React, { Component } from "react";
import { Calendar as BigCalendar, EventProps, momentLocalizer } from "react-big-calendar";  
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Style.css";


const localizer = momentLocalizer(moment)

interface CalendarState {
  events: any[];
  title: string;
  start: string | Date;
  end: string | Date;
  desc: string;
  openSlot: boolean;
  openEvent: boolean;
  clickedEvent: any;
}
interface CalendarProps {
  activeTabs: any;
  eventValues:any;
  nextWeek:any;
  dateValue:any;
  prevWeek:any;
}

 
class Calendar extends Component<{activeTabs:any,eventValues:any,nextWeek:any,dateValue:any;prevWeek:any}, CalendarState,CalendarProps> {
  constructor(props: CalendarProps) {
    super(props);
  }

  getDaysInMonth(year:any, month:any) {
    return new Date(year, month + 1, 0).getDate();
  }

  
  RenderCustomToolbar=({ dateValue,activeTabs }:{dateValue: any,activeTabs:any })=>{
    const currentWeek = moment(dateValue).week(); 
    const currentMonth = moment(dateValue).format('MMMM');
    const currentYear = moment(dateValue).year();
    const day = dateValue.getDate(); 
    const month = dateValue.getMonth() + 1; 
    const NextWeek = moment(dateValue).add(1,'w').toDate()
    const day2 = NextWeek.getDate();
    const month2 = NextWeek.getMonth()+1; 
    const year2 = NextWeek.getFullYear()

    const days = this.getDaysInMonth(currentYear,dateValue.getMonth())
 
  return (
    <div className='rbc-toolbar'>
      { activeTabs === 0 &&
        <div className="rbc-btn-group">
        <div>
          <span onClick={()=>this.props.prevWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}>{"<"} </span>
          <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"20px"}}>Week {" "}{" "}{currentWeek}</span> 
          <span onClick={()=>this.props.nextWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}>{">"} </span>
        </div>
        <div>
          <span style={{color:"#64748B",fontWeight:400,fontFamily:"Poppins"}}>Period :</span>
          <span style={{color:"#64748B",fontWeight:400,fontFamily:"Poppins"}}> {day}/{month}/{currentYear}{" "}{" "}{" "} -</span>
          <span style={{color:"#64748B",fontWeight:400,fontFamily:"Poppins"}}>{" "}{day2}/{month2}/{year2}</span>
        </div>  
      </div>
      }
       { activeTabs === 1 &&
        <div className="rbc-btn-group">
        <div>
          <span onClick={()=>this.props.prevWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}>{"<"} </span>
          <span style={{fontWeight:600,fontSize:"20px",fontFamily:"Poppins",color:"#325962"}}>{currentMonth} {" "}{" "}{" "}{currentYear}</span> 
          <span onClick={()=>this.props.nextWeek()} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}>{">"} </span>
        </div>
        <div>
          <span style={{color:"#64748B",fontWeight:400,fontFamily:"Poppins"}}>Period :</span>
          <span style={{color:"#64748B",fontWeight:400,fontFamily:"Poppins"}}> 1/{month}/{currentYear}{" "}{" "}{" "} -</span>
          <span style={{color:"#64748B",fontWeight:400,fontFamily:"Poppins"}}>{days}/{month}/{currentYear}</span>
        </div>  
      </div>
      }
    </div>
  );
}



renderEvent (eventValues: any) {
  return (
    <div  style={{ backgroundColor:'white',border:"1px solid black",padding:"10px",display:"flex",flexDirection:"column",gap:"10px"}}>
       <span style={{color:"black",fontSize:"14px",fontWeight:600}}>{eventValues?.title}</span>
        <span style={{ fontSize: '12px', color: '#334155',fontWeight:400,fontFamily:"Poppins"}}>
          {`${eventValues.event.start.getDate()}/${eventValues.event.start.getMonth()+1}/${eventValues.event.start.getFullYear()} - ${eventValues.event.end.getDate()}/${eventValues.event.end.getMonth()+1}/${eventValues.event.end.getFullYear()} `}
        </span>  
    </div>
  );
}


 
 render() {
  const {activeTabs,eventValues,dateValue} = this.props;

    return (
      <div id="Calendar">
        {
          activeTabs === 0 &&
          <BigCalendar
          localizer={localizer} 
          views={["week"]}
          timeslots={4}
          defaultView="week"
          events={eventValues}
          defaultDate={dateValue}
          allDayAccessor="allDay" 
          date={dateValue}
          components={{
            toolbar: (props: any) => this.RenderCustomToolbar({ ...props, dateValue,activeTabs}),  
            event: (event:any)=>this.renderEvent(event),  
          }}
        />
        }
          {
          activeTabs === 1 &&
          <BigCalendar
          localizer={localizer} 
          events={eventValues}
          views={["month"]}
          defaultView="month"
          toolbar={true}
          timeslots={1}
          defaultDate={new Date()}
          components={{
            toolbar: (props: any) => this.RenderCustomToolbar({ ...props, dateValue,activeTabs }), 
            event: (event:any)=>this.renderEvent(event),  
          }}
        />
        }
      </div>
    );
  }
}

export default Calendar;
