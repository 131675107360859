import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Client as ConversationsClient, } from "@twilio/conversations";
import { getStorageData } from "framework/src/Utilities";
import React from "react";



// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    conversation_id:string;
    channal_id:any
    reRenderCom:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    statusString: string;
    status: string;
    conversationsClient: ConversationsClient | null;
    conversationList: any[]; 
    allChatMessageList:any[],
    messageList:any[],
    hasPrevPage: boolean;
    twillioMessageObject: any;
    sendMessage:string;
    selectedFile:any;
    selectedFiles:any[];
    reMoveMemberModalOpen:boolean;
    messageSid:string;
    unreadMessageCount:number;
    username:string;






    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ChatConversationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createTwilioTokenApiCallId:string = "";
  dummygetMembersListCallId: string = "";
    createChatRoomApiCallId: string = "";
    conversationsClient: any;
    conversationClientNew: any;
    conversationChannelNew: any;
    maxMessageCount = 100;
    chatEndRef: any;
    fileInputRef: any;







    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            statusString: "",
            status: "",
            conversationsClient: null,
            conversationList: [],
            allChatMessageList:[],
            messageList:[],
            twillioMessageObject: {},
            hasPrevPage: false,
            sendMessage:"",
            selectedFile:null,
            selectedFiles:[],
            reMoveMemberModalOpen:false,
            messageSid:"",
            unreadMessageCount:0,
            username:""







            // Customizable Area End
        };
        this.fileInputRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getTwilioTokenApi();
        this.getChatMessages();
        const firstName = await getStorageData("username");
                  const lastName = await getStorageData("lastname");
                  this.setState({username: `${firstName} ${lastName}`})

        // Customizable Area End
    }
    async componentDidUpdate(prevProps: Props,prevState: { allChatMessageList: string | any[]; }) {

       if (prevState.allChatMessageList.length !== this.state.allChatMessageList.length) {
        this.scrollToBottom();
    }
     
    if (prevProps.conversation_id !== this.props.conversation_id) {

      this.getTwilioTokenApi();

      await this.getChatMessages(); 
  }
    }
    scrollToBottom = () => {
      if (this.chatEndRef.current) {
          this.chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };
    async componentWillUnmount() {
      this.conversationClientNew?.removeAllListeners();
      this.conversationClientNew = null;
      this.conversationChannelNew = null;
    }
    async receive(from: string, message: Message) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorResponse) {
        this.parseApiCatchErrorResponse(errorResponse);
      }
      if (responseJson?.errors) {
        this.parseApiErrorResponse(responseJson);
      }
      if (this.createTwilioTokenApiCallId === apiRequestCallId) {
        this.initChatNew(responseJson.token)

       }
       if (this.dummygetMembersListCallId === apiRequestCallId) {
        if(!responseJson.errors) {
          console.log("11111111==== member list====>", responseJson);
          
        }
    }
    
  }
    handleInputChange = (event: { target: { value: any; }; }) => {
      this.setState({ sendMessage: event.target.value });
    };
   
      
     
      getTwilioTokenApi = async () => {
        const token = await getStorageData("token")
        const header = {
          "Content-Type": "application/json",
          token,
        };
      
        const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createTwilioTokenApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
         `bx_block_chat/chat_channels/twilio_token?chat_channel_id=${this.props.channal_id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      initChatNew =(chatToken: string) => {
        const conversationSid = this.props.conversation_id;     
        try {
          this.conversationClientNew =  new ConversationsClient(chatToken);
          this.conversationClientNew.on('stateChanged', async (state: any) => {
            if (state === 'initialized') {

              try {
                  const subscribedConversations =  await this.conversationClientNew.getConversationBySid(conversationSid);
                   subscribedConversations.join();

                if (subscribedConversations.sid === conversationSid) {
                  this.conversationChannelNew = subscribedConversations;
                  this.getChatMessages()
                }
                 
                 this.conversationChannelNew.on('messageAdded', (message:any) => {
                  const formattedMessage = this.formatMessage(message);
                  this.setState((prevState) => ({
                      allChatMessageList: [...prevState.allChatMessageList, formattedMessage],
                  }));
              });
               // Update unread count
               this.updateUnreadCount();
              } catch (error) {
                console.log("+++++++++++++++console->initChat->err", error);
              }
            }
          });
        } catch (err: any) {
          
        }
      
      }
      updateUnreadCount = async () => {
        if (this.conversationChannelNew) {
            const unreadCount = await this.conversationChannelNew.getUnreadMessagesCount();
            this.setState({ unreadMessageCount: unreadCount });            
        }
    }

      parseSender = (author: string): string => {
        const senderObject = JSON.parse(author);
        return senderObject.name;
        };
      
         formatMessage = (message: { author: string; body: any; dateCreated: any; sid: string; media?: any[] }) => {
          console.log("++++++ message", message);
          
          
            const senderName = this.parseSender(message.author);
            return {
                body: message.body,
                sender: senderName,
                timestamp: message.dateCreated,
                sid: message.sid,
            };
        };
      
          getChatMessages = async () => {
            if (this.conversationChannelNew) {
                try {
      
                await this.conversationChannelNew.setAllMessagesRead();
      
                const messagesResponse = await this.conversationChannelNew.getMessages(this.maxMessageCount) || [];
      
                const allChatMessageList = await messagesResponse.items ; 
      
                const formattedMessages = await allChatMessageList.map(this.formatMessage);
      
                this.setState({
                    allChatMessageList: formattedMessages,
                });
      
            } catch (error) {
                console.log("Error fetching messages:", error);
            }
        } 
        }
     
  
      handleIconClick = () => {
          this.fileInputRef.current?.click();
      };
  
      handleFileChange = (event: { target: { files: any; }; }) => {
          const files = event.target.files;
  
          if (files && files.length > 0) {
              this.setState({ selectedFiles: Array.from(files) }); 
          }
      };
      sendMessage = async () => {
        const { sendMessage, selectedFiles } = this.state;
        
        
        if (this.conversationChannelNew) {
            try {
                const messagesToSend = [];
    
                if (sendMessage.trim() !== "") {
                    messagesToSend.push({
                        type: 'text',
                        content: sendMessage.trim()
                    });
                }
    
                if (selectedFiles && selectedFiles.length > 0) {

                    const mediaPromises = selectedFiles.map(async (file) => {

                        const fileBlob = await file.arrayBuffer(); 
                        
                        const mediaFormData = new FormData();

                        mediaFormData.set(file.name, new Blob([fileBlob]), file.name);
    
                        const mediaMessage = await this.conversationChannelNew.prepareMessage()
                            .setBody(file.name) 
                            .addMedia(mediaFormData) 
                            .build()
                            .send();
    
                        return mediaMessage; 
                    });
    
                    const mediaMessages = await Promise.all(mediaPromises);
    
                    mediaMessages.forEach(media => messagesToSend.push({
                      
                        type: 'media',
                        content: media 
                    }));
                }
    
                // Send messages and update state
            for (const message of messagesToSend) {
              
              if (message.type === 'text') {
                  const textMessage = await this.conversationChannelNew.sendMessage(message.content);
                 console.log("+++++++++++++ textMessage", textMessage); // Log the entire message object

                  const firstName = await getStorageData("username");
                  const lastName = await getStorageData("lastname");
                  this.addMessageToState({
                      body: message.content, // Access the body property
                      sender: `${firstName} ${lastName}`,
                      timestamp: new Date().toISOString(),
                  });

              } 

              else if (message.type === 'media') {
                  this.addMessageToState({
                      body: message.content, // Access the body property of the media message
                      sender: "Your Sender Name", // Replace with actual sender name
                      timestamp: new Date().toISOString(),
                  });
              }

          }

          // Clear input fields
          this.setState({ selectedFiles: [], sendMessage: '' });
    
            } catch (error) {
                console.log("Error sending message:", error);
            }
        }
    };
    addMessageToState = (message:any) => {
      this.setState(prevState => ({
          allChatMessageList: [...prevState.allChatMessageList, message],
      }));
  };
    
   

handleDeleteMessage = (messageSid: string) => {
  this.setState({reMoveMemberModalOpen:true,messageSid:messageSid})
}
deleteMessage = async (messageSid: string) => {
    
    if (this.conversationChannelNew) {
        try {
            const messagesResponse = await this.conversationChannelNew.getMessages();

            const messageToDelete = messagesResponse.items.find((msg: { sid: string; }) => msg.sid === messageSid);

            if (messageToDelete) {

                await messageToDelete.remove();
                
                this.getChatMessages();
            } 
        } catch (error) {
            console.log("++++++++++++++ Error deleting message:", error);
        }
    } 
}
reMoveMemberModelclose=()=>{
   this.setState({reMoveMemberModalOpen:false})
}
deleteMessageConfirm(){
  this.deleteMessage(this.state.messageSid);
  this.reMoveMemberModelclose()
}


getMembersListApi = async () => {

  const token = await getStorageData("token")
  const header = {
    "Content-Type": "application/json",
    token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.dummygetMembersListCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_chat/chat_memberships?chat_channel_id=${2049}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
    // Customizable Area End
}
