// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TeamBuilder";
exports.labelBodyText = "TeamBuilder Body";
exports.getServicesApi = "account_block/show_business_category"

exports.getAllMember = "account_block/team_members"
exports.delete = "DELETE"
exports.put = "PUT"
exports.phoneRegExp = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/;
exports.passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
exports.emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
exports.addNewMember = "bx_block_teambuilder/team_members"
exports.getAllTeamMembersEndPoint = "account_block/teams/list_all_members";
exports.searchMembersEndPoint = "account_block/teams/search?query="

exports.btnExampleTitle = "CLICK ME";

exports.multipartType = "multipart/form-data"
exports.labelEmailText = "Email";
exports.labelPasswordText = "Password";
exports.labelConfirmPasswordText = "Confirm Password"
exports.btnLoginTitle = "Login";
exports.placeholderEmailText = "Please enter email";
exports.placeholderPasswordText = "Please enter password"
exports.placeholderConfirmPassword = "Please enter confirm password"
exports.newUserText = "Don't have an account?"
exports.signUpText = " Sign Up"
exports.labelNameText = "Name"
exports.placeholderNameText = "Enter your name"
exports.registerTitle = "Setup Your Business Profile"
exports.businessNameText = "Business Name"
exports.placeholderBusinessName = "Please enter business name"
exports.registerText = "Register"
exports.btnAddTeam = "Add Member"
exports.btnEdit = "Edit Member"
exports.httpPutMethod = "PUT"
exports.signUpEndPoint = "account/accounts"
exports.signInEndPoint = "login/login"
exports.getAllTeamMemberEndPoint = "account_block/teams/22/list_members"
exports.getTeamMemberProfleEndPoint = "bx_block_teambuilder/team_members/"
exports.httpGetMethod = "GET";
exports.addTeamMemberEndPoint = "bx_block_teambuilder/team_members"
exports.ContentType = "application/json"
exports.httpPostMethod = "POST";
exports.teamEndPoint = "account_block/teams";
exports.paymentPlansEndPoint = "bx_block_custom_user_subs/plans";
exports.emailValidText = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
exports.subscriptionPaymentEndPoint = "bx_block_custom_user_subs/user_subscriptions"
exports.CreateTeamEndPoint = "account_block/teams"
exports.GetMembersListEndPoint="account_block/teams"
exports.postCreateMemberEndPoint="account_block/teams"
exports.getMembersListSelectEndPoint = "account_block/teams"
exports.removeMemberEndPoint = "account_block/teams"
exports.editMemberDetailsEndPoint = "account_block/teams"
exports.GetTeamListEndPoint= "account_block/teams"
exports.postMoveMemberEndPoint ="account_block/teams"
exports.editMemberDetailEndPoint = "account_block/teams"
exports.invitedMemberEndPoint = "account_block/teams"
exports.deleteInvitedMember= "account_block/teams"
exports.AddInvitedMemberEndPoint = "account_block/teams"
exports.DeactivateEndPoint = "account_block/teams"
exports.new="active"
// Customizable Area End
