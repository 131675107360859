// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import * as React from "react";


const { PDFDocumentProxy } = require("pdfjs-dist");
const pdfjsLib = require("pdfjs-dist");
if (!pdfjsLib.GlobalWorkerOptions.workerSrc) {
  const pdfjsVersion = require('pdfjs-dist/package.json').version;
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  uploadedFile: any;
  uploadFileUrl: string;
  fileName: string;
  numPages: number;
  pageNumber: number;
  scale: number;
  zoomLevel: number;
  tool: "line" | "rectangle" | "arrow" | "hand" | null;
  lines: {
    id: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
    length: number;
  }[];
  rectangles: {
    id: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
    area: number;
    width: number;
    height: number;
  }[];
  dragging: boolean;
  startPoint: { x: number; y: number } | null;
  pixels: number;
  realWorldDistance: number;
  lineWidth: number;
  lineColor: string;
  rectangleBorderColor: string;
  rectangleBackgroundColor: string;
  isMounted:boolean;

    unit: string;
  endpoint1: { x: number; y: number };
  endpoint2: { x: number; y: number };
  length: number;
  isDrawing: boolean;
  shapes: any[],
  currentShape: any,
}

interface SS {
  id: any;
}

export default class ScalingController extends BlockComponent<Props, S, SS> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  canvasContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      token: "",
      uploadedFile: "",
      uploadFileUrl: "",
      fileName: "",
      numPages: 0,
      pageNumber: 1,
      scale: 1,
      zoomLevel: 100,
      tool: null,
      lines: [],
      rectangles: [],
      dragging: false,
      startPoint: null,
      pixels: 10,
      realWorldDistance: 100,
      lineWidth: 4,
      lineColor: "#34D399",
      rectangleBorderColor: "#34D399",
      rectangleBackgroundColor: "#34D3991F",
      isMounted:false,
      isDrawing: false,

      unit: 'Meters(m)',
            endpoint1: { x: 700, y: 600 },
            endpoint2: { x: 0, y: 0 },
            length: 100,
            shapes: [],
            currentShape: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.canvasRef = React.createRef();
    this.canvasContainerRef = React.createRef();
  }
  async componentDidMount() {
    const token = await getStorageData("authToken");
    this.setState({ token });
    await this.getFileFromStorage();
    this.setState({ isMounted: true });
  
    const canvas = this.canvasRef.current;
    
    if (canvas) {
      canvas.addEventListener("mousemove", this.handleMouseMove);
      canvas.addEventListener("mousedown", this.handleMouseDown);
      canvas.addEventListener("mouseup", this.handleMouseUp);
    }
  }
  
  async componentWillUnmount(): Promise<void> {
    if (this.state.uploadedFile) {
      URL.revokeObjectURL(this.state.uploadedFile);
    }
    this.setState({ isMounted: false });
  
    const canvas = this.canvasRef.current;
    if (canvas) {
      canvas.removeEventListener("mousemove", this.handleMouseMove);
      canvas.removeEventListener("mousedown", this.handleMouseDown);
      canvas.removeEventListener("mouseup", this.handleMouseUp);
    }
  }


  async getFileFromStorage() {
    try {
      const fileObjString = await getStorageData("fileObj");
      if (!fileObjString) return;

      const fileObj = JSON.parse(fileObjString);
      this.setState(
        {
          uploadFileUrl: fileObj.fileUrl,
          fileName: fileObj.fileMetaData.name,
        },
        () => this.loadPDF(this.state.uploadFileUrl)
        
      );
    } catch (error) {
      console.error("Error retrieving or parsing data from localStorage:", error);
    }
  }


  convertBase64ToBinary(dataURL: string): Uint8Array {
    try {
      if (!dataURL || !dataURL.includes(",")) {
        console.warn("Invalid Base64 data URL format");
        return new Uint8Array();
      }
  
      const base64String = dataURL.split(",")[1] || "";
      const binaryString = Buffer.from(base64String, "base64").toString("binary");
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
  
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      return bytes;
    } catch (error) {
      console.error("Error converting Base64 to binary:", error);
      return new Uint8Array();
    }
  }
  

  loadPDF(pdfData: string) {
    pdfjsLib
      .getDocument({ data: this.convertBase64ToBinary(pdfData), disableFontFace: false }).promise
      .then((pdf: typeof PDFDocumentProxy) => {
        this.setState({ numPages: pdf.numPages }, () => {
          this.renderPage(pdf, this.state.pageNumber);
        });
      })
      .catch((error: any) => {
        console.error("Error loading PDF:", error.message);
        alert("Failed to load PDF. Please check the file data.");
      });
  }


renderingInProgress = false;

renderPage = (pdf:any, pageNumber:any) => {
  
    if (this.renderingInProgress) {
        console.warn("Rendering already in progress.");
        return;
    }

    this.renderingInProgress = true;
    const { scale } = this.state;
    const canvas = this.canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      

        pdf.getPage(pageNumber).then((page: { getViewport: (arg0: { scale: number; }) => any; render: (arg0: { canvasContext: CanvasRenderingContext2D; viewport: any; }) => any; }) => {
            const viewport = page.getViewport({ scale });
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderTask = page.render({
                canvasContext: context,
                viewport,
            });

            renderTask.promise.then(() => {
                context.setTransform(1, 0, 0, 1, 0, 0);
                this.renderShapes(context);
                this.renderingInProgress = false;
            }).catch((error: any) => {
                console.error("Rendering error:", error);
                this.renderingInProgress = false;
            });
        });
    }
};

handleMouseMove = (e: any) => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    const rect = canvas.getBoundingClientRect();

    const x = (e.clientX - rect.left) / this.state.scale;
    const y = (e.clientY - rect.top) / this.state.scale;

    const { isDrawing, startPoint, tool } = this.state;

    if (!isDrawing) return;

    context.clearRect(0, 0, canvas.width, canvas.height);

    this.renderPageContent();

    this.renderShapes(context);

    if (tool === 'line' && startPoint) {
        this.setState({
            currentShape: {
                type: 'line',
                startX: startPoint.x,
                startY: startPoint.y,
                endX: x,
                endY: y
            }
        });

        context.beginPath();
        context.moveTo(startPoint.x, startPoint.y);
        context.lineTo(x, y);
        context.strokeStyle = this.state.lineColor;
        context.lineWidth = this.state.lineWidth;
        context.stroke();
    } else if (tool === 'rectangle' && startPoint) {
        const width = x - startPoint.x;
        const height = y - startPoint.y;

        this.setState({
            currentShape: {
                type: 'rectangle',
                startX: startPoint.x,
                startY: startPoint.y,
                width: width,
                height: height
            }
        });

        context.strokeStyle = this.state.rectangleBorderColor;
        context.fillStyle = '#34D3991F';
        context.lineWidth = this.state.lineWidth;
        context.strokeRect(startPoint.x, startPoint.y, width, height);
    }
};

handleMouseDown = (e: any) => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
  
    const x = (e.clientX - rect.left) / this.state.scale;
    const y = (e.clientY - rect.top) / this.state.scale;

    this.setState({
        isDrawing: true,
        startPoint: { x, y },
    });
};

handleMouseUp = () => {
    const { currentShape, shapes } = this.state;

    if (currentShape) {
        this.setState({
            shapes: [...shapes, currentShape],
            currentShape: null
        });
    }

    this.setState({ isDrawing: false });
};

  renderShapes = (context: CanvasRenderingContext2D) => {
    const { shapes, currentShape, lineColor, rectangleBorderColor, lineWidth } = this.state;

    shapes.forEach(shape => {
        if (shape.type === 'line') {
            context.beginPath();
            context.moveTo(shape.startX, shape.startY);
            context.lineTo(shape.endX, shape.endY);
            context.strokeStyle = lineColor;
            context.lineWidth = lineWidth;
            context.stroke();
        } else if (shape.type === 'rectangle') {
            context.strokeStyle = rectangleBorderColor;
            context.lineWidth = lineWidth;
            context.strokeRect(shape.startX, shape.startY, shape.width, shape.height);
        }
    });

    if (currentShape) {
        if (currentShape.type === 'line') {
            context.beginPath();
            context.moveTo(currentShape.startX, currentShape.startY);
            context.lineTo(currentShape.endX, currentShape.endY);
            context.strokeStyle = lineColor;
            context.lineWidth = lineWidth;
            context.stroke();
        } else if (currentShape.type === 'rectangle') {
            context.strokeStyle = rectangleBorderColor;
            context.lineWidth = lineWidth;
            context.strokeRect(currentShape.startX, currentShape.startY, currentShape.width, currentShape.height);
        }
    }
};

  addLine(start: { x: number; y: number }, end: { x: number; y: number }) {
    const length = Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
    );
    const newLine = { id: `${Date.now()}`, start, end, length };
    this.setState((prevState) => ({ lines: [...prevState.lines, newLine] }));
  }
  
  addRectangle(start: { x: number; y: number }, end: { x: number; y: number }) {
    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);
    const area = width * height;
    const newRectangle = { id: `${Date.now()}`, start, end, width, height, area };
    this.setState((prevState) => ({
      rectangles: [...prevState.rectangles, newRectangle],
    }));
  }

  updateScale = () => {
    const { pixels, realWorldDistance } = this.state;
    if (pixels > 0 && realWorldDistance > 0) {
      const newScale = realWorldDistance / pixels;
      this.setState({ scale: newScale }, () => {
        this.renderPageContent();
      });
    } else {
      alert("Both Pixels and Real World Distance must be greater than zero.");
    }
  };

  deleteShape = (id: string, type: "line" | "rectangle") => {
    if (type === "line") {
      this.setState((prevState) => ({
        lines: prevState.lines.filter((line) => line.id !== id),
      }));
    } else if (type === "rectangle") {
      this.setState((prevState) => ({
        rectangles: prevState.rectangles.filter((rect) => rect.id !== id),
      }));
    }
  };

  componentDidUpdate(prevProps: Props, prevState: S) {
    const canvas = this.canvasRef.current;
    const context = canvas?.getContext("2d");
  
    if (
      prevState.pageNumber === this.state.pageNumber &&
      prevState.scale === this.state.scale &&
      prevState.lines === this.state.lines &&
      prevState.rectangles === this.state.rectangles
    ) {
      return;
    }
  
    if (canvas && context) {
      console.warn("Updating Canvas...");
      context.clearRect(0, 0, canvas.width, canvas.height);
  
      this.renderPageContent();
  
      this.renderShapes(context);
    }
  }
  
  renderPageContent() {
    const { uploadFileUrl } = this.state;
    if (uploadFileUrl) {
      this.loadPDF(uploadFileUrl);
    }
  }
    handelClose = ()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DrawingDashboard"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    
  }
     selectTool = (tool: 'line' | 'rectangle' | 'arrow' | 'hand') => {
    this.setState({ tool });
  };
    handleGoBack=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DrawingDashboard"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleInputChange=(fieldName:string,value:number)=>{
    console.warn(fieldName)
  }
  handleEndpointChange=(fieldName:string,axis:string,value:number)=>{
    console.warn(fieldName)
  }
  
}
// Customizable Area End