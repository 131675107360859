// Customizable Area Start

import React, { CSSProperties } from "react";

import {
  Box,
  Input,
  Button,
  Typography,
  IconButton,
  Grid,
  Modal,
  styled,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from "@mui/material";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {uploadSvg,noDocsSvg,uploadDate,checked,unchecked,searchSvg,profileImg,crossSvg} from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone, {DropzoneState} from 'react-dropzone';
import MessageBox from "../../../components/src/messageBox";
const options = ['All','Client', 'Company Owner', 'Employee'];
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const SortInput = styled(Select)({
  color: "#237182",
  fontWeight: 600,
  fontFamily: "Poppins",
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "2px",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#F0F5F7",
  },
  "& .MuiSelect-icon": {
    color: "#237182",
    right:"unset"
  },
});
const menuStyles = {
  "& .MuiPaper-root": {
    borderRadius: "8px",
    padding: "8px 10px",
  },
};
import DocumentationController, {
  configJSON,
  FileData,
  Props,
} from "./DocumentationController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { Close, ExpandMore, MoreVert } from "@material-ui/icons";
const PdfIcon=({color}:{color:string})=>(
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.03304 13.2958C4.03304 12.9637 3.80257 12.7656 3.39592 12.7656C3.22987 12.7656 3.11741 12.782 3.05859 12.7977V13.8634C3.12824 13.879 3.21386 13.8843 3.33188 13.8843C3.76532 13.8843 4.03304 13.6653 4.03304 13.2958ZM6.55037 12.7768C6.36826 12.7768 6.25058 12.7925 6.18097 12.8088V15.169C6.25062 15.1853 6.36308 15.1853 6.46475 15.1853C7.2035 15.1905 7.68531 14.7839 7.68531 13.9222C7.69054 13.1729 7.25149 12.7768 6.55037 12.7768Z" fill={color}/>
    <path d="M11.2271 0.429688H3.19431C2.02079 0.429688 1.06567 1.38547 1.06567 2.55833V10.4297H0.857761C0.384122 10.4297 0 10.8134 0 11.2875V16.4895C0 16.9635 0.38408 17.3472 0.857761 17.3472H1.06567V18.301C1.06567 19.4752 2.02079 20.4297 3.19431 20.4297H14.1548C15.3275 20.4297 16.2828 19.4751 16.2828 18.301V5.46761L11.2271 0.429688ZM2.25026 12.2262C2.50164 12.1838 2.85499 12.1517 3.35281 12.1517C3.8559 12.1517 4.21447 12.2478 4.4554 12.4407C4.68554 12.6224 4.84085 12.9225 4.84085 13.2756C4.84085 13.6286 4.72316 13.9287 4.50903 14.132C4.23052 14.3942 3.81865 14.5118 3.33684 14.5118C3.22961 14.5118 3.13349 14.5065 3.05833 14.4961V15.786H2.25026V12.2262ZM14.1548 19.1261H3.19431C2.73992 19.1261 2.3699 18.7561 2.3699 18.301V17.3472H12.5873C13.061 17.3472 13.4451 16.9635 13.4451 16.4895V11.2875C13.4451 10.8134 13.061 10.4297 12.5873 10.4297H2.3699V2.55833C2.3699 2.1046 2.73996 1.73458 3.19431 1.73458L10.7394 1.7267L10.7394 4.87414C10.7394 5.71813 10.9191 5.98525 11.4746 5.98525L14.9477 5.98402L14.9785 18.301C14.9785 18.7561 14.6091 19.1261 14.1548 19.1261ZM5.3619 15.7697V12.2262C5.6616 12.1785 6.05224 12.1517 6.46449 12.1517C7.1496 12.1517 7.59387 12.2747 7.94198 12.5368C8.31661 12.8153 8.55193 13.2592 8.55193 13.8966C8.55193 14.587 8.30055 15.0636 7.9524 15.3577C7.57255 15.6735 6.99433 15.8233 6.28794 15.8233C5.86491 15.8233 5.5652 15.7965 5.3619 15.7697ZM11.2037 13.6718V14.3353H9.90818V15.786H9.08933V12.1785H11.2945V12.8473H9.90818V13.6718H11.2037Z" fill={color}/>
    </svg>
  );

export default class Documentation extends DocumentationController {
  constructor(props: Props) {
    super(props);
  }

  deleteModal = () => {
    return (
      <Modal
        open={!!this.state.deleteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={webStyle.renameModal as CSSProperties}>
          <label style={{ padding: "20px", display: "block", fontWeight: 700 }}>
            Do you want to delete this{this.state.fileMenu?.file === "folder" ? " folder ?" : " file ?"}
          </label>
          <Box style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
            <Button
              data-test-id="close-delete-modal-button"
              onClick={() => this.handleDeleteModal(null)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                color: 'rgb(66, 65, 65)',
                border: '2px solid #dd9865',
                backgroundColor: "white"
              }}
              variant="contained" component="span">
              No
            </Button>
            <Button
              data-test-id="delete-button"
              onClick={() => this.deleteFolderAndFiles(this.state.fileMenu)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                border: '2px solid #dd9865',
                backgroundColor: "#dd9865",
                color: "white",
              }}
              variant="contained" component="span">
              Yes
            </Button>
          </Box>
        </div>
      </Modal>
    )
  }
  getProgressWidth = (file: FileData) => {
    if (file.error) {
      return '100%';
    }
    return `${file.progress ?? 100}%`;
  };
  
  getBorderTopStyle = (file: FileData) => {
    if (file.error) {
      return '4px solid #DC2626';
    } else if (file.progress !== undefined && file.progress < 100) {
      return '4px solid #237182';
    } else {
      return '4px solid #67D49A'; 
    }
  };
  renderProgressBar = (file:FileData) => {
    const progressWidth = this.getProgressWidth(file);
    const borderTopStyle = this.getBorderTopStyle(file); 
  
    return (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '4px',
            width: progressWidth,
            borderTop: borderTopStyle,
          }}
        />
    );
  };
  addFolderModal = () => {
    return (
      <Modal
        open={this.state.addDocsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box style={webStyle.uploadModal}>
          <Box display={"flex"}>
         <Typography style={webStyle.fontStyles("24px",700,"#0F172A")}>{configJSON.uploadFiles}</Typography>
         <img src={crossSvg} style={webStyle.uploadCrossIcon} data-test-id="cross-btn" onClick={()=>this.handleAddDocModal(false)} />
          </Box>
          <Dropzone
          onDrop={(acceptedFiles) => {
            this.updatePdfFile(acceptedFiles);
          }}
          data-test-id="dropzone"
            >
              {({ getRootProps, getInputProps }: DropzoneState) => (
          <Paper
            elevation={0}
            sx={webStyle.dropzonePaper}
            {...getRootProps()}
          >
                <input {...getInputProps()} />
              <>
                <label htmlFor="contained-button-file" style={{ ...webStyle.label, color: '#334155' }}>
                  <Typography variant="body1" style={webStyle.fontStyles("14px",600,"#292524")}>{configJSON.dragDrop}</Typography>
                </label>
                <Typography variant="body1">
                  <Typography style={webStyle.labelText}>
                  {configJSON.formats}
                  </Typography>
                  <Typography style={webStyle.labelText}>({configJSON.maxSize})</Typography>
                </Typography>
              </>
          </Paper>
               )}
          </Dropzone>
          {this.state.uploadedFiles.length>0&&
          <Box style={webStyle.addFilesContainer}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
             <Typography style={webStyle.fontStyles("16px",600,"#334155")}>{configJSON.attachedFiles}</Typography>
             <Typography style={webStyle.fontStyles("12px",400,"#334155")}>Files:<span style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins",color:"#237182"}}></span>{this.state.uploadedFiles.length}</Typography>
            </Box>
            <Box style={webStyle.filesContainer}>
            {this.state.uploadedFiles.map((file,index)=>{
              return (
                <Box
                 style={webStyle.fileCard}
                 key={file.file_name} 
              >
                  {this.renderProgressBar(file)}
                  <Box style={webStyle.iconBackground(file.error?"#FEE2E2":"#E0EDF0")}>
                    <PdfIcon color={file.error?"#DC2626":"#237182"}/>
                    </Box>
                  <Box marginRight={"auto"} textAlign={"start"}>
                      <Typography style={webStyle.fontStyles("14px",400,"#0F172A")}>{file.file_name}</Typography>
                      <Typography style={webStyle.fontStyles("12px",400,file.error?"#DC2626":"#64748B")} data-test-id={`file-size-text${index}`}>
                      {file.uploadStatus?`${file.progress}%`:this.fileSizeText(file)}</Typography>
                  </Box>
                  <Close
                    style={{ cursor: 'pointer',marginRight:"3px" }}
                    data-test-id={`removeFile${index}`}
                    onClick={()=>this.handleRemoveFile(index)}
                  />
              </Box>
              )
            })}
              </Box>
          </Box>
          }
         <Box display={"flex"} gap={"16px"}>
         <Button variant="contained" data-test-id="cancelUpload" style={webStyle.cancelBtn} onClick={()=>this.handleAddDocModal(false)}>
         Cancel
         </Button>
         <Button variant="contained" data-test-id="submit-upload" style={this.validateUpload()?webStyle.uploadModalBtn:webStyle.activeUploadBtn} disabled={this.validateUpload()} onClick={()=>this.handleUploadApi()}>
         Upload
         </Button>
         </Box>
        </Box>
      </Modal>
    )
  }

  renderLoaderWeb() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <CircularProgress style={{color: "#0000FF"}}/>
      </div>
    );
  }
  formatDate=(date:string)=>{
    const options:any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-GB", options);
    return formattedDate;
  }
  renderDocumentsData=()=>{
    if(this.state.enableField){
     return(
    <Box>
          <TableContainer style={webStyle.tableContainer}>
    <Table>
      <TableHead>
        <TableRow sx={{
            "& > th": {
              padding: "8px 24px",
              fontSize: "14px",
              fontWeight: 600,
              fontFamily: "Poppins",
              color:"#1E293B"
            },
          }}>
          <TableCell>File name</TableCell>
          <TableCell>Upload Date</TableCell>
          <TableCell>Uploaded by</TableCell>
          <TableCell align="center"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.documentsData.map((row) => (
          <TableRow key={row.id} sx={{
            "& > td": {
              padding: "5px 24px",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color:"#1E293B"
            },
          }}>
            <TableCell sx={webStyle.tableText} title={row.filename}>{row.filename}</TableCell>
            <TableCell>{this.formatDate(row.upload_date)}</TableCell>
            <TableCell sx={webStyle.tableText} title={row.user_name}>{row.user_name}</TableCell>
            <TableCell align="center">
              <IconButton>
                <MoreVert />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
        </Box>
     )
    }else{
      return(
      <Grid container spacing={"24px"}>
          {this.state.documentsData.map((item) => (
           <Grid item key={item.id} xs={12} md={4} lg={3}>
             <Box style={webStyle.cardContainer}>
               <Box style={{ display: "flex", justifyContent: "space-between" }}>
                 <Typography style={webStyle.cardName} title={item.filename}>{item.filename}</Typography>
                 <MoreVert />
               </Box>
               <Box
                 style={webStyle.cardFooter}
               >
                 <img
                   src={item.profile_pic?item.profile_pic:profileImg}
                   style={webStyle.uploadedUser}
                 />
                 <Typography
                   style={webStyle.cardFooterUserText}
                 >
                   {item.user_name}
                 </Typography>
                 <img
                   src={uploadDate}
                   style={webStyle.uploadDate}
                 />
                 <Typography
                   style={webStyle.cardFooterText}
                 >
                   {item.upload_date.split("-").reverse().join("/")}
                 </Typography>
               </Box>
             </Box>
           </Grid>
       ))}</Grid>
      )
    }
  }

  render() {
    return (
    <ThemeProvider theme={theme}>
      <MyProfileHeader navigation={this.props.navigation} showSidebar={true}>
       <>
    {this.state.toast.messageToggle && <MessageBox message={this.state.toast.toastMessage} status={this.state.toast.status}/>}
      {this.state.loader ? this.renderLoaderWeb()
      :<Box style={webStyle.mainContainer}>
      <Box style={{display:"flex",alignItems:"center",gap:"24px",marginBottom:"24px",flexWrap:"wrap"}}>
        <Box style={{display:"flex",alignItems:"center",padding:"4px",borderRadius:"50px",backgroundColor:"#F8FAFC"}}>
          <Box  style={this.state.enableField?webStyle.listView:webStyle.cardView} data-test-id="cardView" onClick={()=>this.handleToggleTabs(false)}>Card View</Box>
          <Box  style={this.state.enableField?webStyle.cardView:webStyle.listView} data-test-id="listView" onClick={()=>this.handleToggleTabs(true)}>List View</Box>
        </Box>
        <Box style={webStyle.topBox}>
          <Typography style={webStyle.uploadedBy}>{configJSON.uploadedBy}<span style={webStyle.selectFont}>{options[this.state.selectedValue]}</span></Typography>
          <SortInput
            id="uploadBy"
            name="uploadBy"
            disableUnderline
            IconComponent={ExpandMore}
            value={options[this.state.selectedValue]}
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-active .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          }}
          MenuProps={{
            sx: menuStyles,
          }}
          data-test-id="uploadedBy"
            onChange={(event)=>this.handleSelectUser(Number(event.target.value))}
          >{
            options.slice(1,4).map((option,index)=>
            <MenuItem value={index+1}><Box style={webStyle.menuItem}><img src={this.state.selectedValue===index+1?checked:unchecked} width="18px" height="18px"/><Typography>{option}</Typography></Box></MenuItem>
            )
          }
            <MenuItem value={0} style={webStyle.clearAll}>Clear All</MenuItem>
          </SortInput>
        </Box>
        <Box style={webStyle.topBox}>
          <Typography style={webStyle.uploadedBy}>{configJSON.sortBy}<span style={webStyle.selectFont}>Date</span></Typography>
          <SortInput
            disableUnderline
            IconComponent={ExpandMore}
            value=""
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-active .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          }}
          data-test-id="sortBy"
          onChange={(event)=>this.handleSelectUser(Number(event.target.value))}
          >
          </SortInput>
        </Box>
        <Input
          startAdornment={<img src={searchSvg} style={webStyle.searchIcon} />}
          style={webStyle.searchInput}
          placeholder="Search here..."
          type="text"
          onChange={(event) => this.handleSearchInput(event)}
          value={this.state.searchInputValue}
          disableUnderline
          data-test-id="search-input"
        />
        <Button variant="contained" style={webStyle.uploadBtn} startIcon={<img src={uploadSvg} width={"24px"} height={"24px"}/>} data-test-id="uploadBtn" onClick={()=>this.handleAddDocModal(true)}>
          Upload
        </Button>
      </Box>
      {this.state.documentsData.length===0?
      <Box style={webStyle.noDataBox}>
        <img src={noDocsSvg} width="50%" height="50%"/>
        <Typography style={webStyle.noDataText}>{configJSON.noFiles}</Typography>
        </Box>
        :
        this.renderDocumentsData()
        }
    </Box>
    }
      {this.deleteModal()}
      {this.addFolderModal()}
       </> 
      </MyProfileHeader>
    </ThemeProvider>
    );
  }
}

const webStyle = {
  fontStyles:(size:string,weight:number,color:string="black")=>{
    return {
     fontFamily:"Poppins",
     fontWeight:weight,
     fontSize:size,
     color:color
    }
  },
  iconBackground:(bgColor:string)=>{
   return{
    width:"38px",
    height:"38px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    backgroundColor:bgColor,
    borderRadius:"4px"
   }
  },
  mainContainer:{
    boxSizing:"border-box",
    padding: "24px 48px 24px 48px",
    minHeight:"100vh",
    backgroundColor:"#f0f5f7",
    marginLeft:"110px"
  }as const,
  toggleBox:{

  },
  noDataBox:{
    display:"flex",
    flexDirection:"column",
    gap:"16px",
    alignItems:"center",
    justifyContent:"center",
    height:"80%"
  }as const,
  noDataText:{
    fontSize:"24px",
    fontWeight:700,
    fontFamily:"Poppins"
  },
  cardContainer:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    height: "120px",
    padding: "16px",
    borderRadius: "8px",
    backgroundColor:"#FFFFFF",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    boxSizing:"border-box",
  }as const,
 cardName:{
  width: "80%",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 600,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textOverflow: "ellipsis",
  whiteSpace: "normal", 
}as const,
cardFooter:{
  display: "flex",
  gap: "6px",
  alignItems: "center",
  borderTop: "1px solid rgba(226, 232, 240, 1)",
  paddingTop: "8px",
  paddingBottom: "8px",
  marginBottom:"-7px"
},
uploadedUser:{
  width: "16px",
  height: "16px",
  borderRadius: "50%",
},
uploadDate:{
  marginLeft: "auto",
  width: "16px",
  height: "16px",
},
cardFooterText:{ 
  fontSize: "12px", 
  fontFamily: "Poppins", 
  fontWeight: 400,
  color:"rgba(100, 116, 139, 1)"
 },
 cardFooterUserText:{
  fontSize: "12px", 
  fontFamily: "Poppins", 
  fontWeight: 400,
  color:"rgba(100, 116, 139, 1)",
  maxWidth:"100px",
  overflow:"hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
 },
 cardView:{
  backgroundColor:"#E0EDF0",
  color:"#325962",
  fontSize:"16px",
  fontFamily:"Poppins",
  fontWeight:"600",
  padding:"10px 12px",
  borderRadius:"50px",
  cursor:"pointer"
 },
 listView:{
  backgroundColor:"#F8FAFC",
  color:"#64748B",
  fontSize:"16px",
  fontFamily:"Poppins",
  fontWeight:"600",
  padding:"10px 12px",
  borderRadius:"50px",
  cursor:"pointer"
 },
 topBox: {
  display: "flex",
  alignItems: "center",
},
uploadedBy: {
  color: "#334155",
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 400,
  fontFamily: 'Poppins',
},
searchInput: {
  marginLeft:"auto",
  border: "1px solid #CBD5E1",
  background: "#FCFDFE",
  borderRadius: "8px",
  width: '323px',
  height: '44px',
  padding: '10px 16px 10px 16px',
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: 'Poppins',
  color: '#94A3B8'
},
searchIcon: {
  width: "20px",
  height: "20px",
  marginRight:"8px",
  marginLeft:"-4px"
},
uploadBtn:{
  backgroundColor:"#237182",
  width: '123px',
  height: '44px',
  padding: '10px 16px 10px 16px',
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#FFFFFF',
  borderRadius:"8px",
  textTransform:"capitalize",
  boxShadow:"none"
}as const,
selectFont:{
  color: "#237182",
  fontWeight: 600,
  fontFamily: "Poppins",
  marginLeft:"6px"
},
menuItem:{
  display:"flex",
  gap:"15px",
  alignItems:"center"
},
clearAll:{
  display:"flex",
  justifyContent:"center",
  fontSize:"16px",
  fontFamily:"Poppins",
  fontWeight:600,
  color:"#237182"
},
tableText:{
  maxWidth:"120px",
  overflow:"hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
 },
 tableContainer:{
  border:"1px solid #E2E8F0",
  backgroundColor:"#FFFFFF",
  borderRadius:"8px"
 },
  addFolder: {
    background: '#fff',
    maxWidth: '400px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    position: 'absolute',
    borderRadius: '15px',
    textAlign: "center",
    paddingBottom: "40px",
    paddingTop: "20px"
  },
  renameModal: {
    background: '#fff',
    maxWidth: '400px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    position: 'absolute',
    borderRadius: '15px',
    textAlign: "center",
    paddingBottom: "40px",
    paddingTop: "20px"
  },
  uploadModal:{
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    textAlign: "center",
    width:"552px",
    minHeight:"370px",
    padding:"24px",
    borderRadius:"12px",
    backgroundColor:"#FFFFFF",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    boxSizing:"border-box"
  }as const,
  cancelBtn:{
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Poppins",
    color:"#325962",
    backgroundColor:"#E0EDF0",
    borderRadius:"8px",
    padding:"16px",
    marginLeft:"auto",
    textTransform:"capitalize",
    boxShadow:"none"
  }as const,
  uploadModalBtn:{
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Poppins",
    color:"#64748B",
    backgroundColor:"#F1F5F9",
    borderRadius:"8px",
    padding:"16px",
    textTransform:"capitalize",
    boxShadow:"none"
  }as const,
  activeUploadBtn:{
    backgroundColor:"#237182",
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Poppins",
    color:"#ffffff",
    borderRadius:"8px",
    padding:"16px",
    textTransform:"capitalize",
    boxShadow:"none"
  }as const,
  dropzonePaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '4px 8px',
    flexDirection: 'column' ,
    backgroundColor:  '#F8FAFC',
    border: '2px dashed rgba(203, 213, 225, 1)',
    margin: '0 auto',
    marginTop: '10px',
    height: "140px",
    borderRadius: "2px",
    boxSizing: 'border-box',
  },
  labelText: {
    fontFamily: "Poppins",
    lineHeight: '22px',
    color: '#334155',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',}as const,
  label: {
      verticalAlign: "middle",
      color: "#237182",
      cursor: 'pointer',
      gap: "5px",
      display: "inline-block",
      fontFamily: "Poppins"
    },
    uploadCrossIcon:{
      marginLeft:"auto",
      width:"20px",
      height:"20px",
      cursor:"pointer"
    },
    addFilesContainer:{
      display:"flex",
      flexDirection:"column",
      marginTop:"1rem",
      gap:"8px",
      marginBottom:"2rem"
    }as const,
    fileCard:{
      display:"flex",
      gap:"12px",
      position:"relative",
      alignItems:"center",
      minHeight:"50px",
      maxHeight:"50px",
      padding:"6px",
      borderRadius:"8px",
      backgroundColor: "#fff",
      overflow:"hidden",
      boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.06), 0 4px 8px 0 rgba(0, 0, 0, 0.03)",
  }as const,
  filesContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"8px",
    maxHeight:"200px",
    overflowY:"auto",
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }as const
};

// Customizable Area End
