import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { Country, State } from "country-state-city";

interface ProjectDetails{
  projectName:string;
  clientFirstName:string;
  clientLastName:string;
  clinetEmail : string;
  clientStreetName:string;
  clientCityName: string;
  clientStateName: string | undefined;
  clientCountryName: string | undefined;
  clientPostCode : string;
  totalCost:string;
  adjustedCost:string;
}
interface TaskAttributes {
  id: number;
  account_id: number | null;
  name: string;
  created_at: string;
  updated_at: string;
  tasks: Array<{
    id: number;
    title: string;
    description: string;
    status: string;
    priority: string | null;
    created_at: string;
    updated_at: string;
    task_list_id: number;
    assigned_to: string | null;
  }>;
}

interface TaskListDataItem {
  data: Array<{
    id: number;
    type: string;
    attributes: TaskAttributes;
  }>;
}
interface DropdownState {
  selectedRooms: string[];
  tempSelectedRooms: string[];
  searchText: string;
  isDropdownOpen: boolean;
}

interface Invoice {
  id: string;
  invoiceName: string;
  dueDate: string;
  invoicePrice: string;
  retainedAmount: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string;
  projectId:string;
  planId:string;
  ProjectDetails :ProjectDetails;
  selectedTaskId:string | null;
  roomsAndTaskListsCard: TaskListDataItem
  viewAllTasks:boolean;
  openDialog:boolean;
  taskId:string;
  openSelectPaymentDialog:boolean;
  selectPlanValue:string;
  openMilestonesPaymentPlanDialog:boolean;
  openValuationsPaymentPlanDialog:boolean;
  PaymentOptionChangeValue:string;
  dropdownKeys: string[];
  dropdowns: Record<string, DropdownState>;
  invoices: Invoice[];
  Adjusted_Openmodel:boolean;
  Edit_AdjustmentOpenmodel:boolean;
  PaymentPlanDialog:string;
  EditAjustPaymentPlanDialog:string;
  EditAjustPaymentPlanModeDialog:string;
  AdjustAmout:string;
  RemoveAdjustmentOpenmodel:boolean;




  // Customizable Area End

}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProjectByIdCallId:string = "";
  apiGetTaskListCallId:string = "";
  apiGetTaskListByIdCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

     
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token:'',
      projectId:'',
      planId:'',
      ProjectDetails : {} as ProjectDetails,
      selectedTaskId:null,
      roomsAndTaskListsCard: { data: [] },
      viewAllTasks:false,
      openDialog:false,
      taskId:'',
      openSelectPaymentDialog:false,
      selectPlanValue:'',
      openMilestonesPaymentPlanDialog:false,
      openValuationsPaymentPlanDialog:false,
      PaymentOptionChangeValue:'',
      dropdownKeys: [],
      dropdowns: {},
      invoices: [],
      Adjusted_Openmodel:false,
      Edit_AdjustmentOpenmodel:false,
      PaymentPlanDialog:"",
      EditAjustPaymentPlanDialog:"",
      EditAjustPaymentPlanModeDialog:"",
      AdjustAmout:"",
      RemoveAdjustmentOpenmodel:false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetProjectByIdCallId) {
        this.getProjectByIdResponse(responseJson);
       
      }
    }

   
  }
  this.receive1(message);
  this.receive2(message);
   // Customizable Area End
    }
  // Customizable Area Start
 
  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTaskListCallId) {
          this.getTaskListResponse(responseJson);
         
        }
      }
  
     
    }

  }
  receive2=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetTaskListByIdCallId) {
          this.getTaskListByIdResponse(responseJson);
         
        }
      }
  
     
    }

  }
  async componentDidMount() {
    let token = await getStorageData("token");
    this.setState({ token: token });
    this.getIds();
    this.getProjectDetailsById();
    this.getTaskList();
    document.addEventListener('mousedown', this.handleClickOutside);

    const apiResponse: Invoice[] = [
      { id: '1', invoiceName: 'Kitchen', dueDate: '15/07/24', invoicePrice: '35,000', retainedAmount: 'Auto-calculate' },
      { id: '2', invoiceName: 'Bathroom', dueDate: '15/07/24', invoicePrice: '35,000', retainedAmount: 'Auto-calculate' },
      { id: '3', invoiceName: 'Bedroom', dueDate: '15/07/24', invoicePrice: '35,000', retainedAmount: 'Auto-calculate' },
      { id: '4', invoiceName: 'Bedroom 2', dueDate: '15/07/24', invoicePrice: '35,000', retainedAmount: 'Auto-calculate' },
      { id: '5', invoiceName: 'Entrance Hall', dueDate: '15/07/24', invoicePrice: '35,000', retainedAmount: 'Auto-calculate' },
    ];

    
    const dropdownKeys = apiResponse.map((invoice) => invoice.id);
    const dropdowns = dropdownKeys.reduce((acc: Record<string, DropdownState>, key) => {
      acc[key] = {
        selectedRooms: [],
        tempSelectedRooms: [],
        searchText: '',
        isDropdownOpen: false,
      };
      return acc;
    }, {});

    this.setState({ dropdownKeys, dropdowns, invoices: apiResponse });
  }
  getIds() {
    let url = window.location.pathname;
    let parts = url.split("/");
  
    if (parts.length >= 3) {
      const planId = parts[2];
  
      if (parts.length >= 5 && parts[3] === "project_id") {
        const projectId = parts[4];
        this.setState({ planId: planId, projectId: projectId });
        return { planId, projectId };
      }
     
      this.setState({ planId: planId, projectId: '' });
      return { planId, projectId: null };
    }
    return { planId: null, projectId: null };
  }

async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (event: MouseEvent) => {
  if (
      this.state.selectedTaskId &&
      !(event.target as HTMLElement).closest('.menu') &&
      !(event.target as HTMLElement).closest('.rename-input')
  ) {
      this.setState({ selectedTaskId: null });
  }
};
handleOpenActiaonTab = (id: string) => {
    this.setState(prevState => ({
        selectedTaskId: prevState.selectedTaskId === id ? null : id
    }));
}
handleDeleteTaskList=(task_id:any)=>{
    this.setState({openDialog:true,taskId:task_id});
}
handleEditTaskList=(task_id:any)=>{
    console.warn("Edit task_id : ",task_id);

}
getProjectDetailsById=()=>{
  const header = {
  "Content-Type": configJSON.getProjectDetailsContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetProjectByIdCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTaskList=()=>{
  const header = {
  "Content-Type": configJSON.getContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetTaskListCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getTaskListAPIEndPoint}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getTaskById=(taskId:string)=>{
  const header = {
  "Content-Type": configJSON.getProjectDetailsContentType,
  token: this.state.token,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.apiGetTaskListByIdCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `${configJSON.getTaskByIdAPIEndPoint}/${taskId}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getAPiEndMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProjectByIdResponse=(responseJson:any)=>{
  const country = Country.getCountryByCode(responseJson.data.attributes.addressable.country);
  const countryName = country?.name
  const state = State.getStateByCodeAndCountry(responseJson.data.attributes.addressable.state, responseJson.data.attributes.addressable.country);
  const stateName = state?.name

 this.setState({
ProjectDetails:{
  projectName: responseJson.data.attributes.project_name,
  clientFirstName: responseJson.data.attributes.addressable.name,
  clientLastName: responseJson.data.attributes.addressable.last_name,
  clinetEmail: responseJson.data.attributes.addressable.email,
  clientStreetName: responseJson.data.attributes.addressable.address,
  clientCityName: responseJson.data.attributes.addressable.city,
  clientStateName: stateName,
  clientCountryName: countryName,
  clientPostCode: responseJson.data.attributes.addressable.post_code,
  totalCost: "",
  adjustedCost: ""
}
 })
}

getTaskListResponse = (responseJson: any) => {
  if (responseJson.errors?.[0]?.token) {
    alert("Token is expired, Please login !!!");
    this.props.navigation.navigate('EmailAccountLoginBlock');
  } else {
    console.warn(responseJson);
    this.setState({
      roomsAndTaskListsCard: responseJson,
    });
  }
};
getTaskListByIdResponse = (responseJson:any)=>{
  console.warn(responseJson);
}
handelViewAll = ()=>{
  this.setState((prevState) => ({ viewAllTasks: !prevState.viewAllTasks }));
}
handleCloseDialog=()=>{
  this.setState({openDialog:false})
}
handleConfirmDelete=()=>{
  this.setState({openDialog:false,taskId:''});
}
handleOpenSelectPaymentDialog=()=>{
  this.setState({openSelectPaymentDialog:true});
}
handleCloseSelectPaymentPlanDialog=()=>{
  this.setState({openSelectPaymentDialog:false});
}
handleRadioChange = (value:any) => {
  this.setState({selectPlanValue:value});
 
};
handleCloseMilestonesPaymentPlanDialog=()=>{
  this.setState({openMilestonesPaymentPlanDialog:false,PaymentOptionChangeValue:'',selectPlanValue:''});
}
handleCloseValuationsPaymentPlanDialog=()=>{
  this.setState({openValuationsPaymentPlanDialog:false,PaymentOptionChangeValue:'',selectPlanValue:''});

}
handleSubmitSelectPaymentPlan = (event:any) =>{
  event.preventDefault();
  if (this.state.selectPlanValue === 'Milestones') {
    this.setState({openSelectPaymentDialog:false,openMilestonesPaymentPlanDialog:true})
  } else if (this.state.selectPlanValue === 'Valuations') {
    console.warn(this.state.selectPlanValue);
    this.setState({openSelectPaymentDialog:false,openValuationsPaymentPlanDialog:true})
  }
}
handleSubmit = (values: any) =>{
console.warn(values);
}
handlePaymentOptionChange=(value:string)=>{
  if(value==='payAfter'){
    this.setState({PaymentOptionChangeValue:'payAfter'});
  }else if(value==='payBefore'){
    this.setState({PaymentOptionChangeValue:'payBefore'});

  }


}
 
  handleDropdownOpen = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          isDropdownOpen: true,
          tempSelectedRooms: [...prevState.dropdowns[key].selectedRooms],
        },
      },
    }));
  };

  handleDropdownClose = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          isDropdownOpen: false,
          searchText: '',
        },
      },
    }));
  };

  handleCheckboxToggle = (key: string, roomId: string) => {
    this.setState((prevState) => {
      const { tempSelectedRooms } = prevState.dropdowns[key];
      const updatedTempSelectedRooms = tempSelectedRooms.includes(roomId)
        ? tempSelectedRooms.filter((id) => id !== roomId)
        : [...tempSelectedRooms, roomId];

      return {
        dropdowns: {
          ...prevState.dropdowns,
          [key]: {
            ...prevState.dropdowns[key],
            tempSelectedRooms: updatedTempSelectedRooms,
          },
        },
      };
    });
  };

  handleSearchChange = (key: string, searchText: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          searchText,
        },
      },
    }));
  };

  handleAdd = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          selectedRooms: [...prevState.dropdowns[key].tempSelectedRooms],
          isDropdownOpen: false,
          searchText: '',
        },
      },
    }));
  };

  handleCancel = (key: string) => {
    this.setState((prevState) => ({
      dropdowns: {
        ...prevState.dropdowns,
        [key]: {
          ...prevState.dropdowns[key],
          tempSelectedRooms: [],
          isDropdownOpen: false,
          searchText: '',
          selectedRooms:[]
        },
      },
    }));
  };

  handleSwitchPaymentPlan = (value:string) =>{
    if (value === 'Milestones') {
      this.setState({openValuationsPaymentPlanDialog:false,openMilestonesPaymentPlanDialog:true})
    } else if (value === 'Valuations') {
      this.setState({openMilestonesPaymentPlanDialog:false,openValuationsPaymentPlanDialog:true})
    } 
  }
  editPlan=()=>{
    this.setState({openValuationsPaymentPlanDialog:true})

  }

  backNavigation = ()=>{
    const pathname = window.location.pathname
    this.backBtnQuoteOverviewClicked()
    const { planID , projectID }= this.extractIdsFromUrl(pathname)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolioShells"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${planID}/project_id/${projectID}`);
    this.send(message);
  }


  extractIdsFromUrl = (url:string)=>{
    const paths = url.split("/")
    const planID = paths[2]
    const projectID = paths[4]
    return {planID,projectID}
  }

  backBtnQuoteOverviewClicked = async ()=>{
    await setStorageData("backBtnQuoteOverviewClick",JSON.stringify(true))
  }
  AdjustpaymentOpenmodel(){
    this.setState({Adjusted_Openmodel:true})
  
  }
  Adjusted_paymentModelclose=()=>{
    this.setState({Adjusted_Openmodel:false})
    this.RemoveAdjustOpenmodel()
    
  }
  AdjustpaymentConfirm(){
    this.setState({Adjusted_Openmodel:false})
    this.AdjustpaymenEdittOpenmodel()
  
  }
  AdjustpaymenEdittOpenmodel(){
    this.setState({Edit_AdjustmentOpenmodel:true})
  
  }
  Adjusted_paymentEditModelclose=()=>{
    this.setState({Edit_AdjustmentOpenmodel:false})
  }
  AdjustpaymentEditConfirm(){
  
  }
  EdithandlePaymentOptionChange=(value:string)=>{
    if(value==='AddDiscount'){
      this.setState({EditAjustPaymentPlanDialog:'AddDiscount'});
    }else if(value==='AddAdditionalCost'){
      this.setState({EditAjustPaymentPlanDialog:'AddAdditionalCost'});
    }
  }
SelectPaymentOptionModeChange=(value:string)=>{
    if(value==='Percentage'){
      this.setState({EditAjustPaymentPlanModeDialog:'Percentage'});
    }else if(value==='FixedAmount'){
      this.setState({EditAjustPaymentPlanModeDialog:'FixedAmount'});
    }
  }
  AdjustAmoutInputChange = (value: string) => {
    this.setState({ AdjustAmout: value }, () => {
    });
  };
  RemoveAdjustOpenmodel(){
    this.setState({RemoveAdjustmentOpenmodel:true})
  
  }
  REmoveAdjustedModelclose=()=>{
    this.setState({RemoveAdjustmentOpenmodel:false})
  }
  RemoveAdjustConfirm(){
   
  }
  // Customizable Area End
}